import React, {useEffect, useState} from "react";
import {t} from "i18next";
import {getUnitNumber} from "../Utils/getUnitNumber";
import {getContentBlockToShow} from "../Pages/ExerciseBlocks";

const LessonHeader = ({ globalState, deadLine, mode, apiInfo, rubrics, lessonName }) => {
    const [deliveredDate, setDeliveredDate] = useState(null);

    useEffect(() => {
        if (apiInfo.params) {
            if (apiInfo.params.attempts?.length > 0) {
                setDeliveredDate(new Date(apiInfo.params.attempts[0].date));
            }
        }
    }, [apiInfo]);

    return (
        <div className="lessonHeader">
            <div className="row">
                <div className="col-12 d-flex justify-content-start align-items-start mt-3">
                    <div className={`lessonType ${globalState.lessonType}`}>
                        {t(globalState.lessonType)}
                    </div>

                    <div className="lessonInfo">
                        <div className="unitInfo">
                            {t("unidad")} {getUnitNumber(globalState.unit.idName)}:{" "}
                            {globalState.unit.name}
                        </div>
                        <h2 className="lessonTitle">{lessonName}</h2>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="destacat">
                        <div className="item">
                            <div className="title">{t(globalState.lessonType)}:</div>
                            <div className="value">
                                {globalState.exercises.length} {t("tareas")}
                            </div>
                        </div>
                        {deadLine != null ? (
                            <div className="item">
                                <div className="title">{t("deadline")}:</div>
                                <div className="value">
                                    {deadLine?.toLocaleString(t("timezone.language"), {
                                        weekday: "long",
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric",
                                    })}
                                </div>
                            </div>
                        ) : null}
                        {deliveredDate != null ? (
                            <div className="item">
                                <div className="title">{t("delivered")}:</div>
                                <div className="value">
                                    {deliveredDate?.toLocaleString(t("timezone.language"), {
                                        weekday: "long",
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric",
                                    })}
                                </div>
                            </div>
                        ) : null}

                        {rubrics.map((rubric, index) => {
                            return (
                                <div key={`competence${index}`}>
                                    {rubric.evaluationItem?.competence != null ? (
                                        <div className="item" id="competence">
                                            <div className="title">{t("competencia")}</div>
                                            <div className="value">
                                                {rubric.evaluationItem.competence.alias} - {t("eje")} {rubric.evaluationItem.competence.linkedFrom?.dimensionsCollection?.items[0]?.name}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            );
                        })}


                    </div>
                </div>
            </div>
        </div>
    );
};

export default LessonHeader;
