import {getContentBlockToShow} from "../ExerciseBlocks";
import React from "react";

export const ContentBlockList = (props) => {
    const { contentBlock, clau } = props;

    let isOrdered = contentBlock.nodeType == 'ordered-list'

    let listItems = contentBlock.content.filter(c => c.nodeType == 'list-item')

    let listContent = getListContent()

    function getListContent() {
        return <>
            {listItems.map((listItem, index) => {
                return (
                    <li key={`list-${index}`}>
                        {listItem.content.map((contentBlock, indexr) => {
                            return (
                                <div key={`list-${index}-${indexr}`}>
                                    {getContentBlockToShow(
                                        contentBlock,
                                        false,
                                        `list-${index}-${indexr}`,
                                        null
                                    )}
                                </div>
                            )
                        })}
                    </li>
                )
            })}
        </>;
    }

    return (
        <div className="mt-2 mb-0" key={`${clau}_lines`}>
            {isOrdered ?
                <ol>{listContent}</ol>
                : <ul>{listContent}</ul>
            }
        </div>
    )
}