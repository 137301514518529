import React from "react";

const StudentProfileName = ({ studentProfile, mode }) => {
  const { name, lastname } = studentProfile;
  mode = mode ?? 'inline';
  return (
    <div className={`studentProfileWrapper ${mode}`}>
      <img className="studentProfileImage"
        src={`https://ui-avatars.com/api/?name=${name}&color=7F9CF5&background=EBF4FF&length=1&rounded=true&size=40`}
      />
      <div className="studentProfileName">
        {name} {lastname}
      </div>
    </div>
  );
};

export default StudentProfileName;
