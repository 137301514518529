import React, { Component } from 'react'
import client from '../Pages/Client'
import { Link } from 'react-router-dom'


class Country extends Component {
    constructor() {
        super()
    }
    componentDidMount() {

    }
    render() {
        return (
            <div>

            </div>
        );
    }
}
export default Country