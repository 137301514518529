import React from "react";
import {Routes, Route, useSearchParams} from "react-router-dom";
import Home from "./Pages/Home";
import Countries from "./Pages/Countries";
import Grades from "./Pages/Grades";
import Country from "./Pages/Country";
import Grade from "./Pages/Grade";
import Page from "./Pages/Page";
import Lessons from "./Pages/Lessons/Lessons";
import LessonExercise from "./Pages/Lessons/LessonExercise";
import LessonActivity from "./Pages/Lessons/LessonActivity";
import RedirectManager from "./Pages/RedirectManager";
import LessonTest from "./Pages/Lessons/LessonTest";
import Activities from "./Pages/Activities";
import Tests from "./Pages/Tests";
import { LessonActivityCorrected } from "./Pages/Lessons/LessonActivity/LessonActivityCorrected";
import { LessonActivityCorrectionSaved } from "./Pages/Lessons/LessonActivity/LessonActivityCorrectionSaved";
import { LessonTestCorrected } from "./Pages/Lessons/LessonTest/LessonTestCorrected";
import { LessonTestCorrectionSaved } from "./Pages/Lessons/LessonTest/LessonTestCorrectionSaved";
import {NotAuthorized} from "./Pages/NotAuthorized";
import {useCookies} from "react-cookie";
import ExerciseBlocks from "./Pages/ExerciseBlocks/ExerciseBlocks";
import ExerciseBlock from "./Pages/ExerciseBlocks/ExerciseBlock";

const App = (props) => {
  let [searchParams] = useSearchParams();

  const [cookies, setCookie] = useCookies([]);
  let cookieAgeInHours = 48

  let authorizedDomains = process.env.REACT_APP_EMBED_AUTHORIZED_DOMAINS.split(',')

  function isAuthorized(){
    if(process.env.REACT_APP_AUTHENTICATION_ENABLED == "false")
      return true;

    let isEmbedded = window.self !== window.top;

    let origin = null
    try{
      origin = window.parent.location.origin
    }catch(e){
      console.log("error when getting origin")
    }

    let isEmbeddedAndAuthorized = isEmbedded
    if(origin){
      isEmbeddedAndAuthorized = isEmbedded && authorizedDomains?.includes(origin)
    }


    let isNotEmbeddedButAuthorized = false
    if(searchParams.get("password") && process.env.REACT_APP_PASSWORD == searchParams.get("password")){
      setCookie('password', searchParams.get("password"), { maxAge: cookieAgeInHours * 60 * 60})
      isNotEmbeddedButAuthorized = true
    }
    if(cookies.password && process.env.REACT_APP_PASSWORD == cookies.password){
      setCookie('password', cookies.password, { maxAge: cookieAgeInHours * 60 * 60})
      isNotEmbeddedButAuthorized = true
    }

    return isEmbeddedAndAuthorized || isNotEmbeddedButAuthorized
  }

  return (
      isAuthorized() ?
        <Routes>
            <Route path="" element={<RedirectManager />} />
            <Route path="/:locale">
              <Route path="" element={<RedirectManager />} />
              <Route path="public">
                {/*<Route path="" element = {<Home />} />*/}
                <Route path="" element={<Home />} />
                <Route path="countries" element={<Countries />} />
                <Route path="country/:slug" element={<Country />} />
                <Route path="grades" element={<Grades />} />
                <Route path="grade/:id" element={<Grade />} />
                <Route path="exerciseBlocks" element={<ExerciseBlocks />} />
                <Route path="exerciseBlock/:id" element={<ExerciseBlock />} />
                <Route path="activities" element={<Activities />} />
                <Route path="tests" element={<Tests />} />
                <Route path="lessons" element={<Lessons />} />
                <Route
                    path="lessons/:grade_id/:unit_id/:id/exercises"
                    element={<LessonExercise />}
                />
                <Route
                    path="lessons/:grade_id/:unit_id/:id/cumulative-activity"
                    element={<LessonActivity />}
                />
                <Route
                    path="lessons/:grade_id/:unit_id/:id/cumulative-activity/corrected"
                    element={<LessonActivityCorrected />}
                />
                <Route
                    path="lessons/:grade_id/:unit_id/:id/cumulative-activity/saved"
                    element={<LessonActivityCorrectionSaved />}
                />
                <Route
                    path="lessons/:grade_id/:unit_id/:id/test/corrected"
                    element={<LessonTestCorrected />}
                />
                <Route
                    path="lessons/:grade_id/:unit_id/:id/test/saved"
                    element={<LessonTestCorrectionSaved />}
                />
                <Route
                    path="lessons/:grade_id/:unit_id/:id/test"
                    element={<LessonTest />}
                />

                <Route path=":env">
                  <Route path="" element={<Home />} />

                  <Route path="countries" element={<Countries />} />
                  <Route path="country/:slug" element={<Country />} />
                  <Route path="grades" element={<Grades />} />
                  <Route path="grade/:id" element={<Grade />} />
                  <Route path="exerciseBlocks" element={<ExerciseBlocks />} />
                  <Route path="exerciseBlock/:id" element={<ExerciseBlock />} />
                  <Route path="activities" element={<Activities />} />
                  <Route path="tests" element={<Tests />} />
                  <Route path="lessons" element={<Lessons />} />
                  <Route
                      path="lessons/:grade_id/:unit_id/:id/exercises"
                      element={<LessonExercise />}
                  />
                  <Route
                      path="lessons/:grade_id/:unit_id/:id/cumulative-activity"
                      element={<LessonActivity />}
                  />
                  <Route
                      path="lessons/:grade_id/:unit_id/:id/cumulative-activity/corrected"
                      element={<LessonActivityCorrected />}
                  />
                  <Route
                      path="lessons/:grade_id/:unit_id/:id/cumulative-activity/saved"
                      element={<LessonActivityCorrectionSaved />}
                  />
                  <Route
                      path="lessons/:grade_id/:unit_id/:id/test/corrected"
                      element={<LessonTestCorrected />}
                  />
                  <Route
                      path="lessons/:grade_id/:unit_id/:id/test/saved"
                      element={<LessonTestCorrectionSaved />}
                  />
                  <Route
                      path="lessons/:grade_id/:unit_id/:id/test"
                      element={<LessonTest />}
                  />
                </Route>
              </Route>
              <Route path="staging">
                <Route path="" element={<Page element={<Home />} />} />
                <Route
                    path="countries"
                    element={<Page element={<Countries />} />}
                />
                <Route
                    path="country/:slug"
                    element={<Page element={<Country />} />}
                />
                <Route path="grades" element={<Page element={<Grades />} />} />
                <Route
                    path="grade/:id"
                    element={<Page element={<Grade />} />}
                />

                <Route path="exerciseBlocks" element={<Page element={<ExerciseBlocks />} />} />
                <Route path="exerciseBlock/:id" element={<Page element={<ExerciseBlock />} />} />
                <Route
                    path="activities"
                    element={<Page element={<Activities />} />}
                />
                <Route path="tests" element={<Page element={<Tests />} />} />
                <Route path="lessons" element={<Page element={<Lessons />} />} />
                <Route
                    path="lessons/:grade_id/:unit_id/:id/exercises"
                    element={<Page element={<LessonExercise />} />}
                />
                <Route
                    path="lessons/:grade_id/:unit_id/:id/cumulative-activity"
                    element={<Page element={<LessonActivity />} />}
                />
                <Route
                    path="lessons/:grade_id/:unit_id/:id/cumulative-activity/corrected"
                    element={<LessonActivityCorrected />}
                />
                <Route
                    path="lessons/:grade_id/:unit_id/:id/cumulative-activity/saved"
                    element={<LessonActivityCorrectionSaved />}
                />
                <Route
                    path="lessons/:grade_id/:unit_id/:id/test/corrected"
                    element={<LessonTestCorrected />}
                />
                <Route
                    path="lessons/:grade_id/:unit_id/:id/test/saved"
                    element={<LessonTestCorrectionSaved />}
                />
                <Route
                    path="lessons/:grade_id/:unit_id/:id/test"
                    element={<Page element={<LessonTest />} />}
                />

                <Route path=":env">
                  <Route path="" element={<Page element={<Home />} />} />
                  <Route
                      path="countries"
                      element={<Page element={<Countries />} />}
                  />
                  <Route
                      path="country/:slug"
                      element={<Page element={<Country />} />}
                  />
                  <Route path="grades" element={<Page element={<Grades />} />} />
                  <Route
                      path="grade/:slug"
                      element={<Page element={<Grade />} />}
                  />
                  <Route
                      path="activities"
                      element={<Page element={<Activities />} />}
                  />
                  <Route path="tests" element={<Page element={<Tests />} />} />
                  <Route
                      path="lessons"
                      element={<Page element={<Lessons />} />}
                  />
                  <Route
                      path="lessons/:grade_id/:unit_id/:id/exercises"
                      element={<Page element={<LessonExercise />} />}
                  />
                  <Route
                      path="lessons/:grade_id/:unit_id/:id/cumulative-activity"
                      element={<Page element={<LessonActivity />} />}
                  />
                  <Route
                      path="lessons/:grade_id/:unit_id/:id/cumulative-activity/corrected"
                      element={<LessonActivityCorrected />}
                  />
                  <Route
                      path="lessons/:grade_id/:unit_id/:id/cumulative-activity/saved"
                      element={<LessonActivityCorrectionSaved />}
                  />
                  <Route
                      path="lessons/:grade_id/:unit_id/:id/test/corrected"
                      element={<LessonTestCorrected />}
                  />
                  <Route
                      path="lessons/:grade_id/:unit_id/:id/test/saved"
                      element={<LessonTestCorrectionSaved />}
                  />
                  <Route
                      path="lessons/:grade_id/:unit_id/:id/test"
                      element={<Page element={<LessonTest />} />}
                  />
                </Route>
              </Route>
            </Route>
        </Routes>
          : <NotAuthorized />
  )
}

export default App;
