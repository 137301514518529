import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './i18n/config'
import {BrowserRouter as Router} from "react-router-dom";
import {CookiesProvider} from "react-cookie";

ReactDOM.render(
    <CookiesProvider>
        <Router basename="/">
            <App/>
        </Router>
    </CookiesProvider>,
    document.getElementById('root'))

