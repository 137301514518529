import React from "react";
import {t} from "i18next";
import {ReactComponent as IconInfo} from '../images/svg/Icon-Info.svg';

const RememberLineCorrection = ({ exercises, corrections, hasCorrected = false }) => {
    return (
        <div className="rememberLine">
            <IconInfo fill='#0D70B6' />

            <div className="rememberInfo">
                <div>
                    {t("user.answered")}
                    {" "}
                    <span className="answeredExercisesCount">{ exercises.filter((e) => e.results.answered).length }{" "}{t("of")}{" "}{exercises.length}</span>
                    {" "}
                    {t("tareas")}
                </div>
                {hasCorrected ?
                    <div>
                        {t("you.have.corrected")}
                        {" "}
                        <span className="answeredExercisesCount">{ corrections.filter((e) => e.corrected || Number.isFinite(e.score)).length }{" "}
                            {t("of.those")}{" "}{exercises.filter((e) => e.results).length}</span>
                        {" "}
                        {t("tareas")}
                    </div>
                : null }
            </div>
        </div>
    );
};

export default RememberLineCorrection;
