import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import React from "react";
import "../scss/App.scss";

export const RICHTEXT_DEFAULT = {
  renderMark: {
    [MARKS.BOLD]: (text) => {
      return <span className="bold">{text}</span>;
    },
    [MARKS.ITALIC]: (text) => <span className="italic">{text}</span>,
  },
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      return (
        <img
          className="imatge"
          crossOrigin="*"
          src={`${node.data.target.fields.file.url}`}
          alt={node.data.target.fields.description}
        />
      );
    },
  },
};

export const RICHTEXT_NO_LINEBREAK = {
  renderMark: {
    [MARKS.BOLD]: (text) => <span className="bold">{text}</span>,
    [MARKS.ITALIC]: (text) => <span className="italic">{text}</span>,
  },
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      return (
        <img
          className="imatge"
          crossOrigin="*"
          src={`${node.data.target.fields.file.url}`}
          alt={node.data.target.fields.description}
        />
      );
    },
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <span>{children}</span>;
    },
  },
};

export const RICHTEXT_WITH_HTML = {
  renderMark: {
    [MARKS.BOLD]: (text) => <span className="bold">{text}</span>,
    [MARKS.ITALIC]: (text) => <span className="italic">{text}</span>,
  },
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      return (
        <img
          className="imatge"
          crossOrigin="*"
          src={`${node.data.target.fields.file.url}`}
          alt={node.data.target.fields.description}
        />
      );
    },
    [BLOCKS.PARAGRAPH]: (node, children) => {
      if (node.asHTML) {
        return <div>{children}</div>;
        // return <div dangerouslySetInnerHTML={{ __html: children }}></div>;
      } else {
        return <span>{children}</span>;
      }
    },
  },
};

export function richText(body, options = RICHTEXT_DEFAULT) {
  return documentToReactComponents(body, options);
}
