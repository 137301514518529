import {t} from "i18next";
import StudentProfileName from "../../../Components/StudentProfileName";
import LessonHeader from "../../../Components/LessonHeader";
import ActivityTestRubrics from "../../../Components/ActivityTestRubrics";
import ActivityTestCorrectionInfo from "../../../Components/ActivityTestCorrectionInfo";

export const LessonTeacherInitialNote = (props) => {
  const {
    slidePosition,
    globalState,
    rubrics,
    deadLine,
    mode,
    apiInfo,
    exercises
  } = props;

  return (
    <div
      className={`slideNote slideNote${slidePosition} initialNote`}
      id="initialNote"
      key={`initialNote`}
      data-position={slidePosition}
      data-navigation="false">

      <div className="slideNoteWrapper">
        {globalState.apiInfo.student && globalState.role !== "student" ? (
            <StudentProfileName
                studentProfile={globalState.apiInfo.student}
                mode='inline'
            />
        ) : null}
        <div className="slideNoteContent">
          <div className="container">

            <LessonHeader globalState={globalState} deadLine={deadLine} mode={mode} apiInfo={apiInfo}
                          rubrics={rubrics} lessonName={globalState.functions.getName()} />

            <div className="row">

              {mode === "display_with_data" || mode.includes("in_review") ? (

                <div className="col-12">
                  <ActivityTestCorrectionInfo exercises={exercises} globalState={globalState}
                                              corrections={exercises} showBadge={false} title={t(globalState.lessonType)} corrected={false} />
                </div>
              ) : null}
            </div>

            <ActivityTestRubrics rubrics={rubrics} mode={mode} results={apiInfo.params?.answers?.results} />

          </div>
        </div>
      </div>
    </div>
  );
};
