import {
  useParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import React from "react";

export function withRouter(Children) {
  return (props) => {
    let match = { params: useParams() };
    let env = process.env.NODE_ENV;
    let { t, i18n } = useTranslation();
    let location = useLocation();
    let [searchParams] = useSearchParams();
    let navigate = useNavigate();

    return (
      <Children
        {...props}
        t={t}
        i18n={i18n}
        match={match}
        router={{ location, navigate }}
        env={env}
        searchParams={searchParams}
      />
    );
  };
}
