import { LessonExerciseFunctions } from "./LessonExercise.functions";

export class LessonExerciseApi {
  static async doLogin(globalState) {
    console.log("doLogin");

    let userData = {};

    if (process.env.REACT_APP_SITE_URL === "https://lms.jumpmath.es/") {
      globalState.grade_id === process.env.REACT_APP_CONTENTFUL_COURSE1
        ? (userData = {
            username: process.env.REACT_APP_CONTENTFUL_COURSE1_USER,
            password: process.env.REACT_APP_CONTENTFUL_COURSE1_PWD,
          })
        : (userData = {
            username: process.env.REACT_APP_CONTENTFUL_COURSE2_USER,
            password: process.env.REACT_APP_CONTENTFUL_COURSE2_PWD,
          });
    } else {
      globalState.grade_id === process.env.REACT_APP_CONTENTFUL_COURSE1
        ? (userData = {
            username: process.env.REACT_APP_CONTENTFUL_COURSE1_TEST_USER,
            password: process.env.REACT_APP_CONTENTFUL_COURSE1_TEST_PWD,
          })
        : (userData = {
            username: process.env.REACT_APP_CONTENTFUL_COURSE2_TEST_USER,
            password: process.env.REACT_APP_CONTENTFUL_COURSE2_TEST_PWD,
          });
    }

    let response = await fetch(
      `${process.env.REACT_APP_SITE_URL}api/v1/login`,
      {
        method: "post",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(userData),
      }
    );

    if (response) {
      let content = await response.json();

      if (content && content.data) {
        globalState.setStudentId(content.data.uuid);
        globalState.setAuthToken(content.data.auth_token);
      }
    }
  }

  static async getInitInfo(globalState, authToken) {
    if (authToken === "") {
      let mode = LessonExerciseFunctions.getMode(globalState, null);
      globalState.setMode(mode);
      return;
    }

    let urlToFetch = `${process.env.REACT_APP_SITE_URL}api/v1/get-init-info/${globalState.lessonType}/${globalState.id}`;

    urlToFetch += `/${globalState.studentId}`;

    // console.log("getInitInfo token:", authToken, ", exercise id: ", globalState.id, ", student id:", globalState.studentId)
    console.log("getInitInfo url:", urlToFetch);

    let response = await fetch(urlToFetch, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + authToken,
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    });

    if (response) {
      console.log("getInitInfo response:", response.status);

      switch (response.status) {
        case 401:
          globalState.setPopup("authenticationError");
          break;
        case 404:
          globalState.setPopup("notFoundError");
          break;
        case 200:
          let content = await response.json();

          console.log("getInitInfo content:", content.data);

          if (content && content.data) {
            if (content.data.params) {
              globalState.setDeadLine(new Date(content.data.params.deadline));
            }

            let apiInfo = content.data;

            globalState.setApiInfo(apiInfo);

            let mode = LessonExerciseFunctions.getMode(globalState, apiInfo);

            if (mode === "solve_retry" || mode === "display_with_data") {
              LessonExerciseFunctions.reprocessExercises(globalState, apiInfo);
            }

            globalState.setMode(mode);
          }
          break;
      }
    }
  }

  static async sendExercise(globalState) {
    if (globalState.authToken === "") return;

    let urlToFetch = `${process.env.REACT_APP_SITE_URL}api/v1/tasks/${globalState.lessonType}/${globalState.id}/${globalState.studentId}/send`;

    console.log("sendExercise url:", urlToFetch);

    let json = this.getJson(globalState);

    let response = await fetch(urlToFetch, {
      method: "POST",
      headers: new Headers({
        Authorization: "Bearer " + globalState.authToken,
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(json),
    });

    if (response) {
      let content = await response.json();

      if (content.error) {
      }

      if (content.success) {
        this.goToPage(globalState, 'display', true, true);
      }
    }
  }

  static goToPage(globalState, mode, devMode, teacherMode) {
    let location = `${globalState.props.location.pathname}?type=${mode}`;

    if (globalState.authToken)
      location += `&auth_token=${globalState.authToken}`;

    if (globalState.role) location += `&role=${globalState.role}`;

    if (globalState.studentId) location += `&uuid=${globalState.studentId}`;

    if (globalState.devMode) location += `&devMode=${devMode}`;

    if (globalState.teacherMode) location += `&teacherMode=${teacherMode}`;

    window.location.replace(location);
  }

  static async resetExercise(globalState) {
    if (globalState.authToken === "") return;

    let urlToFetch = `${process.env.REACT_APP_SITE_URL}api/v1/tasks/${globalState.lessonType}/${globalState.id}/${globalState.studentId}/reset`;

    console.log("resetExercise url:", urlToFetch);

    let response = await fetch(urlToFetch, {
      method: "POST",
      headers: new Headers({
        Authorization: "Bearer " + globalState.authToken,
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    });

    if (response) {
      let content = await response.json();

      window.location.reload();
    }
  }

  static getJson(globalState) {
    return {
      params: {
        key: globalState.id,
        type: globalState.type,
        user_id: globalState.apiInfo?.profile?.uuid ?? -1,
        role_id: globalState.apiInfo?.profile?.last_session_role ?? -1,
      },
      data: {
        name: globalState.lesson.name,
        deadline: globalState.deadLine?.toISOString(),
        date: globalState.startTime.toISOString(),
        limitTime: 60,
      },
      results: {
        completed: LessonExerciseApi.getCompletedPercentage(globalState),
        initTime: globalState.startTime.toISOString(),
        endTime: new Date().toISOString(),
        score: LessonExerciseApi.getScore(globalState.results),
        competencies: LessonExerciseApi.getCompetences(globalState),
        finished: LessonExerciseApi.getFinished(globalState),
        answers: LessonExerciseApi.getAnswers(globalState),
      },
    };
  }

  static getXapiSlideResult(globalState, currentSlidePosition){
    let result = {};

    if(globalState.slides[currentSlidePosition].info.slug === 'general-information' || globalState.slides[currentSlidePosition].info.slug === 'summary')
        return {};

    let exerciseBlock_id = globalState.slides[currentSlidePosition].exerciseInfo?.exerciseBlock?.sys.id;

    if(!exerciseBlock_id)
      return {};

    let json = this.getJson(globalState);

    if(json.results && json.results.answers){
        let answers = json.results.answers;

        let exerciseAnswers = answers.find(answer => answer.exerciseBlock_id === exerciseBlock_id);

        if(exerciseAnswers){
          result['completion'] = exerciseAnswers.answered;
          result['success'] = exerciseAnswers.correct;
          result['extensions'] = {}
          result['extensions'][`${window.location.origin}/score`] = exerciseAnswers.score;
          result['extensions'][`${window.location.origin}/answers`] = exerciseAnswers.answers;
        }
    }

    return result;
  }

  static getXapiFinalResult(globalState, currentSlidePosition){
    let result = {};

    let json= this.getJson(globalState);

    if(json.results){
      result['completion'] = json.results.answered;
      result['extensions'] = {}
      result['extensions'][`${window.location.origin}/info`] = json;
    }

    return result;
  }

  static processJson(globalState) {
    let json = this.getJson(globalState);
  }

  static getScore(results) {
    let score = 0;
    results = results.filter(r => r.qualitative != "N0")
    for (let result of results) {
      score += result.quantitative;
    }
    score /= results.length;
    return score;
  }

  static getFinished(globalState) {
    return (
      globalState.exercises.filter((e) => e.results.answered).length ===
      globalState.exercises.length
    );
  }

  static getAnswers(globalState) {
    let answers = [];
    for (let exercise of globalState.exercises) {
      answers.push(exercise.results);
    }
    return answers;
  }

  static getCompetences(globalState) {
    let competences = [];
    for (let result of globalState.results) {
      competences.push({
        competency: result.competence_id,
        level: parseInt(result.qualitative.replace("N", "")),
        score: result.quantitative,
      });
    }
    return competences;
  }

  static getCompletedPercentage(globalState) {
    return (
      (globalState.exercises.filter((e) => e.results.answered).length /
        globalState.exercises.length) *
      100
    );
  }
}
