import React, { Component } from "react";
import { client } from "../Client";
import { renderToStaticMarkup } from "react-dom/server";
import Asset from "../Asset";
import { richText, RICHTEXT_WITH_HTML } from "../../Utils/richText";

class ContentBlockParagraph extends Component {
  constructor() {
    super();

    this.state = {
      assetUrl: "",
    };
  }

  componentDidMount() {}

  ParagraphContent(props) {
    const { text, contentBlock } = props;

    return (
      <div className="my-3">{richText(contentBlock, RICHTEXT_WITH_HTML)}</div>
    );
  }

  extractParagraphContent(contentBlock, showAnswers, clau, isDigital = false) {
    if (!contentBlock) return;

    let items = [];

    let i;

    for (i = 0; i < contentBlock.content.length; i++) {
      let text = contentBlock.content[i].value;

      let regexInput = /\*\*(.*?)\*\*/g;
      let regex_x = /\*\*(x+)\*\*/g;
      let regex_o = /\*\*(o+)\*\*/g;
      let regex_t = /\*\*(t+)\*\*/g;
      let regexAnswer = /\[\[(.*?)\]\]/gm;

      let matches = [...text.matchAll(regexAnswer)];
      if (matches.length > 0) {
        let answer_class = `answer ${showAnswers ? "show" : "hide"}`;
        text = '<div class="' + answer_class + '"><h5>Solución:</h5>' + text;
        for (const match of matches) {
          if (match && match[1]) {
            let answer = match[1];

            if (answer.includes("$$")) answer = match[1].replaceAll("$", "$$$");

            if (answer.includes("asset=")) {
              let assetId = match[1].replace("asset=", "");

              answer = renderToStaticMarkup(
                <Asset assetUrl={this.state.assetUrl} />
              );

              if (this.state.assetUrl === "") {
                client()
                  .getAsset(assetId)
                  .then((asset) => {
                    let assetUrl = asset.fields.file.url;
                    this.setState({
                      assetUrl: assetUrl,
                    });
                  });
              }
            }

            text = text.replace(match[0], `${answer}<br>`);
          }
        }
        text += "</div>";
        contentBlock.asHTML = true;
      }

      if (text.includes(`<div class="answer`)) {
        text = text.replace(" show", "");
        text = text.replace(" hide", "");

        text = text.replace(
          "answer",
          `answer ${showAnswers ? "show" : "hide"}`
        );
      }

      let match;
      while ((match = regexInput.exec(text))) {
        if (match[1].includes("x")) {
          if (isDigital) {
            // text = text.replace(regex_x, `<input class="ms-3" type="text" id="fname" name="fname">`)
            text = text.replace(regex_x, ``);
          } else {
            text = text.replace(regex_x, match[1].replaceAll("x", "____"));
          }
        }

        if (match[1].includes("o")) text = text.replace(regex_o, "o");

        if (match[1].includes("t")) text = text.replace(regex_t, "test");

        if (!regexInput.global) break;
      }

      contentBlock.asHTML = true;

      contentBlock.content[i].value = text;
    }

    items.push(
      <this.ParagraphContent contentBlock={contentBlock} key={`${clau}_p`} />
    );

    return items;
  }

  render() {
    const { contentBlock, showAnswers, clau, isDigital } = this.props;

    let contentBlock2 = JSON.parse(JSON.stringify(contentBlock));

    let lines = this.extractParagraphContent(
      contentBlock2,
      showAnswers,
      clau,
      isDigital
    );

    return (
      <div className="my-0" key={`${clau}_lines`}>
        {lines}
      </div>
    );
  }
}

function encapsulate(Children) {
  return (props) => {
    const { contentBlock, clau } = props;
    return <Children {...props} contentBlock={contentBlock} clau={clau} />;
  };
}

export default encapsulate(ContentBlockParagraph);
