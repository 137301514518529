import XAPI from "@xapi/xapi";
import {Client_GraphQL} from "../Client_GraphQL";
import sha1 from "js-sha1";
import {LessonExerciseApi} from "./LessonExercise/LessonExercise.api";
import {LessonActivityApi} from "./LessonActivity/LessonActivity.api";
import {LessonTestApi} from "./LessonTest/LessonTest.api";

export class LessonXapi {

    static getOrCreateXapiClient() {
        if(this.xapiClient != null)
            return this.xapiClient;

        this.xapiClient = {
            endpoint: "https://jump-math-platform.lrs.io/xapi/",
            username: "jm_api_platform",
            password: "JM2022!",
            auth: null,
            xapi: null,
            actor: null,
            statements: []
        }

        this.xapiClient.auth = XAPI.toBasicAuth(this.xapiClient.username, this.xapiClient.password)

        this.xapiClient.xapi = new XAPI({
            endpoint:this.xapiClient.endpoint,
            auth: this.xapiClient.auth
        })

        this.xapiClient.getActor = async function(globalState){
            if(!this.actor){
                if(globalState.authToken !== "" && globalState.apiInfo?.profile){
                    this.actor = {
                        objectType: "Agent",
                        name: globalState.role === 'student' ? `${globalState.apiInfo.student.name} ${globalState.apiInfo.student.lastname}` : `${globalState.apiInfo.profile.username}`,
                        mbox: `mailto:${globalState.apiInfo.profile.email}`
                        // mbox_sha1sum: sha1(`mailto:${globalState.apiInfo.profile.email}`)
                    };
                }
            }

            return this.actor;
        }

        this.xapiClient.sendStatement = async function(verb, globalState, _currentSlidePosition = -1){

            let currentSlidePosition = _currentSlidePosition !== -1 ? _currentSlidePosition : globalState.currentSlidePosition;
            let info = {};
            let objectExtensions = {};
            let result = {};

            let actor = await this.getActor(globalState);

            function getAttemptedInfo() {
                return {
                    verb: {
                        id: "http://adlnet.gov/expapi/verbs/attempted",
                        display: {
                            "es-ES": "attempted"
                        }
                    },
                    object: { }
                };
            }

            function getExperiencedInfo() {
                return {
                    verb: {
                        id: "http://adlnet.gov/expapi/verbs/experienced",
                        display: {
                            "es-ES": "experienced"
                        }
                    },
                    object: { }
                };
            }

            function getLeftInfo() {
                return {
                    verb: {
                        id: "http://activitystrea.ms/schema/1.0/leave",
                        display: {
                            "es-ES": "left"
                        }
                    },
                    object: { }
                };
            }

            function getCompletedInfo() {
                return {
                    verb: {
                        id: "http://adlnet.gov/expapi/verbs/completed",
                        display: {
                            "es-ES": "completed"
                        }
                    },
                    object: { }
                };
            }

            function getSlideResult(){

                switch (globalState.lessonType){
                    case "exercise":
                        return LessonExerciseApi.getXapiSlideResult(globalState, currentSlidePosition - 1);
                    case "activity":
                        return LessonActivityApi.getXapiSlideResult(globalState, currentSlidePosition - 1);
                    case "test":
                        return LessonTestApi.getXapiSlideResult(globalState, currentSlidePosition - 1);
                }

                return {};
            }

            function getFinalResult(){

                switch (globalState.lessonType){
                    case "exercise":
                        return LessonExerciseApi.getXapiFinalResult(globalState, currentSlidePosition - 1);
                    case "activity":
                        return LessonActivityApi.getXapiFinalResult(globalState, currentSlidePosition - 1);
                    case "test":
                        return LessonTestApi.getXapiFinalResult(globalState, currentSlidePosition - 1);
                }

                return {};
            }

            switch(verb){
                case "attempted":
                    info = getAttemptedInfo();
                    break;
                case "experienced":
                    info = getExperiencedInfo();
                    break;
                case "left":
                    info = getLeftInfo();
                    result = getSlideResult();
                    break;
                case "completed":
                    info = getCompletedInfo();
                    result = getFinalResult();
                    break;
            }

            let canonicalUrl = `${window.location.origin}${window.location.pathname}`

            objectExtensions[`${window.location.origin}/slide`] = `${currentSlidePosition}`

            let statement = {
                actor: actor,
                verb: info.verb,
                object: {
                    objectType: "Activity",
                    id: canonicalUrl,
                    definition: {
                        type: "http://adlnet.gov/expapi/activities/module",
                        name: {
                            und: `${globalState.functions.getName()}`
                        },
                        extensions: objectExtensions
                    }
                },
                result: result,
                timestamp: new Date().toISOString()
            };

            if(statement.actor && globalState.role === "student" && process.env.REACT_APP_SEND_XAPI === 'true'){
                console.log("Xapi statement:",statement.verb.display["es-ES"], statement)

                this.statements.push(statement)
            }
        }

        this.xapiClient.sendStatements = function(xapiClient){
            if(xapiClient.statements.length > 0){
                let statements = xapiClient.statements
                xapiClient.xapi.sendStatements({
                    statements: statements
                })
                xapiClient.statements = []
            }
        }

        setInterval(this.xapiClient.sendStatements, 1000, this.xapiClient);

        return this.xapiClient
    }

}
