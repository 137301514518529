import React from "react";
import ContentBlockParagraph from "./ContentBlocks/ContentBlockParagraph";
import {ContentBlockList} from "./ContentBlocks/ContentBlockList";
import {ContentBlockEmbeddedAsset} from "./ContentBlocks/ContentBlockEmbeddedAsset";
import {ContentBlockTable} from "./ContentBlocks/ContentBlockTable";

export const ExerciseBlock1 = (props) => {
  const { content, id, showAnswers, assets } = props;
  return (
    <section className="row exerciseBlock exerciseBlock1">
      {getApartados(content).map((apartado, index) => {
        return (
          <div className="col-6" key={`${id}-${index}`}>
            <div className="container">
              <div className="row card">
                {apartado.map((contentBlock, indexb) => {
                  return (
                    <div className="col-12" key={`${id}-${index}-${indexb}`}>
                      {getContentBlockToShow(
                        contentBlock,
                        showAnswers,
                        `${id}-${index}-${indexb}`,
                        assets
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export const ExerciseBlock2 = (props) => {
  const { content, id, showAnswers, assets } = props;
  return (
    <section className="row exerciseBlock exerciseBlock2">
      {getApartados(content).map((apartado, index) => {
        return (
          <div className="col-6" key={`${id}-${index}`}>
            <div className="container">
              <div className="row card">
                {apartado.map((contentBlock, indexb) => {
                  return (
                    <div className="col-12" key={`${id}-${index}-${indexb}`}>
                      {getContentBlockToShow(
                        contentBlock,
                        showAnswers,
                        `${id}-${index}-${indexb}`,
                        assets
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export const ExerciseBlock3 = (props) => {
  const { content, id, showAnswers, assets } = props;
  return (
    <section className="row exerciseBlock exerciseBlock3">
      {getApartados(content).map((apartado, index) => {
        return (
          <div className="col-6" key={`${id}-${index}`}>
            <div className="container">
              <div className="row card">
                {apartado.map((contentBlock, indexb) => {
                  return (
                    <div className="col-12" key={`${id}-${index}-${indexb}`}>
                      {getContentBlockToShow(
                        contentBlock,
                        showAnswers,
                        `${id}-${index}-${indexb}`,
                        assets
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export const ExerciseBlock4 = (props) => {
  const { content, id, showAnswers, assets } = props;

  let col1_apartados = getApartados(content);
  let col2_apartado = col1_apartados.pop();
  return (
    <section className="row exerciseBlock exerciseBlock4">
      <div className="col-6">
        {col1_apartados.map((apartado, index) => {
          return (
            <div className="col-12" key={`${id}-${index}`}>
              <div className="container">
                <div className="row card">
                  {apartado.map((contentBlock, indexb) => {
                    return (
                      <div className="col-12" key={`${id}-${index}-${indexb}`}>
                        {getContentBlockToShow(
                          contentBlock,
                          showAnswers,
                          `${id}-${index}-${indexb}`,
                          assets
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="col-6">
        <div className="container">
          <div className="row card">
            {col2_apartado.map((contentBlock, indexb) => {
              return (
                <div className="col-12" key={`${id}-wsddf-${indexb}`}>
                  {getContentBlockToShow(
                    contentBlock,
                    showAnswers,
                    `${id}-wsddf-${indexb}`,
                    assets
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export const ExerciseBlock5 = (props) => {
  const { content, id, showAnswers, assets } = props;
  return (
    <section className="row exerciseBlock exerciseBlock5">
      {getApartados(content).map((apartado, index) => {
        return (
          <div className="col-12" key={`${id}-${index}`}>
            <div className="container">
              <div className="row card">
                {apartado.map((contentBlock, indexb) => {
                  return (
                    <div className="col-12" key={`${id}-${index}-${indexb}`}>
                      {getContentBlockToShow(
                        contentBlock,
                        showAnswers,
                        `${id}-${index}-${indexb}`,
                        assets
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export const ExerciseBlock6 = (props) => {
  const { content, id, showAnswers, assets } = props;
  return (
    <section className="row exerciseBlock exerciseBlock6">
      {getApartados(content).map((apartado, index) => {
        return (
          <div className="col-6" key={`${id}-${index}`}>
            <div className="container">
              <div className="row card">
                {apartado.map((contentBlock, indexb) => {
                  return (
                    <div className="col-12" key={`${id}-${index}-${indexb}`}>
                      {getContentBlockToShow(
                        contentBlock,
                        showAnswers,
                        `${id}-${index}-${indexb}`,
                        assets
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export const ExerciseBlock7 = (props) => {
  const { content, id, showAnswers, assets } = props;
  return (
    <section className="row exerciseBlock exerciseBlock7">
      {getApartados(content).map((apartado, index) => {
        return (
          <div className="col-12" key={`${id}-${index}`}>
            <div className="container">
              <div className="row card">
                {apartado.map((contentBlock, indexb) => {
                  return (
                    <div className="col-12" key={`${id}-${index}-${indexb}`}>
                      {getContentBlockToShow(
                        contentBlock,
                        showAnswers,
                        `${id}-${index}-${indexb}`,
                        assets
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export const ExerciseBlock8 = (props) => {
  const { content, id, showAnswers, assets } = props;
  return (
    <section className="row exerciseBlock exerciseBlock8">
      {getApartados(content).map((apartado, index) => {
        return (
          <div className="col-3" key={`${id}-${index}`}>
            <div className="container">
              <div className="row card">
                {apartado.map((contentBlock, indexb) => {
                  return (
                    <div className="col-12" key={`${id}-${index}-${indexb}`}>
                      {getContentBlockToShow(
                        contentBlock,
                        showAnswers,
                        `${id}-${index}-${indexb}`,
                        assets
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export const ExerciseBlock9 = (props) => {
  const { content, id, showAnswers, assets } = props;
  return (
    <section className="row exerciseBlock exerciseBlock9">
      {getApartados(content).map((apartado, index) => {
        return (
          <div className="col-12" key={`${id}-${index}`}>
            <div className="container">
              <div className="row card">
                {apartado.map((contentBlock, indexb) => {
                  return (
                    <div className="col-12" key={`${id}-${index}-${indexb}`}>
                      {getContentBlockToShow(
                        contentBlock,
                        showAnswers,
                        `${id}-${index}-${indexb}`,
                        assets
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export const ExerciseBlock10 = (props) => {
  const { content, id, showAnswers, assets } = props;

  let apartados = getApartados(content);
  let first_apartado = apartados.shift();

  return (
    <section className="row exerciseBlock exerciseBlock10">
      <div className="col-12">
        {first_apartado.map((contentBlock, index) => {
          return getContentBlockToShow(
            contentBlock,
            showAnswers,
            `${id}-${index}-efd`,
            assets
          );
        })}
      </div>
      {apartados.map((apartado, index) => {
        return (
          <div className="col-6" key={`${id}-${index}`}>
            {apartado.map((contentBlock, indexb) => {
              return getContentBlockToShow(
                contentBlock,
                showAnswers,
                `${id}-${index}-${indexb}`,
                assets
              );
            })}
          </div>
        );
      })}
    </section>
  );
};

export const ExerciseBlock11 = (props) => {
  const { content, id, showAnswers, assets } = props;
  return (
    <section className="row exerciseBlock exerciseBlock11">
      {getApartados(content).map((apartado, index) => {
        return (
          <div className="col-4" key={`${id}-${index}`}>
            <div className="container">
              <div className="row card">
                {apartado.map((contentBlock, indexb) => {
                  return (
                    <div className="col-12" key={`${id}-${index}-${indexb}`}>
                      {getContentBlockToShow(
                        contentBlock,
                        showAnswers,
                        `${id}-${index}-${indexb}`,
                        assets
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export const ExerciseBlock12 = (props) => {
  const { content, id, showAnswers, assets } = props;

  let apartados = getApartados(content);
  let first_apartado = apartados.shift();

  return (
    <section className="row exerciseBlock exerciseBlock12">
      <div className="col-12">
        {first_apartado.map((contentBlock, index) => {
          return getContentBlockToShow(
            contentBlock,
            showAnswers,
            `${id}-${index}-efd`,
            assets
          );
        })}
      </div>
      {apartados.map((apartado, index) => {
        return (
          <div className="col-3" key={`${id}-${index}`}>
            {apartado.map((contentBlock, indexb) => {
              return getContentBlockToShow(
                contentBlock,
                showAnswers,
                `${id}-${index}-${indexb}`,
                assets
              );
            })}
          </div>
        );
      })}
    </section>
  );
};

export function getApartados(content) {
  let apartados = [];
  let apartado = [];
  if (content) {
    for (let content of content?.content) {
      if (content.nodeType === "hr") {
        apartados.push(apartado);
        apartado = [];
      } else apartado.push(content);
    }
    apartados.push(apartado);
  }
  return apartados;
}

export function getReviewContent(content) {
  let apartados = [];
  let apartado = [];
  if (content) {
    for (let content of content?.content) {
      if (content.nodeType === "hr") {
        apartados.push(apartado);
        apartado = [];
      } else apartado.push(content);
    }
    apartados.push(apartado);
  }
  return apartados;
}

export function getContentBlockToShow(
  contentBlock,
  showAnswers = false,
  clau,
  assets
) {
  let contentBlockToShow = null;
  switch (contentBlock.nodeType) {
    case "paragraph":
      contentBlockToShow = (
        <ContentBlockParagraph
          contentBlock={contentBlock}
          showAnswers={showAnswers}
          clau={`paragraph-${clau}`}
        />
      );
      break;
    case "embedded-asset-block":
      contentBlockToShow = (
        <ContentBlockEmbeddedAsset
          contentBlock={contentBlock}
          clau={`embeddedAsset-${clau}`}
          assets={assets}
        />
      );
      break;
    case "table":
      contentBlockToShow = (
          <ContentBlockTable
              contentBlock={contentBlock}
              clau={`table-${clau}`}
          />
      );
      break;
    case "ordered-list":
    case "unordered-list":
      contentBlockToShow = (
          <ContentBlockList
              contentBlock={contentBlock}
              clau={`list-${clau}`}
          />
      );
      break;
  }
  return contentBlockToShow;
}

export function getDigitalContentBlockToShow(contentBlock, clau, assets) {
  let contentBlockToShow = null;
  switch (contentBlock.nodeType) {
    case "paragraph":
      contentBlockToShow = (
        <ContentBlockParagraph
          contentBlock={contentBlock}
          clau={`paragraph-${clau}`}
          isDigital={true}
        />
      );
      break;
    case "embedded-asset-block":
      contentBlockToShow = (
        <ContentBlockEmbeddedAsset
          contentBlock={contentBlock}
          clau={`embeddedAsset-${clau}`}
          assets={assets}
          isDigital={true}
        />
      );
      break;
    case "table":
      contentBlockToShow = (
          <ContentBlockTable
              contentBlock={contentBlock}
              clau={`table-${clau}`}
              isDigital={true}
          />
      );
      break;
  }
  return contentBlockToShow;
}
