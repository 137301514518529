import Button from "react-bootstrap/Button";
import { t } from "i18next";
export const LessonActivityCorrected = (props) => {
  const { slidePosition, slide, globalState } = props;

  return (
    <div
      className="slideNote d-flex align-items-center justify-content-center"
      id="summaryNote"
      style={{ background: "#E4EFF7", height: "100vh" }}
      data-position={slidePosition}
    >
      <div className="slideNoteWrapper">
        <div className="slideNoteContent d-flex align-items-center">
          <div className="container mt-10">
            <div className="d-flex justify-content-center mb-1">
              <h1 className="text-primary fw-bold">
                {t("corrected.congratulations")}
              </h1>
            </div>
            <div className="d-flex justify-content-center mb-4">
              <h3>{t("corrected.succ.sent.activity.teacher")}</h3>
            </div>
            <div className="d-flex justify-content-center mb-4">
              <p className="text-muted">
                {t("corrected.remember.corrected.activity")}
              </p>
            </div>
            <div className="d-flex justify-content-center">
              {/* <a href="javascript:self.history.back()">
                <Button className="btn-primary py-2 px-3">
                  {t("back.to.tasks")}
                </Button>
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
