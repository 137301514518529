import { LessonTestApi } from "./LessonTest.api";
import React from "react";
import { t } from "i18next";

export const LessonTestOverlay = (props) => {
  const { globalState, popup } = props;

  function cancel() {
    globalState.setPopup("");
  }

  function sendTest() {
    LessonTestApi.sendTest(globalState);
  }

  function saveCorrection() {
    LessonTestApi.saveCorrection(globalState);
  }

  function closeCorrection() {
    LessonTestApi.closeCorrection(globalState);
  }

  function sendCorrection() {
    LessonTestApi.sendCorrection(globalState);
  }

  function editCorrection() {
    let location = `${globalState.props.location.pathname}?type=in-review`;

    if (globalState.authToken)
      location += `&auth_token=${globalState.authToken}`;

    if (globalState.role) location += `&role=${globalState.role}`;

    if (globalState.studentId) location += `&uuid=${globalState.studentId}`;

    if (globalState.devMode) location += "&devMode=true";

    if (globalState.teacherMode) location += "&teacherMode=true";

    window.location.replace(location);
  }

  return (
    <div
      className={`overlay ${popup !== "" ? "visible" : "hidden"}`}
      id={`overlayLayer`}
    >
      <div
        className={`popup ${popup === "sendTest" ? "visible" : "hidden"}`}
        id={`sendTestPopup`}
      >
        <h4>{t("send.test")}</h4>

        <p>{t("going.send.test")}</p>

        <div className="buttons">
          <button className="button white cancel" onClick={cancel}>
            <span>{t("cancel")}</span>
          </button>

          <button className={`button blue sendActivity`} onClick={sendTest}>
            <span>{t("send")}</span>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.7905 2.88972L16.7916 2.88933C16.8363 2.87293 16.8847 2.86963 16.9312 2.87981C16.9762 2.88966 17.0175 2.91171 17.0507 2.9435C17.0816 2.97719 17.1028 3.01875 17.1118 3.06367C17.1211 3.11014 17.117 3.1583 17.0999 3.20254L17.0999 3.20251L17.0975 3.20914L12.0975 16.9591L12.0972 16.9599C12.08 17.0074 12.0489 17.0486 12.0078 17.0781C11.9693 17.1057 11.9237 17.1218 11.8765 17.1245C11.831 17.1215 11.7872 17.1061 11.7498 17.0799C11.7098 17.0519 11.6789 17.0128 11.6608 16.9675L11.6606 16.9668L9.26056 10.9668L9.20087 10.8176L9.05165 10.7579L3.05165 8.35787L3.05167 8.35781L3.04537 8.35542C2.99794 8.33743 2.9571 8.30543 2.92828 8.26368C2.89947 8.22193 2.88403 8.1724 2.88403 8.12167C2.88403 8.07094 2.89947 8.02141 2.92828 7.97966C2.95672 7.93846 2.99686 7.90676 3.0435 7.88864L16.7905 2.88972ZM11.5265 15.2636L11.8922 16.181L12.2276 15.2522L16.1838 4.29591L16.4552 3.54451L15.7038 3.81584L4.74752 7.77209L3.82823 8.10403L4.73363 8.47218L9.08363 10.2409L9.31411 10.3346L9.49004 10.1587L12.2436 7.40513L12.5945 7.75604L9.86596 10.4846L9.69135 10.6592L9.78278 10.8886L11.5265 15.2636Z"
                fill="#374047"
                stroke="white"
                strokeWidth="0.75"
              />
            </svg>
          </button>
        </div>
      </div>

      <div
        className={`popup ${popup === "saveCorrection" ? "visible" : "hidden"}`}
        id={`saveCorrectionPopup`}
      >
        <h4>{t("save.activity")}</h4>

        <p>{t("going.save.activity")}</p>

        <div className="buttons">
          <button className="button white cancel" onClick={cancel}>
            <span>{t("cancel")}</span>
          </button>

          <button
            className={`button blue saveCorrection`}
            onClick={() => saveCorrection()}
          >
            <span>{t("save")}</span>
          </button>
        </div>
      </div>

      <div
          className={`popup ${popup === "closeCorrection" ? "visible" : "hidden"}`}
          id={`closeCorrectionPopup`}
      >
        <h4>{t("save.activity")}</h4>

        <p>{t("going.save.activity")}</p>

        <div className="buttons">
          <button className="button white cancel" onClick={cancel}>
            <span>{t("cancel")}</span>
          </button>

          <button
              className={`button blue saveCorrection`}
              onClick={() => closeCorrection()}
          >
            <span>{t("save")}</span>
          </button>
        </div>
      </div>

      <div
        className={`popup ${popup === "editCorrection" ? "visible" : "hidden"}`}
        id={`editCorrectionPopup`}
      >
        <h4>{t("edit.correction")}</h4>

        <p>{t("enter.edit.mode")}</p>

        <div className="buttons">
          <button className="button white cancel" onClick={cancel}>
            <span>{t("cancel")}</span>
          </button>

          <button
            className={`button blue editCorrection`}
            onClick={editCorrection}
          >
            <span>{t("yes")}</span>
          </button>
        </div>
      </div>

      <div
        className={`popup ${popup === "sendCorrection" ? "visible" : "hidden"}`}
        id={`sendCorrectionPopup`}
      >
        <h4>{t("send.corrected.activity")}</h4>

        <p>{globalState.apiInfo?.params?.corrected ? t("send.corrected.activity.student") : t("send.corrected.activity.student.remember") }</p>

        <div className="buttons">
          <button className="button white cancel" onClick={cancel}>
            <span>{t("cancel")}</span>
          </button>

          <button
            className={`button blue sendCorrection`}
            onClick={sendCorrection}
          >
            <span>{t("send")}</span>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.7905 2.88972L16.7916 2.88933C16.8363 2.87293 16.8847 2.86963 16.9312 2.87981C16.9762 2.88966 17.0175 2.91171 17.0507 2.9435C17.0816 2.97719 17.1028 3.01875 17.1118 3.06367C17.1211 3.11014 17.117 3.1583 17.0999 3.20254L17.0999 3.20251L17.0975 3.20914L12.0975 16.9591L12.0972 16.9599C12.08 17.0074 12.0489 17.0486 12.0078 17.0781C11.9693 17.1057 11.9237 17.1218 11.8765 17.1245C11.831 17.1215 11.7872 17.1061 11.7498 17.0799C11.7098 17.0519 11.6789 17.0128 11.6608 16.9675L11.6606 16.9668L9.26056 10.9668L9.20087 10.8176L9.05165 10.7579L3.05165 8.35787L3.05167 8.35781L3.04537 8.35542C2.99794 8.33743 2.9571 8.30543 2.92828 8.26368C2.89947 8.22193 2.88403 8.1724 2.88403 8.12167C2.88403 8.07094 2.89947 8.02141 2.92828 7.97966C2.95672 7.93846 2.99686 7.90676 3.0435 7.88864L16.7905 2.88972ZM11.5265 15.2636L11.8922 16.181L12.2276 15.2522L16.1838 4.29591L16.4552 3.54451L15.7038 3.81584L4.74752 7.77209L3.82823 8.10403L4.73363 8.47218L9.08363 10.2409L9.31411 10.3346L9.49004 10.1587L12.2436 7.40513L12.5945 7.75604L9.86596 10.4846L9.69135 10.6592L9.78278 10.8886L11.5265 15.2636Z"
                fill="#374047"
                stroke="white"
                strokeWidth="0.75"
              />
            </svg>
          </button>
        </div>
      </div>

      <div
        className={`popup ${
          popup === "sendUnfinishedCorrection" ? "visible" : "hidden"
        }`}
        id={`sendCorrectionPopup`}
      >
        <h4>{t("send.corrected.activity")}</h4>

        <p>{t("send.corrected.activity.student")}</p>

        <div className="buttons">
          <button className="button white cancel" onClick={cancel}>
            <span>{t("cancel")}</span>
          </button>

          <button
            className={`button blue sendCorrection`}
            onClick={sendCorrection}
          >
            <span>{t("send")}</span>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.7905 2.88972L16.7916 2.88933C16.8363 2.87293 16.8847 2.86963 16.9312 2.87981C16.9762 2.88966 17.0175 2.91171 17.0507 2.9435C17.0816 2.97719 17.1028 3.01875 17.1118 3.06367C17.1211 3.11014 17.117 3.1583 17.0999 3.20254L17.0999 3.20251L17.0975 3.20914L12.0975 16.9591L12.0972 16.9599C12.08 17.0074 12.0489 17.0486 12.0078 17.0781C11.9693 17.1057 11.9237 17.1218 11.8765 17.1245C11.831 17.1215 11.7872 17.1061 11.7498 17.0799C11.7098 17.0519 11.6789 17.0128 11.6608 16.9675L11.6606 16.9668L9.26056 10.9668L9.20087 10.8176L9.05165 10.7579L3.05165 8.35787L3.05167 8.35781L3.04537 8.35542C2.99794 8.33743 2.9571 8.30543 2.92828 8.26368C2.89947 8.22193 2.88403 8.1724 2.88403 8.12167C2.88403 8.07094 2.89947 8.02141 2.92828 7.97966C2.95672 7.93846 2.99686 7.90676 3.0435 7.88864L16.7905 2.88972ZM11.5265 15.2636L11.8922 16.181L12.2276 15.2522L16.1838 4.29591L16.4552 3.54451L15.7038 3.81584L4.74752 7.77209L3.82823 8.10403L4.73363 8.47218L9.08363 10.2409L9.31411 10.3346L9.49004 10.1587L12.2436 7.40513L12.5945 7.75604L9.86596 10.4846L9.69135 10.6592L9.78278 10.8886L11.5265 15.2636Z"
                fill="#374047"
                stroke="white"
                strokeWidth="0.75"
              />
            </svg>
          </button>
        </div>
      </div>

      {/* <div
        className={`popup ${
          popup === "sendUnfinishedCorrection" ? "visible" : "hidden"
        }`}
        id={`sendUnfinishedCorrectionPopup`}
      >
        <h4>
          <svg
            className={`me-2`}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10 0.410156C4.75341 0.410156 0.5 4.66357 0.5 9.91016C0.5 15.1567 4.75341 19.4102 10 19.4102C15.2466 19.4102 19.5 15.1567 19.5 9.91016C19.5 4.66357 15.2466 0.410156 10 0.410156ZM10.8636 5.59197C10.8636 5.36292 10.7726 5.14325 10.6107 4.98129C10.4487 4.81933 10.2291 4.72834 10 4.72834C9.77095 4.72834 9.55128 4.81933 9.38932 4.98129C9.22735 5.14325 9.13636 5.36292 9.13636 5.59197V10.7738C9.13636 11.0028 9.22735 11.2225 9.38932 11.3845C9.55128 11.5464 9.77095 11.6374 10 11.6374C10.2291 11.6374 10.4487 11.5464 10.6107 11.3845C10.7726 11.2225 10.8636 11.0028 10.8636 10.7738V5.59197ZM10.8636 13.7965C10.8636 13.5675 10.7726 13.3478 10.6107 13.1858C10.4487 13.0239 10.2291 12.9329 10 12.9329C9.77095 12.9329 9.55128 13.0239 9.38932 13.1858C9.22735 13.3478 9.13636 13.5675 9.13636 13.7965V14.2283C9.13636 14.4574 9.22735 14.6771 9.38932 14.839C9.55128 15.001 9.77095 15.092 10 15.092C10.2291 15.092 10.4487 15.001 10.6107 14.839C10.7726 14.6771 10.8636 14.4574 10.8636 14.2283V13.7965Z"
              fill="#FF4D4D"
            />
          </svg>
          {t("send.uncorrected.activity")}
        </h4>

        <p>{t("cannot.send.uncorrected")}</p>

        <div className="buttons">
          <button className="button white cancel" onClick={cancel}>
            <span>{t("cancel")}</span>
          </button>

          <button
            className={`button red sendUnfinishedCorrection`}
            onClick={sendCorrection}
          >
            <span>OK</span>
          </button>
        </div>
      </div> */}

      <div
        className={`popup ${
          popup === "authenticationError" ? "visible" : "hidden"
        }`}
        id={`authenticationErrorPopup`}
      >
        <h4>{t("auth.error")}</h4>

        <p>Auth token: {globalState.authToken}</p>

        <div className="buttons">
          <button className="button white cancel" onClick={cancel}>
            <span>Ok</span>
          </button>
        </div>
      </div>

      <div
        className={`popup ${popup === "notFoundError" ? "visible" : "hidden"}`}
        id={`notFoundErrorPopup`}
      >
        <h4>{t("not.found.error")}</h4>

        <div className="buttons">
          <button className="button white cancel" onClick={cancel}>
            <span>Ok</span>
          </button>
        </div>
      </div>
    </div>
  );
};
