import { gql } from "@apollo/client";
import { Client_GraphQL } from "../../Client_GraphQL";

export class LessonActivityGraphql {
  static getOrCreateGraphQLClient() {
    if (this.graphQLClient == null) this.graphQLClient = Client_GraphQL();
    return this.graphQLClient;
  }

  static getGradeGraphQLCall(activityId, unitId, language) {
    return gql`
            query getActivity{
              cumulativeActivities(id: "${activityId}", locale:"${language}"){
                idName
                title
                cumulativeActivityBlocksCollection{
                  items{
                    sys{
                      id
                    }
                    digitalTemplate
                    content{
                      json
                    }
                    review {
                      json
                    }
                  }
                }
                rubricsCollection(limit:2){
                  items{
                    sys{
                      id
                    }
                    idName
                    evaluationItem{
                      idName
                      evaluationItemText
                      competence{
                        linkedFrom(allowedLocales: ["es-ES", "ca", "en-CA"]) {
                          dimensionsCollection(limit:1) {
                            items {
                              name
                            }
                          }
                        }
                        sys{
                          id
                        }
                        name
                        alias
                      }
                    }
                    level1Description{
                      json
                    }
                    level2Description{
                      json
                    }
                    level3Description{
                      json
                    }
                    level4Description{
                      json
                    }
                  }
                }
              }
              units(id: "${unitId}", locale:"${language}"){
                name
                idName
              }
            }
        `;
  }

  static getExerciseGraphQLCall(activityBlockId, language) {
    return gql`
            query getActivityBlock{
              activityBlock(id: "${activityBlockId}", locale:"${language}"){
                sys{
                  id
                }
                idName
                digitalTemplate
                statement{
                  json
                  links{
                    assets{
                      block{
                        url
                        sys{
                          id
                        }
                      }
                    }
                  }
                }
                content{
                  json
                  links{
                    assets{
                      block{
                        url
                        sys{
                          id
                        }
                      }
                    }
                  }
                }
                answers
                answersFormat
                multipleTextFormat
                openAnswer{
                  json
                  links{
                    assets{
                      block{
                        url
                        sys{
                          id
                        }
                      }
                    }
                  }
                }
                review{
                  json
                  links{
                    assets{
                      block{
                        url
                        sys{
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
        `;
  }
}
