import React, { Component } from 'react'
import {Link, useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next';
import {withRouter} from "../Utils/withRouter";
import logo from "../images/LogoJM.jpg";

class Navbar extends Component {

    constructor() {
        super();
        this.state = {
            language: "",
            env: "master"
        }
    }

    componentDidMount() {
        const { i18n, t, match } = this.props;

        this.setState({language: this.props.i18n.language})
        this.setState({env: this.props.env})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.t !== prevProps.t) {
            this.setState({language: this.props.i18n.language})
        }
    }

    render() {
        const { i18n, t } = this.props;

        return (
            <div>
                <nav className="navbar navbar-expand-md navbar-dark">
                    <div className="container">
                        <Link className="navbar-brand" to="../"><img src={logo}/></Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse justify-content-end" id="navbarCollapse">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link className="nav-link" to={`/${this.state.language}/staging/`}>Home</Link>
                                </li>
                                {/*<li className="nav-item">
                                    <Link className="nav-link" to={`/${this.state.language}/staging/countries`}>{t('navbar.countries')}</Link>
                                </li>*/}
                                <li className="nav-item">
                                    <Link className="nav-link" to={`/${this.state.language}/staging/grades`}>{t('navbar.grades')}</Link>
                                </li>
                                {/*<li className="nav-item">
                                    <Link className="nav-link" to={`/${this.state.language}/staging/activities`}>{t('navbar.activities')}</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to={`/${this.state.language}/staging/tests`}>{t('navbar.tests')}</Link>
                                </li>*/}
                                <li className="nav-item">
                                    <Link className="nav-link" to={`/${this.state.language}/staging/lessons`}>{t('navbar.lessons')}</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to={`/${this.state.language}/staging/exerciseBlocks`}>Exercise blocks</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to={`https://translationapp.jumpmath.es/`} target={"_blank"}>{t('navbar.translations')}</Link>
                                </li>
                            </ul>

                        </div>
                    </div>
                </nav>
            </div>
        );
    }
}

export default withRouter(Navbar)