import { getApartados, getContentBlockToShow } from "../../ExerciseBlocks";
import React, { useState, useLayoutEffect } from "react";
import { useQuery } from "@apollo/client";
import BlackLoader from "../../../images/black-loader.gif";
import { useTranslation } from "react-i18next";
import { LessonTestGraphql } from "./LessonTest.graphql";
import { DigitalExerciseBlock1 } from "../DigitalExercises/DigitalExerciseBlock1";
import { DigitalExerciseBlock2 } from "../DigitalExercises/DigitalExerciseBlock2";
import { DigitalExerciseBlock3 } from "../DigitalExercises/DigitalExerciseBlock3";
import { DigitalExerciseBlock4 } from "../DigitalExercises/DigitalExerciseBlock4";
import { DigitalExerciseBlock5 } from "../DigitalExercises/DigitalExerciseBlock5";
import { DigitalExerciseBlock6 } from "../DigitalExercises/DigitalExerciseBlock6";
import { DigitalExerciseBlock7 } from "../DigitalExercises/DigitalExerciseBlock7";
import $ from "jquery";
import { LessonTestFunctions } from "./LessonTest.functions";
import { twoDecimals } from "../../../Utils/twoDecimals";
import StudentProfileName from "../../../Components/StudentProfileName";
import TeacherCommentsAsStudent from "../../../Components/TeacherCommentsAsStudent";
import TeacherCommentsAsTeacher from "../../../Components/TeacherCommentsAsTeacher";
import {DigitalExerciseBlock8} from "../DigitalExercises/DigitalExerciseBlock8";
import DigitalTemplateNumber from "../../../Components/DigitalTemplateNumber";

const LessonExerciseNote = (props) => {
  let { i18n } = useTranslation();

  const { globalState, exerciseInfo, exercises, corrections } = props;

  const [correction, setCorrection] = useState(
    corrections[exerciseInfo.position - 1]
  );

  useLayoutEffect(() => {
    const updatedCorrections = corrections.map((correctionToUpdate) => {
      if (correctionToUpdate.exerciseBlock_id === correction.exerciseBlock_id) {
        return {
          ...correctionToUpdate,
          score: twoDecimals(correction.score),
          feedback: correction.feedback,
          level: correction.level,
          correct: correction.correct,
          corrected: correction.corrected,
          answered: correction.answered,
        };
      }

      return correctionToUpdate;
    });

    globalState.setCorrections(updatedCorrections);
  }, [correction]);

  const { loading, error, data } = useQuery(
    LessonTestGraphql.getExerciseGraphQLCall(
      exerciseInfo.exerciseBlock.sys.id,
      i18n.language
    ),
    {
      client: LessonTestGraphql.getOrCreateGraphQLClient(),
      errorPolicy: "ignore"
    }
  );

  if (loading)
    return (
      <div align="center" className="pt-5">
        {" "}
        <img src={BlackLoader} alt="Loader" />{" "}
      </div>
    );
  if (error) return <p>Error :(</p>;

  exerciseInfo.exerciseBlock = data.testBlock;

  let statement = exerciseInfo.exerciseBlock.statement;

  let apartadosStatement = getApartados(statement.json);

  let exerciseBlockToShow = getDigitalExerciseBlockToShow(
    exerciseInfo,
    globalState,
    exercises
  );

  function refreshCorrection(e) {
    let feedbackValue = $(
      `.slideNote${exerciseInfo.slidePosition} .slideNoteTeacherComments textarea[name="feedback"]`
    ).val();
    let scoreValue = $(
      `.slideNote${exerciseInfo.slidePosition} .slideNoteTeacherComments input[name="score"]`
    ).val();

    if (isNaN(scoreValue)) scoreValue = 0;

    let currentCorrection = {
      ...correction,
      feedback: feedbackValue,
      score: scoreValue > 10 ? 10 : scoreValue,
      level: LessonTestFunctions.getLevelFromScore(scoreValue),
      correct: LessonTestFunctions.IsCorrectFromScore(scoreValue),
      corrected: true,
    };

    setCorrection(currentCorrection);
  }

  let showTeacherComments = globalState.mode.includes("in_review") || globalState.mode === "reviewed";

  return (
    <div
      className={`slideNote slideNote${exerciseInfo.slidePosition} `}
      data-position={exerciseInfo.slidePosition}>
      {globalState.apiInfo.student && globalState.role !== "student" ? (
          <StudentProfileName
              studentProfile={globalState.apiInfo.student}
              mode='fixed'
          />
      ) : null}
      <div className={`slideNoteWrapper ${ showTeacherComments ? "additionalPadding" : "" }`}>
        <div className="slideNoteContent">
          <div className="container my-0">
            <div className="row statement">
              <DigitalTemplateNumber globalState={globalState} exerciseInfo={exerciseInfo} />

              {apartadosStatement.map((apartado, index) => {
                return (
                  <div
                    className="col-12"
                    key={`slideNote${exerciseInfo.slidePosition}-${index}`}
                  >
                    {apartado.map((contentBlock, indexb) => {
                      return (
                        <div
                          className="contentBlock"
                          key={`slideNote${exerciseInfo.slidePosition}-${index}-${indexb}`}
                        >
                          {getContentBlockToShow(
                            contentBlock,
                            false,
                            `slideNote${exerciseInfo.slidePosition}-${index}-${indexb}`,
                            statement.links?.assets?.block
                          )}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>

            <form
              action=""
              id={`exercise_${exerciseInfo.exerciseBlock.sys.id}`}
            >
              {exerciseBlockToShow}
            </form>
          </div>
        </div>
      </div>

      {showTeacherComments ? (
        globalState.role === "teacher" ?
            <TeacherCommentsAsTeacher globalState={globalState} correction={correction} exerciseInfo={exerciseInfo} refreshCorrection={refreshCorrection} />
            : <TeacherCommentsAsStudent correction={correction} showScore={true} />
      ) : null}

    </div>
  );
};

function getDigitalExerciseBlockToShow(exerciseInfo, globalState, exercises) {
  function parseAnswers(answersText) {
    let allAnswers = [];
    if (answersText) {
      let regexAnswer = /\[\[(.*?)\]\]/gm;
      let matches = [...answersText.matchAll(regexAnswer)];
      if (matches.length > 0) {
        for (const match of matches) {
          let answers = [];
          for (const answer of match[1].split("|")) {
            answers.push(answer);
          }
          allAnswers.push(answers);
        }
      }
    }
    return allAnswers;
  }

  let answers = parseAnswers(exerciseInfo.exerciseBlock.answers);

  exerciseInfo.answers = answers;

  switch (exerciseInfo.exerciseBlock.digitalTemplate) {
    case 1:
      return (
        <DigitalExerciseBlock1
          assets={exerciseInfo.exerciseBlock.content?.links?.assets?.block}
          exerciseInfo={exerciseInfo}
          globalState={globalState}
          exercises={exercises}
        />
      );
    case 2:
      return (
        <DigitalExerciseBlock2
          assets={exerciseInfo.exerciseBlock.content?.links?.assets?.block}
          exerciseInfo={exerciseInfo}
          globalState={globalState}
          exercises={exercises}
        />
      );
    case 3:
      return (
        <DigitalExerciseBlock3
          assets={exerciseInfo.exerciseBlock.content?.links?.assets?.block}
          exerciseInfo={exerciseInfo}
          globalState={globalState}
          exercises={exercises}
        />
      );
    case 4:
      return (
        <DigitalExerciseBlock4
          assets={exerciseInfo.exerciseBlock.content?.links?.assets?.block}
          exerciseInfo={exerciseInfo}
          globalState={globalState}
          exercises={exercises}
        />
      );
    case 5:
      return (
        <DigitalExerciseBlock5
          assets={exerciseInfo.exerciseBlock.content?.links?.assets?.block}
          exerciseInfo={exerciseInfo}
          globalState={globalState}
          exercises={exercises}
        />
      );
    case 6:
      return (
        <DigitalExerciseBlock6
          assets={exerciseInfo.exerciseBlock.content?.links?.assets?.block}
          exerciseInfo={exerciseInfo}
          globalState={globalState}
          exercises={exercises}
        />
      );
    case 7:
      return (
        <DigitalExerciseBlock7
          assets={exerciseInfo.exerciseBlock.content?.links?.assets?.block}
          exerciseInfo={exerciseInfo}
          globalState={globalState}
          exercises={exercises}
        />
      );
    case 8:
      return (
          <DigitalExerciseBlock8
              assets={exerciseInfo.exerciseBlock.content?.links?.assets?.block}
              exerciseInfo={exerciseInfo}
              globalState={globalState}
              exercises={exercises}
          />
      );
    default:
      return <></>;
  }
}

export default LessonExerciseNote;
