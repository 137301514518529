import { Link } from "react-router-dom";
import {ButtonGroup, Dropdown} from "react-bootstrap";
import Button from "react-bootstrap/Button";

export const LessonsListFragment = (props) => {
  const { items, t, unitId, gradeId, i18n } = props;

  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th>{t("navbar.lessons")}</th>
          <th>{t("navbar.exercises")}</th>
          <th>{t("navbar.activities")}</th>
          <th>{t("navbar.tests")}</th>
        </tr>
      </thead>

      <tbody>
        {items?.map((lesson, index) => {
          let unit =
            lesson.linkedFrom?.unitsCollection?.items?.length > 0
              ? lesson.linkedFrom.unitsCollection.items[0]
              : null;
          let grade =
            unit?.linkedFrom?.gradesCollection?.items?.length > 0
              ? unit.linkedFrom.gradesCollection.items[0]
              : null;

          let _unitId = unit != null ? unit.sys.id : unitId;
          let _gradeId = grade != null ? grade.sys.id : gradeId;

          if (_unitId !== undefined && _gradeId !== undefined) {
            if (lesson.__typename === "Lessons") {
              return (
                <tr key={index}>
                  <td className={`title`}>
                    {lesson.idName}
                    {"  -  "}
                    {lesson.name}
                  </td>
                  <td>

                      <Dropdown as={ButtonGroup} size="sm" className="me-2">
                          <Link className="btn btn-primary btn-sm ps-4 pe-3"
                                to={`../lessons/${_gradeId}/${_unitId}/${lesson.sys.id}/exercises?type=display`}>
                              {t("lessons.ver")}
                          </Link>

                          <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />

                          <Dropdown.Menu>
                              <Dropdown.Item href={`/${i18n.language}/public/lessons/${_gradeId}/${_unitId}/${lesson.sys.id}/exercises?type=display`}>{t("lessons.ver.student")}</Dropdown.Item>
                              <Dropdown.Item href={`/${i18n.language}/public/lessons/${_gradeId}/${_unitId}/${lesson.sys.id}/exercises?type=display&role=teacher`}>{t("lessons.ver.teacher")}</Dropdown.Item>
                              <Dropdown.Item href={`/${i18n.language}/public/lessons/${_gradeId}/${_unitId}/${lesson.sys.id}/exercises?type=display&devMode=true`}>{t("lessons.ver.devMode")}</Dropdown.Item>
                              <Dropdown.Item href={`/${i18n.language}/public/lessons/${_gradeId}/${_unitId}/${lesson.sys.id}/exercises?type=display&role=teacher&teacherMode=true`}>{t("lessons.ver.teacherMode")}</Dropdown.Item>
                          </Dropdown.Menu>
                      </Dropdown>

                    <Link
                      className="btn btn-primary btn-sm mx-2"
                      to={`../lessons/${_gradeId}/${_unitId}/${lesson.sys.id}/exercises?type=solve`}
                    >
                      {t("lessons.resolver")}
                    </Link>

                    <Link
                      className="btn btn-secondary btn-sm mx-2"
                      to={`../lessons/${_gradeId}/${_unitId}/${lesson.sys.id}/exercises?type=solve&useTestUser=true`}
                    >
                      {t("lessons.testear")}
                    </Link>
                  </td>
                  <td>
                    {lesson.cumulativeActivitiesCollection?.items.map(
                      (activity, activityIndex) => {
                        return (
                          <div key={`activity${activityIndex}`}>

                              <Dropdown as={ButtonGroup} size="sm" className="me-2">
                                  <Link className="btn btn-primary btn-sm ps-4 pe-3"
                                        to={`../lessons/${_gradeId}/${_unitId}/${activity.sys.id}/cumulative-activity?type=display`}>
                                      {t("lessons.ver")}
                                  </Link>

                                  <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />

                                  <Dropdown.Menu>
                                      <Dropdown.Item href={`/${i18n.language}/public/lessons/${_gradeId}/${_unitId}/${activity.sys.id}/cumulative-activity?type=display`}>{t("lessons.ver.student")}</Dropdown.Item>
                                      <Dropdown.Item href={`/${i18n.language}/public/lessons/${_gradeId}/${_unitId}/${activity.sys.id}/cumulative-activity?type=display&role=teacher`}>{t("lessons.ver.teacher")}</Dropdown.Item>
                                      <Dropdown.Item href={`/${i18n.language}/public/lessons/${_gradeId}/${_unitId}/${activity.sys.id}/cumulative-activity?type=display&devMode=true`}>{t("lessons.ver.devMode")}</Dropdown.Item>
                                      <Dropdown.Item href={`/${i18n.language}/public/lessons/${_gradeId}/${_unitId}/${activity.sys.id}/cumulative-activity?type=display&role=teacher&teacherMode=true`}>{t("lessons.ver.teacherMode")}</Dropdown.Item>
                                  </Dropdown.Menu>
                              </Dropdown>

                            <Link
                              className="btn btn-primary btn-sm mx-2"
                              to={`../lessons/${_gradeId}/${_unitId}/${activity.sys.id}/cumulative-activity?type=not-initialized`}
                            >
                              {t("lessons.resolver")}
                            </Link>
                            <Link
                              className="btn btn-secondary btn-sm mx-2"
                              to={`../lessons/${_gradeId}/${_unitId}/${activity.sys.id}/cumulative-activity?type=not-initialized&useTestUser=true&devMode=true`}
                            >
                              {t("lessons.testear")}
                            </Link>
                          </div>
                        );
                      }
                    )}
                  </td>
                  <td></td>
                </tr>
              );
            } else {
              return (
                <tr key={index}>
                  <td className={`title`}>{lesson.name}</td>
                  <td></td>
                  <td></td>
                  <td>
                      <Dropdown as={ButtonGroup} size="sm" className="me-2">
                          <Link className="btn btn-primary btn-sm ps-4 pe-3"
                                to={`../lessons/${_gradeId}/${_unitId}/${lesson.sys.id}/test?type=display`}>
                              {t("lessons.ver")}
                          </Link>

                          <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />

                          <Dropdown.Menu>
                              <Dropdown.Item href={`/${i18n.language}/public/lessons/${_gradeId}/${_unitId}/${lesson.sys.id}/test?type=opened`}>{t("lessons.ver.opened")}</Dropdown.Item>
                              <Dropdown.Item href={`/${i18n.language}/public/lessons/${_gradeId}/${_unitId}/${lesson.sys.id}/test?type=delivered`}>{t("lessons.ver.delivered")}</Dropdown.Item>
                          </Dropdown.Menu>
                      </Dropdown>
                    <Link
                        className="btn btn-secondary btn-sm mx-2"
                        to={`../lessons/${_gradeId}/${_unitId}/${lesson.sys.id}/test?type=started&useTestUser=true&devMode=true`}>
                        {t("lessons.testear")}
                    </Link>
                  </td>
                </tr>
              );
            }
          } else {
            return (
              <tr key={index}>
                <td className={`title`}>{lesson.name}</td>
                <td className="text-end" colSpan={`3`}>
                  {t("lessons.no_units")}
                </td>
              </tr>
            );
          }
        })}
      </tbody>
    </table>
  );
};
