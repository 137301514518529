import { useEffect, useState } from "react";
import StudentProfileName from "../../../Components/StudentProfileName";
import { t } from "i18next";
import StatusBanner from "../../../Components/StatusBanner";
import ExerciseAttemptInfo from "../../../Components/ExerciseAttemptInfo";
import {LessonExerciseApi} from "./LessonExercise.api";
import {LessonExerciseFunctions} from "./LessonExercise.functions";
import LessonHeader from "../../../Components/LessonHeader";

export const LessonExerciseAttemptNote = (props) => {
    const {
        slidePosition,
        globalState,
        rubrics,
        deadLine,
        mode,
        apiInfo,
        statusBanner,
        exercises,
    } = props;

    const [attemptResults, setAttemptResults] = useState({});
    function getExerciseAnswers(exercises) {
        let results = [];
        for (let exercise of exercises) {
            results.push(exercise.results);
        }
        return results;
    }

    useEffect(() => {
        let answers = getExerciseAnswers(exercises);
        let newAttemptResults = {
            score: LessonExerciseApi.getScore(LessonExerciseFunctions.calculateResults(globalState, exercises)),
            answers: answers
        }
        setAttemptResults(newAttemptResults);
    }, [exercises]);

    return (
        <div
            className={`slideNote slideNote${slidePosition} initialNote`}
            id="initialNote"
            key={`initialNote`}
            data-position={slidePosition}
            data-navigation="false">

            {statusBanner !== "" ? (
                <StatusBanner
                    lessonType={globalState.lessonType}
                    statusBannerType={statusBanner} />
            ) : null}

            <div className="slideNoteWrapper">

                {globalState.apiInfo.student && globalState.role !== "student" ? (
                    <StudentProfileName
                        studentProfile={globalState.apiInfo.student}
                        mode='inline'
                    />
                ) : null}

                <div className="slideNoteContent">
                    <div className="container">

                        <LessonHeader globalState={globalState} deadLine={deadLine} mode={mode} apiInfo={apiInfo}
                                      rubrics={rubrics} lessonName={globalState.lesson.name} />

                        {mode === "solve_retry" ? (
                            <div className="row">
                                <div className="col-12">
                                    <ExerciseAttemptInfo exercises={exercises} results={attemptResults} globalState={globalState} title={t("average.current")} showBadge={true} />
                                </div>
                                <div className="col-12 mt-4">
                                    <ExerciseAttemptInfo exercises={exercises} results={apiInfo.params.answers.results} globalState={globalState} title={t("average.first")} showBadge={false} />
                                </div>
                            </div>
                        ) : null}

                    </div>
                </div>
            </div>
        </div>
    );
};
