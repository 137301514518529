import { t } from "i18next";
import $ from "jquery";
import React, { useEffect, useState } from "react";

export const LessonActivityNavigationBar = (props) => {
  const { globalState, mode, currentSlidePosition } = props;

  useEffect(() => {
    refreshNavigation();
  });

  function refreshNavigation() {
    refreshProgressBar();
    refreshNavigationButtons();

    // $(".sidebarLink").removeClass("selected");
    // $(`#sidebarLink${currentSlidePosition - 1}`).addClass("selected");
  }

  function refreshNavigationButtons() {}

  function refreshProgressBar() {
    $(".numSlides .position").text(`${currentSlidePosition - 1}`);
    $(".numSlides .total").text(`${globalState.exercises.length}`);

    let width = 0;

    if (currentSlidePosition > 1) {
      if (currentSlidePosition - 1 < globalState.exercises.length)
        width = Math.floor(
          ($(".line-bg").width() * (currentSlidePosition - 1)) /
            globalState.exercises.length
        );
      else width = $(".line-bg").width();
    }

    $(".line-progress").animate(
      {
        width: width,
      },
      200,
      function () {
        // Animation complete.
      }
    );
  }

  return (
    <div className="slideNotesBottomMenu">
      <div className="navigation">
        <div className="lines">
          <div className="line-bg"></div>
          <div className="line-progress"></div>
        </div>
      </div>

      <NavBarLeft
        globalState={globalState}
        mode={mode}
        currentSlidePosition={currentSlidePosition}
      />

      <NavBarCenter
        globalState={globalState}
        mode={mode}
        currentSlidePosition={currentSlidePosition}
      />

      <NavBarRight
        globalState={globalState}
        mode={mode}
        currentSlidePosition={currentSlidePosition}
      />
    </div>
  );
};

const NavBarCenter = (props) => {
  const { globalState, mode, currentSlidePosition } = props;

  function startExercise() {
    $(".slideNotes").slick("slickNext");
  }

  function leftArrowButton() {
    return (
      <>
        <button
          className={`button white leftArrow ${
            currentSlidePosition >= 2 ? "visible" : "hidden"
          }`}
        >
          <svg
            width="10"
            height="16"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.49998 1.33334L1.83331 8.00001L8.49998 14.6667"
              stroke="#0D70B6"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <span>
            {currentSlidePosition === 2 ? t("previous") : t("previous")}
          </span>
        </button>
      </>
    );
  }

  function numSlidesButton() {
    return (
      <>
        <div
          className={`numSlides ${
            currentSlidePosition >= 2 &&
            currentSlidePosition < globalState.slides.length
              ? "visible"
              : "hidden"
          }`}
        >
          <span className={`position`}></span>
          <span className={`slash`}>/</span>
          <span className={`total`}></span>
        </div>
      </>
    );
  }

  function rightArrowButton() {
    let isSummary = currentSlidePosition === globalState.slides.length - 1;
    let isVisible =
      currentSlidePosition >= 2 &&
      currentSlidePosition < globalState.slides.length;
    if (
      (mode === "display_with_data" ||
        mode === "display_without_data" ||
        mode === "reviewed") &&
      isSummary
    ) {
      isVisible = false;
    }
    return (
      <>
        <button
          className={`button rightArrow ${isVisible ? "visible" : "hidden"}`}
        >
          <span>{isSummary ? t("summary") : t("next")}</span>
          <svg
            width="10"
            height="16"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.66669 1.33334L8.33335 8.00001L1.66669 14.6667"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </>
    );
  }

  function navigationButtons() {
    return (
      <>
        {leftArrowButton()}
        {numSlidesButton()}
        {rightArrowButton()}
      </>
    );
  }

  return (
    <div className={`arrows`}>
      {(() => {
        switch (mode) {
          case "loading":
            return <div></div>;
          case "blocked":
            return (
              <div>
                <button className={`button disabled`} onClick={startExercise}>
                  <span>{t("start.activity")}</span>
                </button>
              </div>
            );
          case "solve_first_time":
          case "solve_continue":
            return (
              <div>
                <button
                  className={`button ${
                    currentSlidePosition === 1 ? "visible" : "hidden"
                  }`}
                  onClick={startExercise}
                >
                  <span>{t("start.activity")}</span>
                </button>

                {navigationButtons()}
              </div>
            );
          case "display_with_data":
          case "display_without_data":
            return (
              <div>
                <button
                  className={`button ${
                    currentSlidePosition === 1 ? "visible" : "hidden"
                  }`}
                  onClick={startExercise}
                >
                  <span>{t("review.activity")}</span>
                </button>

                {navigationButtons()}
              </div>
            );
          case "in_review_first_time":
          case "in_review_continue":
            return (
              <div>
                <button
                  className={`button  ${
                    currentSlidePosition === 1 ? "visible" : "hidden"
                  }`}
                  onClick={startExercise}
                >
                  <span>{t("correct.activity")}</span>
                </button>
                {navigationButtons()}
              </div>
            );
          case "reviewed":
            return (
              <div>
                <button
                  className={`button ${
                    currentSlidePosition === 1 ? "visible" : "hidden"
                  }`}
                  onClick={startExercise}
                >
                  <span>{t("view.correction")}</span>
                </button>
                {navigationButtons()}
              </div>
            );
          default:
            return <div></div>;
        }
      })()}
    </div>
  );
};

const NavBarRight = (props) => {
  const { globalState, mode, currentSlidePosition } = props;

  function retryExercise() {
    globalState.setPopup("retryExercises");
  }

  function saveCorrection() {
    globalState.setPopup("saveCorrection");
  }

  function closeCorrection() {
    globalState.setPopup("closeCorrection");
  }

  function sendCorrection() {
    if (
      globalState.corrections.filter((e) => e.corrected).length <
      globalState.exercises.filter((e) => e.results.answered).length
    ) {
      globalState.setPopup("sendUnfinishedCorrection");
    } else {
      globalState.setPopup("sendCorrection");
    }
  }

  function editCorrection() {
    globalState.setPopup("editCorrection");
  }

  return (
    <div className={`rightButtons`}>
      {(() => {
        switch (mode) {
          case "loading":
            return <div></div>;
          case "blocked":
            return <div></div>;
          case "solve_first_time":
          case "solve_continue":
            if (globalState.authToken == "") return <div></div>;
            else {
              return (
                <div>
                  <button
                    className={`button sendActivityButton ${
                      currentSlidePosition === globalState.slides.length ||
                      globalState.devMode
                        ? "visible"
                        : "hidden"
                    }`}
                    onClick={globalState.functions.sendActivity}
                  >
                    <span>{t("send")}</span>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.7905 2.88972L16.7916 2.88933C16.8363 2.87293 16.8847 2.86963 16.9312 2.87981C16.9762 2.88966 17.0175 2.91171 17.0507 2.9435C17.0816 2.97719 17.1028 3.01875 17.1118 3.06367C17.1211 3.11014 17.117 3.1583 17.0999 3.20254L17.0999 3.20251L17.0975 3.20914L12.0975 16.9591L12.0972 16.9599C12.08 17.0074 12.0489 17.0486 12.0078 17.0781C11.9693 17.1057 11.9237 17.1218 11.8765 17.1245C11.831 17.1215 11.7872 17.1061 11.7498 17.0799C11.7098 17.0519 11.6789 17.0128 11.6608 16.9675L11.6606 16.9668L9.26056 10.9668L9.20087 10.8176L9.05165 10.7579L3.05165 8.35787L3.05167 8.35781L3.04537 8.35542C2.99794 8.33743 2.9571 8.30543 2.92828 8.26368C2.89947 8.22193 2.88403 8.1724 2.88403 8.12167C2.88403 8.07094 2.89947 8.02141 2.92828 7.97966C2.95672 7.93846 2.99686 7.90676 3.0435 7.88864L16.7905 2.88972ZM11.5265 15.2636L11.8922 16.181L12.2276 15.2522L16.1838 4.29591L16.4552 3.54451L15.7038 3.81584L4.74752 7.77209L3.82823 8.10403L4.73363 8.47218L9.08363 10.2409L9.31411 10.3346L9.49004 10.1587L12.2436 7.40513L12.5945 7.75604L9.86596 10.4846L9.69135 10.6592L9.78278 10.8886L11.5265 15.2636Z"
                        fill="#374047"
                        stroke="white"
                        strokeWidth="0.75"
                      />
                    </svg>
                  </button>
                </div>
              );
            }
          case "display_with_data":
            if (globalState.role === "student") {
              return (
                <div>
                  <button
                    className={`button white retryExerciseButton`}
                    onClick={retryExercise}
                  >
                    <span>{t("retry.activity")}</span>
                  </button>
                </div>
              );
            } else {
              return <div></div>;
            }
          case "display_without_data":
            return <div></div>;
          case "in_review_first_time":
          case "in_review_continue":
            if(globalState.apiInfo?.params?.close_correction && globalState.apiInfo?.params?.forwarded) {
              return (
                  <div>
                    <button
                        className={`button sendActivityButton ${
                            currentSlidePosition === globalState.slides.length ||
                            globalState.devMode
                                ? "visible"
                                : "hidden"
                        }`}
                        onClick={sendCorrection}
                    >
                      <span>{t("save_and_send")}</span>
                      <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                            d="M16.7905 2.88972L16.7916 2.88933C16.8363 2.87293 16.8847 2.86963 16.9312 2.87981C16.9762 2.88966 17.0175 2.91171 17.0507 2.9435C17.0816 2.97719 17.1028 3.01875 17.1118 3.06367C17.1211 3.11014 17.117 3.1583 17.0999 3.20254L17.0999 3.20251L17.0975 3.20914L12.0975 16.9591L12.0972 16.9599C12.08 17.0074 12.0489 17.0486 12.0078 17.0781C11.9693 17.1057 11.9237 17.1218 11.8765 17.1245C11.831 17.1215 11.7872 17.1061 11.7498 17.0799C11.7098 17.0519 11.6789 17.0128 11.6608 16.9675L11.6606 16.9668L9.26056 10.9668L9.20087 10.8176L9.05165 10.7579L3.05165 8.35787L3.05167 8.35781L3.04537 8.35542C2.99794 8.33743 2.9571 8.30543 2.92828 8.26368C2.89947 8.22193 2.88403 8.1724 2.88403 8.12167C2.88403 8.07094 2.89947 8.02141 2.92828 7.97966C2.95672 7.93846 2.99686 7.90676 3.0435 7.88864L16.7905 2.88972ZM11.5265 15.2636L11.8922 16.181L12.2276 15.2522L16.1838 4.29591L16.4552 3.54451L15.7038 3.81584L4.74752 7.77209L3.82823 8.10403L4.73363 8.47218L9.08363 10.2409L9.31411 10.3346L9.49004 10.1587L12.2436 7.40513L12.5945 7.75604L9.86596 10.4846L9.69135 10.6592L9.78278 10.8886L11.5265 15.2636Z"
                            fill="#374047"
                            stroke="white"
                            strokeWidth="0.75"
                        />
                      </svg>
                    </button>
                  </div>
              );
            }else{
              return (
                <div>
                  <button
                    className={`button white saveCorrectionButton me-2 ${
                      currentSlidePosition === globalState.slides.length ||
                      globalState.devMode
                        ? "visible"
                        : "hidden"
                    }`}
                    onClick={closeCorrection}
                  >
                    <span>{t("save")}</span>
                  </button>
                  <button
                    className={`button sendActivityButton ${
                      currentSlidePosition === globalState.slides.length ||
                      globalState.devMode
                        ? "visible"
                        : "hidden"
                    }`}
                    onClick={sendCorrection}
                  >
                    <span>{t("send")}</span>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.7905 2.88972L16.7916 2.88933C16.8363 2.87293 16.8847 2.86963 16.9312 2.87981C16.9762 2.88966 17.0175 2.91171 17.0507 2.9435C17.0816 2.97719 17.1028 3.01875 17.1118 3.06367C17.1211 3.11014 17.117 3.1583 17.0999 3.20254L17.0999 3.20251L17.0975 3.20914L12.0975 16.9591L12.0972 16.9599C12.08 17.0074 12.0489 17.0486 12.0078 17.0781C11.9693 17.1057 11.9237 17.1218 11.8765 17.1245C11.831 17.1215 11.7872 17.1061 11.7498 17.0799C11.7098 17.0519 11.6789 17.0128 11.6608 16.9675L11.6606 16.9668L9.26056 10.9668L9.20087 10.8176L9.05165 10.7579L3.05165 8.35787L3.05167 8.35781L3.04537 8.35542C2.99794 8.33743 2.9571 8.30543 2.92828 8.26368C2.89947 8.22193 2.88403 8.1724 2.88403 8.12167C2.88403 8.07094 2.89947 8.02141 2.92828 7.97966C2.95672 7.93846 2.99686 7.90676 3.0435 7.88864L16.7905 2.88972ZM11.5265 15.2636L11.8922 16.181L12.2276 15.2522L16.1838 4.29591L16.4552 3.54451L15.7038 3.81584L4.74752 7.77209L3.82823 8.10403L4.73363 8.47218L9.08363 10.2409L9.31411 10.3346L9.49004 10.1587L12.2436 7.40513L12.5945 7.75604L9.86596 10.4846L9.69135 10.6592L9.78278 10.8886L11.5265 15.2636Z"
                        fill="#374047"
                        stroke="white"
                        strokeWidth="0.75"
                      />
                    </svg>
                  </button>
                </div>
              );
            }
          case "reviewed":
            if (globalState.role === "teacher") {
              return (
                <div>
                  <button
                    className={`button white me-2`}
                    onClick={editCorrection}
                  >
                    <span>{t("edit.correction")}</span>
                  </button>
                </div>
              );
            }
          default:
            return <div></div>;
        }
      })()}
    </div>
  );
};

const NavBarLeft = (props) => {
  const { globalState, mode, currentSlidePosition } = props;

  return (
    <div className={`leftButtons`}>
      {(() => {
        switch (mode) {
          case "blocked":
          case "solve_first_time":
          case "solve_continue":
          case "in_review_first_time":
          case "in_review_continue":
            return (
              <div>
                <button
                  className={`resetExerciseButton ${
                    globalState.devMode ? "visible" : "hidden"
                  }`}
                  onClick={globalState.functions.resetExercise}
                >
                  <span>{t("reset.activity")}</span>
                </button>
              </div>
            );
          default:
            return <div></div>;
        }
      })()}
    </div>
  );
};
