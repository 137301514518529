import { t } from "i18next";
import React, { useEffect, useState, useRef } from "react";
import {
  getApartados,
  getContentBlockToShow
} from "../../ExerciseBlocks";
import {ReactSketchCanvas} from "react-sketch-canvas";
import {ArrowClockwise, ArrowCounterclockwise} from "react-bootstrap-icons";

export const DigitalExerciseBlock8 = React.memo((props) => {
  const { assets, exerciseInfo, globalState, exercises } = props;

  const [showAlert, setShowAlert] = useState(false);

  let contentJson =
    exerciseInfo.exerciseBlock.digitalContent?.json ??
    exerciseInfo.exerciseBlock.content?.json;

  function getCanvasBackgroundImage() {
    let imageUrl = ''
    getApartados(contentJson).map((apartado, index) => {
      apartado.map((contentBlock, indexb) => {
        if(contentBlock.nodeType == "embedded-asset-block"){
          imageUrl = contentBlock.data?.target?.fields?.file?.url;

          if(!imageUrl && assets != null){
            let asset = assets.filter(a => a.sys.id == contentBlock.data.target.sys.id)[0];

            if(asset)
              imageUrl = asset.url;
          }
        }
      })
    })
    return imageUrl
  }

  useEffect(() => {}, [exercises]);

  let initialAnswer = getAnswers();

  const [answers, setAnswers] = useState(initialAnswer);

  const canvas = useRef(null);
  let canvasBackgroundImage = getCanvasBackgroundImage()
  let canvasIsEditable =
      (globalState.role === "student" && (globalState.mode === "solve_first_time" || globalState.mode === "solve_continue"));
  //    || (globalState.role === "teacher" && globalState.mode === "in_review");

  useEffect(() => {
    loadPaths()
  }, []);

  useEffect(() => {
    loadPaths()
  }, [exerciseInfo]);

  useEffect(() => {
    refreshResults();
  }, [answers]);

  function loadPaths(){
    initialAnswer = getAnswers();
    if(initialAnswer){
      canvas.current.clearCanvas()
      canvas.current.loadPaths(initialAnswer)
      refreshAnswers(initialAnswer)
    }
  }

  function getAnswers() {
    let answers = "";
    if (exerciseInfo.results !== "" && exerciseInfo.results.answers !== "") {
      try{
        answers = JSON.parse(exerciseInfo.results.answers);
      }catch (e){ }
    }
    return answers;
  }

  function refreshResults() {
    try {
      let results = {
        ...exerciseInfo.results,
        correctAnswer: ``,
        answers: `${answers}`,
        level: 0,
        score: 0,
        correct: true,
        answered: isAnswered(answers),
      };

      const updatedExercises = exercises.map((exercise) => {
        if (exercise.slidePosition === exerciseInfo.slidePosition) {
          return { ...exercise, results: results };
        }

        return exercise;
      });

      globalState.setExercises(updatedExercises);
    } catch (e) {
      console.log("DigitalExerciseBlock8", e);
      setShowAlert(true);
    }
  }

  function isAnswered(userAnswers) {
    return userAnswers !== "";
  }

  function refreshAnswers(data) {
    let answersData = JSON.stringify(data)
    setAnswers(answersData);
  }

  function undo(e){
    e.preventDefault()
    canvas.current.undo()
  }

  function redo(e){
    e.preventDefault()
    canvas.current.redo()
  }

  function resetCanvas(e){
    e.preventDefault()
    canvas.current.resetCanvas()
  }

  function clearCanvas(e){
    e.preventDefault()
    canvas.current.clearCanvas()
  }

  let showAsAnswered =
    (globalState.role === "student" && globalState.mode === "reviewed") ||
      (globalState.role === "teacher") || globalState.devMode;

  return (
    <section
      className="digitalExerciseBlock digitalExerciseBlock8"
      id={`digitalExercise${exerciseInfo.exerciseBlock.sys.id}`}>
      {showAlert ? (
        <div className="alert alert-danger" role="alert">
          Este ejercicio tiene errores en los datos.
          <br />
          ExerciseBlock idName: {exerciseInfo.exerciseBlock.idName}
        </div>
      ) : (
        <></>
      )}
      <table>
        <tbody>
          <tr>
            <td>
              <div className={`reactSketchCanvasContainer ${canvasIsEditable ? "" : "disabled" }`}>
                <div className="toolbar mb-3">
                  <button className={`btn me-2`} onClick={undo}><ArrowCounterclockwise /></button>
                  <button className={`btn me-2`} onClick={redo}><ArrowClockwise /></button>
                  <button className={`btn`} onClick={clearCanvas}>{t("canvas.clear")}</button>
                </div>
                <ReactSketchCanvas
                    id={`canvas${exerciseInfo.exerciseBlock.sys.id}`}
                    ref={canvas}
                    className={`reactSketchCanvas`}
                    width="100%"
                    height="100%"
                    strokeWidth={2}
                    strokeColor={ "black" }
                    backgroundImage={canvasBackgroundImage != '' ? canvasBackgroundImage : null}
                    preserveBackgroundImageAspectRatio={`${190/97}`}
                    onChange={(canvasPath) => {
                      refreshAnswers(canvasPath);
                    }}
                />
              </div>
            </td>
          </tr>

          {showAsAnswered && exerciseInfo.exerciseBlock.openAnswer ? (
            <tr>
              <td>
                <div className="openAnswer">
                  <div className="cabecera">
                    <svg
                      width="18"
                      height="20"
                      viewBox="0 0 18 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.012 16H18V2C18 1.46957 17.7893 0.960859 17.4142 0.585786C17.0391 0.210714 16.5304 0 16 0H3C1.794 0 0 0.799 0 3V17C0 19.201 1.794 20 3 20H18V18H3.012C2.55 17.988 2 17.805 2 17C2 16.195 2.55 16.012 3.012 16ZM5 4H14V6H5V4Z"
                        fill="#0D70B6"
                      />
                    </svg>
                    <span>{t("solucionario")}</span>
                  </div>
                  <div className="row">
                    {getApartados(
                      exerciseInfo.exerciseBlock.openAnswer.json
                    ).map((apartado, index) => {
                      return (
                        <div
                          className="col-12"
                          key={`openAnswer${exerciseInfo.slidePosition}-${index}`}
                        >
                          {apartado.map((contentBlock, indexb) => {
                            return (
                              <div
                                className="contentBlock"
                                key={`openAnswer${exerciseInfo.slidePosition}-${index}-${indexb}`}
                              >
                                {getContentBlockToShow(
                                  contentBlock,
                                  false,
                                  `slideNote${exerciseInfo.slidePosition}-${index}-${indexb}`,
                                  exerciseInfo.exerciseBlock.openAnswer.links
                                    ?.assets?.block
                                )}
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </td>
            </tr>
          ) : (
            <></>
          )}
        </tbody>
      </table>
    </section>
  );
});
