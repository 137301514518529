import { gql } from "@apollo/client";

export class ExerciseBlockGraphql {

  static getExerciseGraphQLCall(exerciseBlockId, language) {
    return gql`
            query getExerciseBlock{
              exerciseBlock(id: "${exerciseBlockId}", locale:"${language}"){
                sys{
                  id
                }
                idName
                digital
                digitalTemplate
                linkedFrom{
                  lessonsCollection(limit:3){
                    items{
                      sys{
                        id
                      }
                      idName
                    }
                  }
                }
                digitalStatement{
                  json
                  links{
                    assets{
                      block{
                        url
                        sys{
                          id
                        }
                      }
                    }
                  }
                }
                digitalContent{
                  json
                  links{
                    assets{
                      block{
                        url
                        sys{
                          id
                        }
                      }
                    }
                  }
                }
                answers
                answersFormat
                multipleTextFormat
                template
                content {
                  json
                  links{
                    assets{
                      block{
                        url
                        sys{
                          id
                        }
                      }
                    }
                  }
                }
                statement{
                  json
                  links{
                    assets{
                      block{
                        url
                        sys{
                          id
                        }
                      }
                    }
                  }
                }
                review{
                  json
                  links{
                    assets{
                      block{
                        url
                        sys{
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
        `;
  }
}
