import React, {useEffect, useState} from "react";
import {getApartados, getDigitalContentBlockToShow,} from "../../ExerciseBlocks";
import {ReactComponent as IconCheck} from '../../../images/svg/Icon-Check.svg';
import $ from "jquery";

export const DigitalExerciseBlock2 = (props) => {
  const { assets, exerciseInfo, globalState, exercises } = props;

  const [showAlert, setShowAlert] = useState(false);

  let contentJson =
    exerciseInfo.exerciseBlock.digitalContent?.json ??
    exerciseInfo.exerciseBlock.content?.json;

  const [answers, setAnswers] = useState(getAnswers());

  useEffect(() => {
    refreshResults();
  }, [answers]);

  let correctAnswers = getCorrectAnswersAsArray();

  useEffect(() => {
    refreshAnswers();
  }, [exerciseInfo]);

  function getAnswers() {
    let answers = [];
    if (exerciseInfo.results != "") {
      answers = exerciseInfo.results.answers
        .replaceAll("[", "")
        .replaceAll("]", "")
        .replaceAll("(", "")
        .replaceAll(")", "")
        .split("|");
    }
    return answers;
  }

  function getCorrectAnswersAsArray() {
    return exerciseInfo.answers
        .flat()
        .filter((a) => a.includes("("))
        .map((a) => a.replace(/[()]/g, ""));
  }

  function refreshResults() {
    try {

      let results = {
        ...exerciseInfo.results,
        correctAnswer: `[[${getCorrectAnswers(exerciseInfo.answers).join(
          "|"
        )}]]`,
        answers: `[[${answers.join("|")}]]`,
        level: calculateLevel(exerciseInfo.answers[0], answers),
        score: calculateScore(correctAnswers, answers),
        correct: isCorrect(correctAnswers, answers),
        answered: isAnswered(correctAnswers, answers),
      };

      const updatedExercises = exercises.map((exercise) => {
        if (exercise.slidePosition == exerciseInfo.slidePosition) {
          return { ...exercise, results: results };
        }

        return exercise;
      });

      globalState.setExercises(updatedExercises);
    } catch (e) {
      setShowAlert(true);
    }
  }

  function calculateLevel(correctAnswers, userAnswers) {
    let score = calculateScore(correctAnswers, userAnswers);
    if (score <= 4) return 1;
    else if (score <= 6) return 2;
    else if (score <= 8) return 3;
    else return 4;
  }

  function calculateScore(correctAnswers, userAnswers) {
    if (userAnswers.length == 0) return 0;

    let numCorrectAnswers = correctAnswers
      .map(function (value, index) {
        if (typeof value == "number" || typeof userAnswers[index] == "number") {
          return Number(value) == Number(userAnswers[index]);
        }
        return value == userAnswers[index];
      })
      .filter((v) => v).length;

    return (10 * Number(numCorrectAnswers)) / userAnswers.length;
  }

  function isCorrect(correctAnswers, userAnswers) {
    return (
      correctAnswers.length == userAnswers.length &&
      correctAnswers.every(function (value, index) {
        return value == userAnswers[index];
      })
    );
  }

  function isAnswered(correctAnswers, userAnswers) {
    return (
      correctAnswers.length == userAnswers.filter((a) => a != "empty").length
    );
  }

  function getCorrectAnswers(answers) {
    let correctAnswer = [];

    for (let row of answers) {
      for (let answer of row) {
        if (answer.includes("(")) {
          correctAnswer.push(answer.replace("(", "").replace(")", ""));
        }
      }
    }

    return correctAnswer;
  }

  function refreshAnswers() {
    let currentAnswers = [];

    $(`#digitalExercise${exerciseInfo.exerciseBlock.sys.id} .apartado`).each(
      function (index) {
        currentAnswers.push("empty");
        $(
          `#digitalExercise${exerciseInfo.exerciseBlock.sys.id} input[name="radioGroup${index}"]`
        ).each(function () {
          if ($(this)[0].checked) {
            currentAnswers[index] = $(this).val();
          }
        });
      }
    );

    setAnswers(currentAnswers);
  }

  return (
    <section className="digitalExerciseBlock digitalExerciseBlock2"
      id={`digitalExercise${exerciseInfo.exerciseBlock.sys.id}`}>
      {showAlert ? (
        <div className="alert alert-danger" role="alert">
          Este ejercicio tiene errores en los datos.
          <br />
          ExerciseBlock idName: {exerciseInfo.exerciseBlock.idName}
          {console.log("ERROR en EB=" + exerciseInfo.exerciseBlock.idName)}
        </div>
      ) : (
        <></>
      )}
      <table>
        <tbody>
          {getApartados(contentJson).map((apartado, index) => {
            return (
              <tr className="apartado"
                key={`${exerciseInfo.exerciseBlock.sys.id}-${index}`}>
                <td className="enunciado">
                  <div className="wrapper">
                    {apartado.map((contentBlock, indexb) => {
                      return (
                          <div className="contentBlock"
                              key={`contentBlock${exerciseInfo.exerciseBlock.sys.id}-${index}-${indexb}`}>
                            {getDigitalContentBlockToShow(
                                contentBlock,
                                `${exerciseInfo.exerciseBlock.sys.id}-${index}-${indexb}`,
                                assets
                            )}
                          </div>
                      );
                    })}
                  </div>
                </td>
                <td className="opciones">
                  <div className="wrapper">
                    {exerciseInfo.answers[index]?.map((answer, indexAnswer) => {
                      answer = answer.replaceAll("(", "").replaceAll(")", "");

                      let isCorrect = "";

                      if (exerciseInfo.results.correct) {
                        isCorrect = answer == answers[index] ? "correct" : "";
                      } else {
                        if (answer == answers[index]) {
                          isCorrect = answer == correctAnswers[index] ? "correct" : "wrong";
                        } else {
                          if (answer == correctAnswers[index]) {
                            isCorrect = "correct";
                          }
                        }
                      }
                      return (
                          <div
                              key={`td${exerciseInfo.exerciseBlock.sys.id}-${index}-${indexAnswer}`}
                              className="radioElement"
                          >
                            {(() => {
                              switch (globalState.mode) {
                                case "solve_first_time":
                                case "solve_retry":
                                  return (
                                      <input
                                          type="radio"
                                          id={`radioButton${index}_${indexAnswer}`}
                                          name={`radioGroup${index}`}
                                          onChange={refreshAnswers}
                                          className={`me-2`}
                                          value={answer}
                                      />
                                  );
                                case "solve_continue":
                                  return (
                                      <input
                                          type="radio"
                                          id={`radioButton${index}_${indexAnswer}`}
                                          name={`radioGroup${index}`}
                                          onChange={refreshAnswers}
                                          className={`me-2`}
                                          value={answer}
                                          checked={answer == answers[index]}
                                      />
                                  );
                                case "in_review_first_time":
                                case "in_review_continue":
                                case "display_with_data":
                                case "reviewed":
                                  return (
                                      <input
                                          type="radio"
                                          id={`radioButton${index}_${indexAnswer}`}
                                          name={`radioGroup${index}`}
                                          className={`me-2 ${isCorrect}`}
                                          disabled={true}
                                          checked={answer == answers[index]}
                                          value={answer}
                                      />
                                  );
                                case "display_without_data":
                                  return (
                                      <input
                                          type="radio"
                                          id={`radioButton${index}_${indexAnswer}`}
                                          name={`radioGroup${index}`}
                                          className={`me-2`}
                                          disabled={true}
                                      />
                                  );
                                default:
                                  return <></>;
                              }
                            })()}

                            <label htmlFor={`radioButton${index}_${indexAnswer}`}
                                className={`ms-2 ${isCorrect}`}>
                              {answer}

                              {(globalState.devMode || globalState.teacherMode) &&
                                  answer == correctAnswers[index] ? (
                                  <div className="ms-1"><IconCheck /></div>
                              ) : (
                                  ""
                              )}
                            </label>
                          </div>
                      );
                    })}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </section>
  );
};
