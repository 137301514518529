import {evaluate, simplify} from "mathjs";

function validateNaturalNumber(userAns, correctAns) {
  userAns = userAns.toString().replaceAll(",", ".");
  correctAns = correctAns.toString().replaceAll(",", ".");

  return userAns === correctAns || Number(userAns) === Number(correctAns);
}

function validateDecimalNumber(userAns, correctAns) {
  userAns = userAns.toString().replaceAll(",", ".");
  correctAns = correctAns.toString().replaceAll(",", ".");

  return userAns === correctAns || validateNaturalNumber(userAns, correctAns);
}

function validateFractionNumber(userAns, correctAns) {
  userAns = userAns.toString().replaceAll(":", "/");
  correctAns = correctAns.toString().replaceAll(":", "/");

  let userAnsArray = userAns.split("/")
  let correctAnsArray = correctAns.split("/")

  let arraysAreEqual = userAnsArray.length === correctAnsArray.length
      && userAnsArray.every((userAnsItem, index) => validateDecimalNumber(userAnsItem, correctAnsArray[index]));

  return userAns === correctAns || arraysAreEqual;
}

function validateCoordinates(userAns, correctAns) {
  userAns = userAns.toString().replaceAll(/\s+/g, "");
  correctAns = correctAns.toString().replaceAll(/\s+/g, "");

  let userAnsArray = userAns.replaceAll(/[()]/g, "").split(",")
  let correctAnsArray = correctAns.replaceAll(/[()]/g, "").split(",")

  let arraysAreEqual = userAnsArray.length === correctAnsArray.length
      && userAnsArray.every((userAnsItem, index) => validateDecimalNumber(userAnsItem, correctAnsArray[index]));

  return userAns === correctAns || (userAns.includes('(') && userAns.includes(')') && arraysAreEqual);
}

function validateExpressions(userAns, correctAns) {
  const evaluateUserAns = userAns.toString().toLowerCase()
    // .replaceAll(new RegExp("[a-z]", "g"), "");

  const evaluateCorrectAns = correctAns.toString().toLowerCase()
    // .replaceAll(new RegExp("[a-z]", "g"), "");

  let scope = {x:2.34, y:2.78}

  try {
    return evaluate(evaluateUserAns, scope) === evaluate(evaluateCorrectAns, scope);
  } catch (error) {
    try {
      if (
        evaluate(evaluateUserAns.replace(/.$/, ""), scope) ===
        evaluate(evaluateCorrectAns, scope)
      ) {
        return true;
      }
    } catch (error) {
      return false;
    }

    return false;
  }
}

function validateRangeNumbers(userAns, correctAns) {
  userAns = userAns.toString().replaceAll(",", ".");
  correctAns = correctAns.toString().replaceAll(",", ".");

  let userAnsNumber = Number(userAns)
  let correctAnsArray = correctAns.split("+/-")

  let lowLimit = .1;
  let highLimit = .1;
  if(correctAnsArray.length == 2){
    lowLimit = Number(correctAnsArray[0]) - Number(correctAnsArray[1])
    highLimit = Number(correctAnsArray[0]) + Number(correctAnsArray[1])
  }

  return userAnsNumber >= lowLimit && userAnsNumber <= highLimit;
}

function validateText(userAns, correctAns) {
  userAns = userAns.toLowerCase();
  correctAns = correctAns.toLowerCase();

  userAns = userAns.replace(new RegExp("[í]", "g"), "i");
  correctAns = correctAns.replace(new RegExp("[í]", "g"), "i");

  userAns = removeAccents(userAns);
  correctAns = removeAccents(correctAns);

  userAns = removeLongDash(userAns);
  correctAns = removeLongDash(correctAns);

  return userAns === correctAns;
}

export const validateAnswersFormat = (userAns, correctAns, answerFormat = []) => {
  let validateArr = [];

  if (!answerFormat.length || !userAns || !correctAns) return false;

  userAns = userAns.toString().replaceAll(" ", "");
  correctAns = correctAns.toString().replaceAll(" ", "");

  answerFormat.forEach((ansFormat) => {
    switch (ansFormat) {
      case "Natural numbers":
      case "Integer":
      case "N":
      case "I":
        validateArr.push(validateNaturalNumber(userAns, correctAns));
        break;
      case "Decimal numbers":
      case "Decimals number":
      case "D":
        validateArr.push(validateDecimalNumber(userAns, correctAns));
        break;
      case "Fractions and ratios":
      case "F":
        validateArr.push(validateFractionNumber(userAns, correctAns));
        break;
      case "Coordinates":
      case "C":
        validateArr.push(validateCoordinates(userAns, correctAns));
        break;
      case "Algebraic expressions":
      case "A":
        validateArr.push(validateExpressions(userAns, correctAns));
        break;
      case "Text":
      case "T":
        validateArr.push(validateText(userAns, correctAns));
        break;
      case "Range numbers":
      case "R":
        validateArr.push(validateRangeNumbers(userAns, correctAns));
        break;
      case "Multiple":
      case "M":
        validateArr.push(false);
        break;
      default:
        validateArr.push(false);
    }
  });

  return validateArr.includes(true);
};

function removeAccents(str) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

function removeLongDash(str) {
  return str.replace('−', "-");
}

