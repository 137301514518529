import React, { useEffect, useState } from "react";
import {
  getApartados,
  getDigitalContentBlockToShow,
} from "../../ExerciseBlocks";
import $ from "jquery";
import {ReactComponent as IconCheck} from '../../../images/svg/Icon-Check.svg';
import {ReactComponent as IconCorrect} from '../../../images/svg/Icon-Correct.svg';
import {ReactComponent as IconWrong} from '../../../images/svg/Icon-Wrong.svg';

export const DigitalExerciseBlock6 = (props) => {
  const { assets, exerciseInfo, globalState, exercises } = props;

  const [showAlert, setShowAlert] = useState(false);

  let contentJson =
    exerciseInfo.exerciseBlock.digitalContent?.json ??
    exerciseInfo.exerciseBlock.content?.json;

  useEffect(() => {
    refreshAnswers();
  }, [exerciseInfo]);

  const [answers, setAnswers] = useState(getAnswers());

  useEffect(() => {
    refreshResults();
  }, [answers]);

  function getAnswers() {
    let answers = [];
    if (exerciseInfo.results != "") {
      answers = exerciseInfo.results.answers
        .replaceAll("[", "")
        .replaceAll("]", "")
        .replaceAll("empty", "")
        .split("|")
        .filter(a => a);
    }
    return answers;
  }

  function refreshResults() {
    try {
      let results = {
        ...exerciseInfo.results,
        correctAnswer: `[[${exerciseInfo.answers[0].join("|")}]]`,
        answers: `[[${answers.join("|")}]]`,
        level: calculateLevel(exerciseInfo.answers[0], answers),
        score: calculateScore(exerciseInfo.answers[0], answers),
        correct: isCorrect(exerciseInfo.answers[0], answers),
        answered: isAnswered(exerciseInfo.answers[0], answers),
      };

      const updatedExercises = exercises.map((exercise) => {
        if (exercise.slidePosition == exerciseInfo.slidePosition) {
          return { ...exercise, results: results };
        }

        return exercise;
      });

      globalState.setExercises(updatedExercises);
    } catch (e) {
      console.log(e);
      setShowAlert(true);
    }
  }

  function calculateLevel(correctAnswers, userAnswers) {
    let score = calculateScore(correctAnswers, userAnswers);
    if (score <= 4) return 1;
    else if (score <= 6) return 2;
    else if (score <= 8) return 3;
    else return 4;
  }

  function calculateScore(correctAnswers, userAnswers) {
    const eachAnswerScore = 10 / correctAnswers.length;
    let totalScoreCounter = 0;

    correctAnswers.map((ans) => {
      if (
        userAnswers.includes(Number(ans)) ||
        userAnswers.includes((Number(ans)).toString())
      ) {
        totalScoreCounter += eachAnswerScore;
      }
    });

    userAnswers.map((ans) => {
      if (
        !correctAnswers.includes(Number(ans)) &&
        !correctAnswers.includes((Number(ans)).toString())
      ) {
        totalScoreCounter -= eachAnswerScore;
      }
    });

    if (totalScoreCounter < 0) totalScoreCounter = 0;

    return totalScoreCounter;
  }

  function isCorrect(correctAnswers, userAnswers) {
    return (
      correctAnswers.length == userAnswers.length &&
      correctAnswers.every(function (value, index) {
        return value == userAnswers[index];
      })
    );
  }

  function isAnswered(correctAnswers, userAnswers) {
    return userAnswers.length > 0;
  }

  function refreshAnswers() {
    let currentAnswers = [];

    $(
      `#digitalExercise${exerciseInfo.exerciseBlock.sys.id} input[type="checkbox"]`
    ).each(function (index) {
      if ($(this)[0].checked) {
        currentAnswers.push(index + 1);
      }
    });

    if (!currentAnswers.length && exerciseInfo.results.answers.length) {
      setAnswers(
        exerciseInfo.results.answers
          .replaceAll("[", "")
          .replaceAll("]", "")
          .replaceAll("empty", "")
          .split("|")
          .filter(a => a)
      );
    } else {
      setAnswers(currentAnswers.filter(a => a));
    }
  }

  return (
    <section
      className="digitalExerciseBlock digitalExerciseBlock6"
      id={`digitalExercise${exerciseInfo.exerciseBlock.sys.id}`}
    >
      {showAlert ? (
        <div className="alert alert-danger" role="alert">
          Este ejercicio tiene errores en los datos.
          <br />
          ExerciseBlock idName: {exerciseInfo.exerciseBlock.idName}
        </div>
      ) : null}
      <table>
        <tbody>
          {getApartados(contentJson).map((apartado, index) => {
            let index1 = index + 1;
            return (
              <tr
                className="apartado"
                key={`${exerciseInfo.exerciseBlock.sys.id}-${index1}`}
              >
                <td>
                  {(() => {
                    let isChecked = answers?.includes(index1.toString()) || answers?.includes(index1)
                    switch (globalState.mode) {
                      case "solve_first_time":
                      case "solve_retry":
                        return (
                          <input
                            type="checkbox"
                            id={`checkbox${index1}`}
                            name={`checkbox${exerciseInfo.exerciseBlock.sys.id}`}
                            onChange={refreshAnswers}
                            className={`me-2`}
                            checked={isChecked}
                          />
                        );
                      case "solve_continue":
                        return (
                          <input
                            type="checkbox"
                            id={`checkbox${index1}`}
                            name={`checkbox${exerciseInfo.exerciseBlock.sys.id}`}
                            onChange={refreshAnswers}
                            className={`me-2`}
                            checked={isChecked}
                          />
                        );
                      case "in_review_first_time":
                      case "in_review_continue":
                      case "display_with_data":
                      case "reviewed":
                        return answers.includes(index1.toString()) ||
                          answers.includes(index1) ? (
                          <input
                            type="checkbox"
                            id={`checkbox${index1}`}
                            name={`checkbox${exerciseInfo.exerciseBlock.sys.id}`}
                            onChange={refreshAnswers}
                            className={`me-2`}
                            disabled
                            checked
                          />
                        ) : (
                          <input
                            type="checkbox"
                            id={`checkbox${index1}`}
                            name={`checkbox${exerciseInfo.exerciseBlock.sys.id}`}
                            onChange={refreshAnswers}
                            className={`me-2`}
                            disabled
                          />
                        );
                      case "display_without_data":
                        return (
                          <input
                            type="checkbox"
                            id={`checkbox${index1}`}
                            name={`checkbox${exerciseInfo.exerciseBlock.sys.id}`}
                            onChange={refreshAnswers}
                            className={`me-2`}
                            readOnly={true}
                          />
                        );
                      default:
                        <></>;
                    }
                  })()}
                  <label htmlFor={`checkbox${index1}`} className={`ms-2`}>
                    {apartado.map((contentBlock, indexb) => {
                      return (
                        <div
                          className="contentBlock"
                          key={`${exerciseInfo.exerciseBlock.sys.id}-${index1}-${indexb}`}
                        >
                          {getDigitalContentBlockToShow(
                            contentBlock,
                            `${exerciseInfo.exerciseBlock.sys.id}-${index1}-${indexb}`,
                            assets
                          )}
                        </div>
                      );
                    })}
                  </label>
                  {(() => {
                    switch (globalState.mode) {
                      case "in_review_first_time":
                      case "in_review_continue":
                      case "display_with_data":
                      case "reviewed":
                        if ((answers.includes(index1.toString()) ||
                                answers.includes(index1)) &&
                            exerciseInfo.results.correctAnswer
                                .replaceAll("[", "")
                                .replaceAll("]", "")
                                .replaceAll("empty", "")
                                .includes(index1)
                        ) {
                            return (
                                <div className="ms-1"><IconCorrect /></div>
                            );
                        }
                        if (
                          (answers.includes(index1.toString()) ||
                            answers.includes(index1)) &&
                          !exerciseInfo.results.correctAnswer
                            .replaceAll("[", "")
                            .replaceAll("]", "")
                            .replaceAll("empty", "")
                            .includes(index1)
                        ) {
                          return (
                              <div className="ms-1 mt-1"><IconWrong /></div>
                          );
                        }
                        if (
                          (!answers.includes(index1.toString()) ||
                            !answers.includes(index1)) &&
                          exerciseInfo.results.correctAnswer
                            .replaceAll("[", "")
                            .replaceAll("]", "")
                            .replaceAll("empty", "")
                            .includes(index1)
                        ) {
                          return (
                              <div className="ms-1"><IconCheck /></div>
                          );
                        }
                        break;
                      default:
                        if ((globalState.devMode || globalState.teacherMode) && (exerciseInfo.answers[0].includes(index1.toString()) || exerciseInfo.answers[0].includes(index1)))
                        {
                          return (
                              <div className="ms-1"><IconCheck /></div>
                          );
                        }else{
                          return (
                            <></>
                          );
                        }
                    }
                  })()}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </section>
  );
};
