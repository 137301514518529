import { gql } from "@apollo/client";
import { Client_GraphQL } from "../../Client_GraphQL";

export class LessonExerciseGraphql {
  static getOrCreateGraphQLClient() {
    if (this.graphQLClient == null) this.graphQLClient = Client_GraphQL();
    return this.graphQLClient;
  }

  static getGradeGraphQLCall(lessonId, unitId, language) {
    return gql`
            query getLesson{
              lessons(id: "${lessonId}", locale:"${language}"){
                idName
                name
                teachingBlocksCollection{
                  items{
                    sys{
                      id
                    }
                    exerciseBlocksCollection{
                      items{
                        sys{
                          id
                        }
                        digital
                        digitalTemplate
                        digitalContent{
                          json
                        }
                        review {
                          json
                        }
                      }
                    }
                    rubricsCollection(limit:2){
                      items{
                        sys{
                          id
                        }
                        idName
                        evaluationItem{
                          idName
                          evaluationItemText
                          competence{
                        linkedFrom(allowedLocales: ["es-ES", "ca", "en-CA"]) {
                          dimensionsCollection(limit:1) {
                            items {
                              name
                            }
                          }
                        }
                        sys{
                          id
                        }
                        name
                        alias
                      }
                        }
                        level1Description{
                          json
                        }
                        level2Description{
                          json
                        }
                        level3Description{
                          json
                        }
                        level4Description{
                          json
                        }
                      }
                    }
                  }
                }
              }
              units(id: "${unitId}", locale:"${language}"){
                name
                idName
              }
            }
        `;
  }

  static getExerciseGraphQLCall(exerciseBlockId, language) {
    return gql`
            query getExerciseBlock{
              exerciseBlock(id: "${exerciseBlockId}", locale:"${language}"){
                sys{
                  id
                }
                idName
                digital
                digitalTemplate
                digitalStatement{
                  json
                  links{
                    assets{
                      block{
                        url
                        sys{
                          id
                        }
                      }
                    }
                  }
                }
                digitalContent{
                  json
                  links{
                    assets{
                      block{
                        url
                        sys{
                          id
                        }
                      }
                    }
                  }
                }
                answers
                answersFormat
                multipleTextFormat
                template
                content {
                  json
                  links{
                    assets{
                      block{
                        url
                        sys{
                          id
                        }
                      }
                    }
                  }
                }
                statement{
                  json
                  links{
                    assets{
                      block{
                        url
                        sys{
                          id
                        }
                      }
                    }
                  }
                }
                review{
                  json
                  links{
                    assets{
                      block{
                        url
                        sys{
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
        `;
  }
}
