import { LessonActivityFunctions } from "./LessonActivity.functions";

export class LessonActivityApi {
  static async doLogin(globalState) {
    console.log("doLogin");

    let userData = {};

    if (process.env.REACT_APP_SITE_URL === "https://lms.jumpmath.es/") {
      globalState.grade_id === process.env.REACT_APP_CONTENTFUL_COURSE1
        ? (userData = {
            username: process.env.REACT_APP_CONTENTFUL_COURSE1_USER,
            password: process.env.REACT_APP_CONTENTFUL_COURSE1_PWD,
          })
        : (userData = {
            username: process.env.REACT_APP_CONTENTFUL_COURSE2_USER,
            password: process.env.REACT_APP_CONTENTFUL_COURSE2_PWD,
          });
    } else {
      globalState.grade_id === process.env.REACT_APP_CONTENTFUL_COURSE1
        ? (userData = {
            username: process.env.REACT_APP_CONTENTFUL_COURSE1_TEST_USER,
            password: process.env.REACT_APP_CONTENTFUL_COURSE1_TEST_PWD,
          })
        : (userData = {
            username: process.env.REACT_APP_CONTENTFUL_COURSE2_TEST_USER,
            password: process.env.REACT_APP_CONTENTFUL_COURSE2_TEST_PWD,
          });
    }

    let response = await fetch(
      `${process.env.REACT_APP_SITE_URL}api/v1/login`,
      {
        method: "post",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(userData),
      }
    );

    if (response) {
      let content = await response.json();

      if (content && content.data) {
        globalState.setStudentId(content.data.uuid);
        globalState.setAuthToken(content.data.auth_token);
      }
    }
  }

  static async getInitInfo(globalState, authToken) {
    if (authToken === "") {
      let mode = LessonActivityFunctions.getMode(globalState, null);
      globalState.setMode(mode);
      return;
    }

    let urlToFetch = `${process.env.REACT_APP_SITE_URL}api/v1/get-init-info/${globalState.lessonType}/${globalState.id}`;

    urlToFetch += `/${globalState.studentId}`;

    // console.log("getInitInfo token:", authToken, ", exercise id: ", globalState.id, ", student id:", globalState.studentId)
    console.log("getInitInfo url:", urlToFetch);

    let response = await fetch(urlToFetch, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + authToken,
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    });

    if (response) {
      console.log("getInitInfo response:", response.status);

      switch (response.status) {
        case 401:
          globalState.setPopup("authenticationError");
          break;
        case 404:
          globalState.setPopup("notFoundError");
          break;
        case 200:
          let content = await response.json();

          console.log("getInitInfo content:", content.data);

          if (content && content.data) {
            if (content.data.params) {
              globalState.setDeadLine(new Date(content.data.params.deadline));
            }

            let apiInfo = content.data;

            globalState.setApiInfo(apiInfo);

            let mode = LessonActivityFunctions.getMode(globalState, apiInfo);

            if (mode === "solve_continue" || mode === "display_with_data") {
              LessonActivityFunctions.reprocessExercises(globalState, apiInfo);
            }

            if (mode.includes("in_review")) {
              LessonActivityFunctions.reprocessCorrections(
                globalState,
                apiInfo
              );
            }

            globalState.setMode(mode);
          }
          break;
      }
    }
  }

  static async saveExercise(globalState) {
    if (globalState.authToken == "") return;

    let urlToFetch = `${process.env.REACT_APP_SITE_URL}api/v1/tasks/${globalState.lessonType}/${globalState.id}/${globalState.studentId}/save`;

    console.log("saveExercise url:", urlToFetch);

    let json = this.getJson(globalState);

    let response = await fetch(urlToFetch, {
      method: "POST",
      headers: new Headers({
        Authorization: "Bearer " + globalState.authToken,
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(json),
    });

    if (response) {
      let content = await response.json();

      if (content.error) {
      }

      if (content.success) {
        // console.log(content)
      }
    }
  }

  static async sendActivity(globalState) {
    if (globalState.authToken == "") return;

    let urlToFetch = `${process.env.REACT_APP_SITE_URL}api/v1/tasks/${globalState.lessonType}/${globalState.id}/${globalState.studentId}/send-to-teacher`;

    console.log("sendActivity url:", urlToFetch);

    let json = this.getJson(globalState);

    let response = await fetch(urlToFetch, {
      method: "POST",
      headers: new Headers({
        Authorization: "Bearer " + globalState.authToken,
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(json),
    });

    if (response) {
      let content = await response.json();

      if (content.error) {
      }

      if (content.success) {
        let location = `${globalState.props.location.pathname}?type=delivered&justFinished=true`;

        if (globalState.authToken)
          location += `&auth_token=${globalState.authToken}`;

        if (globalState.role) location += `&role=${globalState.role}`;

        if (globalState.studentId) location += `&uuid=${globalState.studentId}`;

        if (globalState.devMode) location += "&devMode=true";

        if (globalState.teacherMode) location += "&teacherMode=true";

        window.location.replace(location);
      }
    }
  }

  static async saveCorrection(globalState) {
    if (globalState.authToken == "") return;

    let urlToFetch = `${process.env.REACT_APP_SITE_URL}api/v1/tasks/${globalState.lessonType}/${globalState.id}/${globalState.studentId}/teacher-save-correction`;

    console.log("saveCorrection url:", urlToFetch);

    let json = this.getJson(globalState);

    let response = await fetch(urlToFetch, {
      method: "POST",
      headers: new Headers({
        Authorization: "Bearer " + globalState.authToken,
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(json),
    });

    if (response) {
      let content = await response.json();

      if (content.error) {
      }

      if (content.success) {

      }
    }
  }

  static async closeCorrection(globalState) {
    if (globalState.authToken == "") return;

    let urlToFetch = `${process.env.REACT_APP_SITE_URL}api/v1/tasks/${globalState.lessonType}/${globalState.id}/${globalState.studentId}/teacher-close-correction`;

    console.log("closeCorrection url:", urlToFetch);

    let json = this.getJson(globalState);

    json.params.corrected = true;

    try {
      let response = await fetch(urlToFetch, {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + globalState.authToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(json),
      });

      if (response) {
        let content = await response.json();

        if (content.success) {
          let location = `${globalState.props.location.pathname}/saved?`;

          if (globalState.authToken)
            location += `auth_token=${globalState.authToken}`;

          if (globalState.role) location += `&role=${globalState.role}`;

          if (globalState.studentId)
            location += `&uuid=${globalState.studentId}`;

          if (globalState.devMode) location += "&devMode=true";

          if (globalState.teacherMode) location += "&teacherMode=true";

          window.location.replace(location);
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  }

  static async sendCorrection(globalState) {
    if (globalState.authToken == "") return;

    let urlToFetch = `${process.env.REACT_APP_SITE_URL}api/v1/tasks/${globalState.lessonType}/${globalState.id}/${globalState.studentId}/teacher-send-correction`;

    console.log("sendCorrection url:", urlToFetch);

    let json = this.getJson(globalState);

    json.params.corrected = true;

    try {
      let response = await fetch(urlToFetch, {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + globalState.authToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(json),
      });

      if (response) {
        let content = await response.json();

        if (content.success) {
          let location = `${globalState.props.location.pathname}/corrected?`;

          if (globalState.authToken)
            location += `auth_token=${globalState.authToken}`;

          if (globalState.role) location += `&role=${globalState.role}`;

          if (globalState.studentId)
            location += `&uuid=${globalState.studentId}`;

          if (globalState.devMode) location += "&devMode=true";

          if (globalState.teacherMode) location += "&teacherMode=true";

          window.location.replace(location);
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  }

  static async resetExercise(globalState) {
    if (globalState.authToken == "") return;

    let urlToFetch = `${process.env.REACT_APP_SITE_URL}api/v1/tasks/${globalState.lessonType}/${globalState.id}/${globalState.studentId}/reset`;

    console.log("resetExercise url:", urlToFetch);

    let response = await fetch(urlToFetch, {
      method: "POST",
      headers: new Headers({
        Authorization: "Bearer " + globalState.authToken,
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    });

    if (response) {
      let content = await response.json();

      window.location.reload();
    }
  }

  static getJson(globalState) {
    switch (globalState.role) {
      case "teacher":
        return LessonActivityApi.getTeacherJson(globalState);
      default:
        return LessonActivityApi.getStudentJson(globalState);
    }
  }

  static getStudentJson(globalState) {
    return {
      params: LessonActivityApi.getJsonParams(globalState),
      data: LessonActivityApi.getJsonData(globalState),
      results: {
        cursor: globalState.currentSlidePosition,
        completed: LessonActivityApi.getCompletedPercentage(globalState),
        totalTime: 1200,
        finished: LessonActivityApi.getFinished(globalState),
        answers: LessonActivityApi.getAnswers(globalState),
      },
    };
  }

  static getTeacherJson(globalState) {
    return {
      params: LessonActivityApi.getJsonParams(globalState),
      data: LessonActivityApi.getJsonData(globalState),
      results: {
        cursor: globalState.currentSlidePosition,
        completed: LessonActivityApi.getCompletedPercentage(globalState),
        // totalTime: 1200,
        finished: LessonActivityApi.getFinished(globalState),
        // answers: LessonActivityApi.getAnswers(globalState),
        finalCorrection: LessonActivityApi.getFinalCorrection(globalState),
        corrections: LessonActivityApi.getCorrections(globalState),
      },
    };
  }

  static getJsonParams(globalState) {
    return {
      key: globalState.id,
      type: globalState.type,
      uuid: globalState.apiInfo?.profile?.uuid ?? -1,
      role: globalState.apiInfo?.profile?.last_session_role ?? -1,
    };
  }

  static getJsonData(globalState) {
    return {
      name: globalState.activity.title,
      deadline: globalState.deadLine?.toISOString(),
      date: globalState.startTime.toISOString(),
      // limitTime: 60
    };
  }

  static getXapiSlideResult(globalState, currentSlidePosition){
    let result = {};

    if(globalState.slides[currentSlidePosition].info.slug === 'general-information' || globalState.slides[currentSlidePosition].info.slug === 'reviewed'
        || globalState.slides[currentSlidePosition].info.slug === 'summary' || globalState.slides[currentSlidePosition].info.slug === 'delivered')
      return {};

    let exerciseBlock_id = globalState.slides[currentSlidePosition].exerciseInfo?.exerciseBlock?.sys.id;

    if(!exerciseBlock_id)
      return {};

    let json = this.getJson(globalState);

    if(json.results && json.results.answers){
      let answers = json.results.answers;

      let exerciseAnswers = answers.find(answer => answer.exerciseBlock_id === exerciseBlock_id);

      if(exerciseAnswers){
        result['completion'] = exerciseAnswers.answered;
        result['success'] = exerciseAnswers.correct;
        result['extensions'] = {}
        result['extensions'][`${window.location.origin}/answers`] = exerciseAnswers.answers;
      }
    }

    return result;
  }

  static getXapiFinalResult(globalState, currentSlidePosition){
    let result = {};

    let json= this.getJson(globalState);

    if(json.results){
        result['completion'] = json.results.answered;
        result['extensions'] = {}
        result['extensions'][`${window.location.origin}/info`] = json;
    }

    return result;
  }

  static processJson(globalState) {
    let json = this.getJson(globalState);
  }

  static getFinished(globalState) {
    return (
      globalState.exercises.filter((e) => e.results.answered).length ===
      globalState.exercises.length
    );
  }

  static getAnswers(globalState) {
    let answers = [];
    for (let exercise of globalState.exercises) {
      answers.push(exercise.results);
    }
    return answers;
  }

  static getCompletedPercentage(globalState) {
    return (
      (globalState.exercises.filter((e) => e.results != null).filter((e) => e.results.answered).length /
        globalState.exercises.length) *
      100
    );
  }

  static getFinalCorrection(globalState) {
    return globalState.finalCorrection;
  }

  static getCorrections(globalState) {
    let corrections = [];
    for (let correction of globalState.corrections) {
      corrections.push(correction);
    }
    return corrections;
  }
}
