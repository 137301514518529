import React from "react";
import {t} from "i18next";
import {ReactComponent as IconInfo} from '../images/svg/Icon-Info.svg';

const RememberLineSummary = ({ exercises, lessonType }) => {
    let exercisesAnswered = exercises.filter((e) => e.results.answered)
    return (
        <div className="rememberLine">
            <IconInfo fill='#0D70B6' />

            <div className="rememberInfo">
                <div>
                    {exercisesAnswered.length < exercises.length ? t(`${lessonType}.not.completed.answered`) : t("answered")}
                    {" "}
                    <span className="answeredExercisesCount">{exercisesAnswered.length}{" "}{t("of")}{" "}{exercises.length}</span>
                    {" "}
                    {t("tasks")}
                </div>
                <div>{t(`${lessonType}.remember.check.results`)}</div>
            </div>
        </div>
    );
};

export default RememberLineSummary;
