import React, { useState, useLayoutEffect } from "react";
import { useQuery } from "@apollo/client";
import { renderKatex } from "../../../Utils/renderKatex";
import BlackLoader from "../../../images/black-loader.gif";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  getApartados,
  getReviewContent,
  getContentBlockToShow,
} from "../../ExerciseBlocks";
import { DigitalExerciseBlock1 } from "../DigitalExercises/DigitalExerciseBlock1";
import { DigitalExerciseBlock2 } from "../DigitalExercises/DigitalExerciseBlock2";
import { DigitalExerciseBlock4 } from "../DigitalExercises/DigitalExerciseBlock4";
import { DigitalExerciseBlock3 } from "../DigitalExercises/DigitalExerciseBlock3";
import { DigitalExerciseBlock5 } from "../DigitalExercises/DigitalExerciseBlock5";
import { DigitalExerciseBlock6 } from "../DigitalExercises/DigitalExerciseBlock6";
import { LessonExerciseGraphql } from "./LessonExercise.graphql";
import { t } from "i18next";
import StudentProfileName from "../../../Components/StudentProfileName";
import DigitalTemplateNumber from "../../../Components/DigitalTemplateNumber";

const LessonExerciseNote = (props) => {
  let { i18n } = useTranslation();

  const { globalState, exerciseInfo, exercises } = props;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useLayoutEffect(() => {
    renderKatex(document.body);
  });

  const { loading, error, data } = useQuery(
    LessonExerciseGraphql.getExerciseGraphQLCall(
      exerciseInfo.exerciseBlock.sys.id,
      i18n.language
    ),
    {
      client: LessonExerciseGraphql.getOrCreateGraphQLClient(),
        errorPolicy: "ignore"
    }
  );

  if (loading)
    return (
      <div align="center" className="pt-5">
        {" "}
        <img src={BlackLoader} alt="Loader" />{" "}
      </div>
    );
  if (error) return <p>Error :(</p>;

  exerciseInfo.exerciseBlock = data.exerciseBlock;

  let statement = exerciseInfo.exerciseBlock.digital
    ? exerciseInfo.exerciseBlock.digitalStatement
    : exerciseInfo.exerciseBlock.statement;

  let apartadosStatement = getApartados(statement.json);

  const reviewContent = getReviewContent(
    exerciseInfo.exerciseBlock.review?.json
  );

  let exerciseBlockToShow = getDigitalExerciseBlockToShow(
    exerciseInfo,
    globalState,
    exercises
  );

  return (
    <div className={`slideNote slideNote${exerciseInfo.slidePosition}`}
      data-position={exerciseInfo.slidePosition}>
      {globalState.apiInfo.student && globalState.role !== "student" ? (
          <StudentProfileName
              studentProfile={globalState.apiInfo.student}
              mode='fixed'
          />
      ) : null}
      <div className="slideNoteWrapper">
        <div className="slideNoteContent">
          <div className="container my-0">
            <div>
              {exerciseInfo.exerciseBlock.review && (
                <span className="noteContentModalButtonWrapper justify-content-end">
                  <Button
                    variant="none"
                    onClick={handleShow}
                    className="btn-primary"
                  >
                    {t("help")}
                  </Button>
                </span>
              )}
              <div className="row statement">
                <DigitalTemplateNumber globalState={globalState} exerciseInfo={exerciseInfo} />

                {apartadosStatement.map((apartado, index) => {
                  return (
                    <div
                      className="col-12"
                      key={`slideNote${exerciseInfo.slidePosition}-${index}`}
                    >
                      {apartado.map((contentBlock, indexb) => {
                        return (
                          <div
                            className="contentBlock"
                            key={`slideNote${exerciseInfo.slidePosition}-${index}-${indexb}`}
                          >
                            {getContentBlockToShow(
                              contentBlock,
                              false,
                              `slideNote${exerciseInfo.slidePosition}-${index}-${indexb}`,
                              statement.links?.assets?.block
                            )}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>

              <form
                action=""
                id={`exercise_${exerciseInfo.exerciseBlock.sys.id}`}
              >
                {exerciseBlockToShow}
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal
        dialogClassName="note-modal"
        show={show}
        onHide={handleClose}
        className="modal-help"
      >
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              background: "#white",
              fontFamily: "Roboto",
              fontSize: "16px",
              color: "#1d1d1b",
            }}
          >
            {t("help")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            background: "#white",
            fontFamily: "Roboto",
            fontSize: "16px",
            color: "#1d1d1b",
          }}
        >
          {reviewContent.map((apartado, index) => {
            return (
              <div
                className="col-12"
                style={{ padding: "2rem" }}
                key={`slideNote${exerciseInfo.slidePosition}-${index}`}
              >
                {apartado.map((contentBlock, indexb) => {
                  return (
                    <div style={{ paddingBottom: "1.5rem" }} key={indexb}>
                      {getContentBlockToShow(
                        contentBlock,
                        false,
                        `slideNote${exerciseInfo.slidePosition}-${index}-${indexb}`,
                        exerciseInfo.exerciseBlock.review?.links?.assets?.block
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            {t("close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

function getDigitalExerciseBlockToShow(exerciseInfo, globalState, exercises) {
  function parseAnswers(answersText) {
    let allAnswers = [];
    if (answersText) {
      let regexAnswer = /\[\[(.*?)\]\]/gm;
      let matches = [...answersText.matchAll(regexAnswer)];
      if (matches.length > 0) {
        for (const match of matches) {
          let answers = [];
          for (const answer of match[1].split("|")) {
            answers.push(answer);
          }
          allAnswers.push(answers);
        }
      }
    }
    return allAnswers;
  }

  let answers = exerciseInfo.exerciseBlock.digital
    ? parseAnswers(exerciseInfo.exerciseBlock.answers)
    : null;

  exerciseInfo.answers = answers;

  switch (exerciseInfo.exerciseBlock.digitalTemplate) {
    case 1:
      return (
        <DigitalExerciseBlock1
          assets={
            exerciseInfo.exerciseBlock.digitalContent.links?.assets?.block
          }
          exerciseInfo={exerciseInfo}
          globalState={globalState}
          exercises={exercises}
        />
      );
    case 2:
      return (
        <DigitalExerciseBlock2
          assets={
            exerciseInfo.exerciseBlock.digitalContent.links?.assets?.block
          }
          exerciseInfo={exerciseInfo}
          globalState={globalState}
          exercises={exercises}
        />
      );
    case 3:
      return (
        <DigitalExerciseBlock3
          assets={
            exerciseInfo.exerciseBlock.digitalContent.links?.assets?.block
          }
          exerciseInfo={exerciseInfo}
          globalState={globalState}
          exercises={exercises}
        />
      );
    case 4:
      return (
        <DigitalExerciseBlock4
          assets={
            exerciseInfo.exerciseBlock.digitalContent.links?.assets?.block
          }
          exerciseInfo={exerciseInfo}
          globalState={globalState}
          exercises={exercises}
        />
      );
    case 5:
      return (
        <DigitalExerciseBlock5
          assets={
            exerciseInfo.exerciseBlock.digitalContent.links?.assets?.block
          }
          exerciseInfo={exerciseInfo}
          globalState={globalState}
          exercises={exercises}
        />
      );
    case 6:
      return (
        <DigitalExerciseBlock6
          assets={
            exerciseInfo.exerciseBlock.digitalContent.links?.assets?.block
          }
          exerciseInfo={exerciseInfo}
          globalState={globalState}
          exercises={exercises}
        />
      );
    default:
      return <></>;
  }
}

export default LessonExerciseNote;
