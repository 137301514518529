import React from "react";
import {t} from "i18next";

const DigitalTemplateNumber = ({ exerciseInfo, globalState }) => {
    if(globalState.devMode){
        return (
            <h5>{t("template")}:{" "}{exerciseInfo.exerciseBlock.digitalTemplate}</h5>
        );
    }else{
        return null;
    }
};

export default DigitalTemplateNumber;
