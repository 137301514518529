import { ApolloClient, InMemoryCache } from "@apollo/client";

export function Client_GraphQL() {
  const token =
    process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN ||
    process.env.REACT_APP_TOKEN;
  const env = process.env.REACT_APP_CONTENTFUL_ENVIRONMENT || "master";
  return new ApolloClient({
    uri: `https://graphql.contentful.com/content/v1/spaces/2wexatmcd4kx/environments/${env}?access_token=${token}`,
    cache: new InMemoryCache()
  });
}
