import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  getApartados,
  getDigitalContentBlockToShow,
} from "../../ExerciseBlocks";
import $ from "jquery";
import AutosizeInput from "../../../Utils/AutosizeInput";
import { validateAnswersFormat } from "../../../Utils/validateAnswersFormat";
import { renderKatex } from "../../../Utils/renderKatex";
import {getAnswersFormat} from "../../../Utils/getAnswersFormat";

export const DigitalExerciseBlock5 = (props) => {
  const { assets, exerciseInfo, globalState, exercises } = props;

  const [showAlert, setShowAlert] = useState(false);

  let contentJson =
    exerciseInfo.exerciseBlock.digitalContent?.json ??
    exerciseInfo.exerciseBlock.content?.json;

  const [rows, setRows] = useState([]);

  useEffect(() => {
    refreshRows();
  }, [exerciseInfo]);

  useLayoutEffect(() => {
    renderKatex(document.body);
  });

  useEffect(() => {
    if (globalState.mode.includes("in_review") || globalState.mode === "reviewed")
      return;
    refreshAnswers();
  }, [rows]);

  const [answers, setAnswers] = useState(getAnswers());

  useEffect(() => {
    if (globalState.mode.includes("in_review") || globalState.mode === "reviewed")
      return;
    refreshResults();
  }, [answers]);

  function getAnswers() {
    let answers = [];
    if (exerciseInfo.results !== "") {
      answers = exerciseInfo.results.answers
        .replaceAll("[", "")
        .replaceAll("]", "")
        .replaceAll("empty", "")
        .split("|");
    }
    return answers;
  }

  function refreshResults() {
    setShowAlert(false);

    try {
      let results = {
        ...exerciseInfo.results,
        correctAnswer: `[[${exerciseInfo.answers[0]?.join("|")}]]`,
        answers: `[[${answers.join("|")}]]`,
        level: calculateLevel(exerciseInfo.answers[0], answers),
        score: calculateScore(exerciseInfo.answers[0], answers),
        correct: isCorrect(exerciseInfo.answers[0], answers),
        answered: isAnswered(exerciseInfo.answers[0], answers),
      };

      const updatedExercises = exercises.map((exercise) => {
        if (exercise.slidePosition === exerciseInfo.slidePosition) {
          return { ...exercise, results: results };
        }

        return exercise;
      });

      globalState.setExercises(updatedExercises);
    } catch (e) {
      console.log("DigitalExerciseBlock5", e);
      setShowAlert(true);
    }
  }

  function calculateLevel(correctAnswers, userAnswers) {
    let score = calculateScore(correctAnswers, userAnswers);
    if (score <= 4) return 1;
    else if (score <= 6) return 2;
    else if (score <= 8) return 3;
    else return 4;
  }

  function calculateScore(correctAnswers, userAnswers) {
    let numCorrectAnswers = correctAnswers
      .map(function (value, index) {
        const ansFormat = getAnswersFormat(exerciseInfo.exerciseBlock, index);
        return (
          value.toLowerCase() === userAnswers[index]?.toLowerCase() ||
          validateAnswersFormat(userAnswers[index], value, ansFormat)
        );
      })
      .filter((v) => v).length;
    return (10 * numCorrectAnswers) / userAnswers.length;
  }

  function isCorrect(correctAnswers, userAnswers) {
    return (
      correctAnswers.length === userAnswers.length &&
      correctAnswers.every(function (value, index) {
        return value.toLowerCase() === userAnswers[index].toLowerCase();
      })
    );
  }

  function isAnswered(correctAnswers, userAnswers) {
    return userAnswers.some((a) => a !== "empty");
  }

  function refreshAnswers() {
    if (globalState.mode === "display_with_data") return;

    let inputTexts = $(
      `#digitalExercise${exerciseInfo.exerciseBlock.sys.id} input[name="inputText"]`
    );

    if (inputTexts.length > 1) {
      let currentAnswers = [];

      inputTexts.each(function () {
        currentAnswers.push($(this).val() !== "" ? $(this).val() : "empty");
      });

      setAnswers(currentAnswers);
    }
  }

  function refreshRows() {
    let apartados = getApartados(contentJson);

    let infoTable = apartados.shift();

    infoTable = infoTable[0]?.content[0]?.value?.replace(".", ",").split(",");

    let index = 0;
    let rows = [];
    for (let rowPosition = 0; rowPosition < infoTable[0]; rowPosition++) {
      let row = [];
      for (let colPosition = 0; colPosition < infoTable[1]; colPosition++) {
        row.push(apartados[index]);
        index++;
      }
      rows.push(row);
    }

    setRows(rows);
  }

  let answerPos = -1;
  return (
    <section
      className="digitalExerciseBlock digitalExerciseBlock5"
      id={`digitalExercise${exerciseInfo.exerciseBlock.sys.id}`}
    >
      {showAlert ? (
        <div className="alert alert-danger" role="alert">
          Este ejercicio tiene errores en los datos.
          <br />
          ExerciseBlock idName: {exerciseInfo.exerciseBlock.idName}
        </div>
      ) : (
        <></>
      )}
      <table className="table table-bordered">
        <tbody>
          {rows.map((row, index) => {
            return (
              <tr
                className="apartado"
                key={`${exerciseInfo.exerciseBlock.sys.id}-row-${index}`}
              >
                {row
                  .filter((a) => a != null)
                  .map((apartado, indexa) => {
                    let isAnswer = apartado.some((b) => {
                      return b.content?.some((c) => {
                        return c.value.includes("**x**");
                      });
                    });
                    if (isAnswer) {
                      answerPos++;
                      return (
                        <td key={indexa}>
                          <div className="contentBlock">
                            <div className="my-3">
                              {(() => {
                                switch (globalState.mode) {
                                  case "solve_first_time":
                                  case "solve_retry":
                                    return (
                                      <AutosizeInput
                                        onChange={refreshAnswers}
                                        type="text"
                                        id={`input${index}`}
                                        name={`inputText`}
                                        length={answers[answerPos]?.length}
                                        placeholder={
                                          (globalState.devMode || globalState.teacherMode) &&
                                          exerciseInfo.answers[0]
                                            ? exerciseInfo.answers[0][answerPos]
                                            : ""
                                        }
                                      />
                                    );
                                  case "solve_continue":
                                    return (
                                      <AutosizeInput
                                        onChange={refreshAnswers}
                                        type="text"
                                        id={`input${index}`}
                                        name={`inputText`}
                                        length={answers[answerPos]?.length}
                                        placeholder={
                                          (globalState.devMode || globalState.teacherMode) &&
                                          exerciseInfo.answers[0]
                                            ? exerciseInfo.answers[0][answerPos]
                                            : ""
                                        }
                                        value={
                                          answers[answerPos] &&
                                          answers[answerPos] !== "empty"
                                            ? answers[answerPos]
                                            : ""
                                        }
                                      />
                                    );
                                  case "in_review_first_time":
                                  case "in_review_continue":
                                  case "display_with_data":
                                  case "reviewed":
                                    const ansFormat = getAnswersFormat(exerciseInfo.exerciseBlock, answerPos);

                                    if (exerciseInfo.answers[0][answerPos] ===
                                        answers[answerPos] ||
                                      validateAnswersFormat(
                                        answers[answerPos],
                                        exerciseInfo?.answers[0][answerPos],
                                        ansFormat
                                      )
                                    ) {
                                      return (
                                        <AutosizeInput
                                          className={`correct answer`}
                                          type="text"
                                          id={`input${index}`}
                                          name={`inputText`}
                                          length={answers[answerPos]?.length}
                                          placeholder={
                                            (globalState.devMode || globalState.teacherMode) &&
                                            exerciseInfo.answers[0]
                                              ? exerciseInfo.answers[0][index]
                                              : ""
                                          }
                                          value={
                                            exerciseInfo.answers[0][
                                              answerPos
                                            ] || ""
                                          }
                                          readOnly={true}
                                          size={1}
                                        />
                                      );
                                    } else {
                                      return (
                                        <div>
                                          <AutosizeInput
                                            className={`wrong`}
                                            type="text"
                                            id={`input_${index}`}
                                            name={`inputText`}
                                            length={answers[answerPos]?.length}
                                            placeholder={
                                              (globalState.devMode || globalState.teacherMode) &&
                                              exerciseInfo.answers[0]
                                                ? exerciseInfo.answers[0][
                                                    answerPos
                                                  ]
                                                : ""
                                            }
                                            value={
                                              exerciseInfo?.results?.answers
                                                ? answers[answerPos]
                                                : ""
                                            }
                                            readOnly={true}
                                          />
                                          <AutosizeInput
                                            className={`correctAnswer answer`}
                                            type="text"
                                            id={`input${index}`}
                                            name={`inputText`}
                                            length={answers[answerPos]?.length}
                                            value={
                                              exerciseInfo.answers[0]
                                                ? exerciseInfo.answers[0][
                                                    answerPos
                                                  ]
                                                : ""
                                            }
                                            readOnly={true}
                                            size={1}
                                          />
                                        </div>
                                      );
                                    }
                                  case "display_without_data":
                                    return (
                                      <AutosizeInput
                                        type="text"
                                        id={`input${index}`}
                                        name={`inputText`}
                                        length={answers[answerPos]?.length}
                                        placeholder={
                                          (globalState.devMode || globalState.teacherMode) &&
                                          exerciseInfo.answers[0]
                                            ? exerciseInfo.answers[0][answerPos]
                                            : ""
                                        }
                                        readOnly={true}
                                      />
                                    );
                                  default:
                                    return <></>;
                                }
                              })()}
                            </div>
                          </div>
                        </td>
                      );
                    } else {
                      return (
                        <td key={indexa}>
                          {apartado.map((contentBlock, indexb) => {
                            return (
                              <div
                                className="contentBlock"
                                key={`${exerciseInfo.exerciseBlock.sys.id}-${indexa}-${indexb}`}
                              >
                                {getDigitalContentBlockToShow(
                                  contentBlock,
                                  `${exerciseInfo.exerciseBlock.sys.id}-${indexa}-${indexb}`,
                                  assets
                                )}
                              </div>
                            );
                          })}
                        </td>
                      );
                    }
                  })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </section>
  );
};
