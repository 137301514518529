import React from "react";
import {t} from "i18next";
import {ReactComponent as IconBubble} from '../images/svg/Icon-Bubble.svg';
import {twoDecimals} from "../Utils/twoDecimals";

const TeacherCommentsAsStudent = ({ correction, showScore= false }) => {
    return (
        <div className={`slideNoteTeacherComments asStudent`}>
            <div className="container">
                <div className="wrapper">
                    <div className="icon">
                        <IconBubble />
                    </div>
                    <div className="commentsWrapper">
                        <h6 className={"title"}>{t("teacher.comment")}</h6>
                        <div className="comments">
                            { correction.feedback }
                        </div>
                    </div>
                    {showScore ?
                        <div className="scoreWrapper">
                            <div className={`score ${correction.answered ? "answered" : "unanswered"} ${correction.correct ? "correct" : "wrong"}`}>
                                { twoDecimals(correction.score) ?? correction.score }
                            </div>
                        </div>
                    : null}
                </div>
            </div>
        </div>
    );
};

export default TeacherCommentsAsStudent;
