import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  getApartados,
  getDigitalContentBlockToShow,
} from "../ExerciseBlocks";
import $ from "jquery";
import AutosizeInput from "../../Utils/AutosizeInput";
import { validateAnswersFormat } from "../../Utils/validateAnswersFormat";
import { renderKatex } from "../../Utils/renderKatex";
import {getAnswersFormat} from "../../Utils/getAnswersFormat";

export const TestExerciseBlock5 = (props) => {
  const { assets, exerciseInfo, globalState } = props;

  let contentJson =
    exerciseInfo.exerciseBlock.digitalContent?.json ??
    exerciseInfo.exerciseBlock.content?.json;

  const [rows, setRows] = useState([]);

  useEffect(() => {
    refreshRows();
  }, [exerciseInfo]);

  useLayoutEffect(() => {
    renderKatex(document.body);
  });

  useEffect(() => {
    refreshAnswers();
  }, [rows]);

  const [answers, setAnswers] = useState(getAnswers());

  useEffect(() => {
    refreshResults();
  }, [answers]);

  function getAnswers() {
    let answers = [];
    if (exerciseInfo.results !== "") {
      answers = exerciseInfo.results.answers
        .replaceAll("[", "")
        .replaceAll("]", "")
        .replaceAll("empty", "")
        .split("|");
    }
    return answers;
  }

  function refreshResults() {

    try {

    } catch (e) {
      console.log("DigitalExerciseBlock5", e);
    }
  }

  function refreshAnswers() {

    let inputTexts = $(`#digitalExercise${exerciseInfo.exerciseBlock.sys.id} input[name="inputText"]`);

    if (inputTexts.length > 1) {
      let currentAnswers = [];

      inputTexts.each(function () {
        currentAnswers.push($(this).val() !== "" ? $(this).val() : "empty");
      });

      setAnswers(currentAnswers);
    }
  }

  function refreshRows() {
    let apartados = getApartados(contentJson);

    let infoTable = apartados.shift();

    infoTable = infoTable[0]?.content[0]?.value?.split(",");

    let index = 0;
    let rows = [];
    for (let rowPosition = 0; rowPosition < infoTable[0]; rowPosition++) {
      let row = [];
      for (let colPosition = 0; colPosition < infoTable[1]; colPosition++) {
        row.push(apartados[index]);
        index++;
      }
      rows.push(row);
    }

    setRows(rows);
  }

  let answerPos = -1;
  return (
    <section className="digitalExerciseBlock digitalExerciseBlock5"
      id={`digitalExercise${exerciseInfo.exerciseBlock.sys.id}`}>
      <table className="table table-bordered">
        <tbody>
          {rows.map((row, index) => {
            return (
              <tr className="apartado"
                key={`${exerciseInfo.exerciseBlock.sys.id}-row-${index}`}>
                {row.filter((a) => a != null)
                  .map((apartado, indexa) => {
                    let isAnswer = apartado.some((b) => {
                      return b.content?.some((c) => {
                        return c.value.includes("**x**");
                      });
                    });
                    if (isAnswer) {
                      answerPos++;
                      return (
                        <td key={indexa}>
                          <div className="contentBlock">
                            <div className="my-3">
                              {(() => {
                                const ansFormat = getAnswersFormat(exerciseInfo.exerciseBlock, answerPos);

                                let hasValue = answers[answerPos] && answers[answerPos] !== "empty"
                                let isCorrect = validateAnswersFormat(
                                        answers[answerPos],
                                        exerciseInfo?.answers[0][answerPos],
                                        ansFormat);
                                return (
                                    <div className={`d-flex flex-row`}>
                                      <AutosizeInput
                                          className={`${hasValue ? (isCorrect ? 'correct' : 'wrong') : ''} answer`}
                                          onChange={refreshAnswers}
                                          type="text"
                                          id={`input${index}`}
                                          name={`inputText`}
                                          length={answers[answerPos]?.length}
                                          placeholder={""}
                                      />
                                      <AutosizeInput
                                          className={`correctAnswer answer`}
                                          type="text"
                                          id={`input_${index}`}
                                          name={`inputTex2t`}
                                          length={answers[answerPos]?.length}
                                          value={ exerciseInfo.answers[0]
                                                ? exerciseInfo.answers[0][answerPos]
                                                : "" }
                                          readOnly={true}
                                          size={1}
                                      />
                                    </div>
                                );
                              })()}
                            </div>
                          </div>
                        </td>
                      );
                    } else {
                      return (
                        <td key={indexa}>
                          {apartado.map((contentBlock, indexb) => {
                            return (
                              <div
                                className="contentBlock"
                                key={`${exerciseInfo.exerciseBlock.sys.id}-${indexa}-${indexb}`}
                              >
                                {getDigitalContentBlockToShow(
                                  contentBlock,
                                  `${exerciseInfo.exerciseBlock.sys.id}-${indexa}-${indexb}`,
                                  assets
                                )}
                              </div>
                            );
                          })}
                        </td>
                      );
                    }
                  })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </section>
  );
};
