import { createClient } from "contentful";

export function client() {
  return createClient({
    space:
      process.env.REACT_APP_CONTENTFUL_SPACE || process.env.REACT_APP_SPACE,
    environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT || "master",
    accessToken:
      process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN ||
      process.env.REACT_APP_TOKEN,
  });
}
