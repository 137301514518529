import React from "react";
import {t} from "i18next";
import {twoDecimals} from "../Utils/twoDecimals";
import RememberLineResults from "./RememberLineResults";
import {ReactComponent as IconCheckBadge} from '../images/svg/Icon-Check-Badge.svg';

const ActivityTestAttemptInfo = ({ exercises, results, globalState, corrections, title, showBadge = false, refreshCorrection = null, onBlur = null }) => {
    if(results.score != null){
        return (
            <div className="attemptInfo">
                <div className="attemptTopBar">
                    <div className="attemptTitle">
                        {showBadge ? <IconCheckBadge /> : null}
                        <span>{title}</span>
                    </div>
                    <div className={`attemptScore ${results.score >= 5 ? "green" : ""}`}>
                        { refreshCorrection ?
                            <input
                                type="text"
                                inputMode="numeric"
                                value={twoDecimals(results.score) ?? "-"}
                                name={`score`}
                                onChange={refreshCorrection}
                                onBlur={onBlur}
                            />
                            : twoDecimals(results.score) ?? "-"
                        }
                    </div>
                </div>

                <div className="attemptExercises">
                    <div className="exercisesLine">
                        {exercises.map((exercise, exercisePosition) => {
                            let correction = corrections[exercise.position - 1];
                            return (
                                <button
                                    className={`exerciseItem 
                                    ${ correction.answered || correction.score > 0 ? "answered" : "unanswered" }
                                    ${ globalState.role === "teacher" ? (correction.corrected ? "corrected" : "uncorrected") : '' }
                                    ${ correction.correct ? "correct" : "wrong" }`}
                                    onClick={globalState.functions.goToSlide}
                                    data-slide={exercise.slidePosition}
                                    id={`exerciseItem${exercisePosition}`}
                                    key={`exerciseItem${exercisePosition}${exercise.position}`}>
                                    <div className="title">
                                        {t("task")} {exercise.position}
                                    </div>
                                    <div className="score">
                                        {twoDecimals(correction.score)}
                                    </div>
                                </button>
                            );
                        })}
                    </div>

                    <RememberLineResults exercises={exercises} />

                </div>
            </div>
        );
    }else{
        return null;
    }
};

export default ActivityTestAttemptInfo;
