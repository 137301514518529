import React, { Component } from "react";
import { withRouter } from "../Utils/withRouter";
import { Client_GraphQL } from "./Client_GraphQL";
import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

class Activities extends Component {
  constructor() {
    super();
    this.state = {
      slug: "",
    };
  }

  componentDidMount() {
    this.setState({
      slug: this.props.match.params.slug ?? "no slug",
    });
  }

  getOrCreateGraphQLClient() {
    if (this.graphQLClient == null) this.graphQLClient = Client_GraphQL();
    return this.graphQLClient;
  }

  render() {
    const { i18n, t } = this.props;

    const GET_LESSONS = gql`
          query getLessons{
              lessonsCollection(locale:"${i18n.language}"){
                items{
                  sys{
                    id
                  }
                  name
                  slug
                  cumulativeActivitiesCollection(limit:1){
                    items{
                      sys{
                        id
                      }
                      title
                    }
                  }
                  linkedFrom(allowedLocales: ["es-ES", "ca", "en-CA"]){
                    unitsCollection(limit:1){
                      items{
                        sys{
                          id
                        }
                        linkedFrom(allowedLocales: ["es-ES", "ca", "en-CA"]){
                          gradesCollection(limit:1){
                            items{
                              sys{
                                id
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
        `;

    const LessonsList = (props) => {
      const { t } = props;

      const { loading, error, data } = useQuery(GET_LESSONS, {
        client: this.getOrCreateGraphQLClient(),
        errorPolicy: "ignore"
      });

      if (loading) return <p>Loading...</p>;
      if (error) return <p>Error :(</p>;

      return (
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>{t("lesson")}</th>
              <th>{t("activity")}</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {data.lessonsCollection.items
              .filter((i) => i.cumulativeActivitiesCollection.items.length > 0)
              .map((lesson, index) => {
                let unit =
                  lesson.linkedFrom?.unitsCollection?.items?.length > 0
                    ? lesson.linkedFrom.unitsCollection.items[0]
                    : null;
                let grade =
                  unit?.linkedFrom?.gradesCollection?.items?.length > 0
                    ? unit.linkedFrom.gradesCollection.items[0]
                    : null;

                let _unitId = unit != null ? unit.sys.id : undefined;
                let _gradeId = grade != null ? grade.sys.id : undefined;

                if (_unitId !== undefined && _gradeId !== undefined) {
                  return (
                    <>
                      {lesson.cumulativeActivitiesCollection?.items.map(
                        (activity, activityIndex) => {
                          return (
                            <tr key={`${index}${activityIndex}`}>
                              <td className={`title2`}>{lesson.name}</td>
                              <td className={`title`}>{activity.title}</td>
                              <td className="text-end">
                                <Link
                                  className="btn btn-primary btn-sm mx-2"
                                  to={`../lessons/${_gradeId}/${_unitId}/${activity.sys.id}/cumulative-activity?type=display`}
                                >
                                  {t("lessons.ver")}
                                </Link>
                                <Link
                                  className="btn btn-primary btn-sm mx-2"
                                  to={`../lessons/${_gradeId}/${_unitId}/${activity.sys.id}/cumulative-activity?type=not-initialized`}
                                >
                                  {t("lessons.resolver")}
                                </Link>
                                <Link
                                  className="btn btn-secondary btn-sm mx-2"
                                  to={`../lessons/${_gradeId}/${_unitId}/${activity.sys.id}/cumulative-activity?type=not-initialized&useTestUser=true&devMode=true`}
                                >
                                  {t("lessons.testear")}
                                </Link>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </>
                  );
                } else {
                  return (
                    <tr key={index}>
                      <td className={`title`}>{lesson.name}</td>
                      <td className="text-end">{t("lessons.no_units")}</td>
                    </tr>
                  );
                }
              })}
          </tbody>
        </table>
      );
    };

    return (
      <div className={`activities`}>
        <div className="container my-5">
          <div className="row">
            <div className="col-12">
              <div className="text-center">
                <h2>{t("navbar.activities")}</h2>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-12">
              <LessonsList t={t} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Activities);
