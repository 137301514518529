import React, {useEffect, useLayoutEffect, useState} from "react";
import {
  getApartados,
  getContentBlockToShow
} from "../../ExerciseBlocks";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import CustomEditor from "ckeditor5-custom-build/build/ckeditor";
import "ckeditor5-custom-build/build/translations/ca";
import {t} from "i18next";
import {renderKatex} from "../../../Utils/renderKatex";

export const DigitalExerciseBlock7 = React.memo((props) => {
  const { assets, exerciseInfo, globalState, exercises } = props;

  const [showAlert, setShowAlert] = useState(false);

  let contentJson =
    exerciseInfo.exerciseBlock.digitalContent?.json ??
    exerciseInfo.exerciseBlock.content?.json;

  useEffect(() => {}, [exercises]);

  let initialAnswer = getAnswers();

  const [answers, setAnswers] = useState(initialAnswer);

  useLayoutEffect(() => {
    renderKatex(document.body);
  });

  useEffect(() => {
    refreshResults();
  }, [answers]);

  function getAnswers() {
    let answers = "<p></p>";
    if (exerciseInfo.results !== "") {
      answers = exerciseInfo.results.answers;
    }
    return answers;
  }

  function refreshResults() {
    try {
      let results = {
        ...exerciseInfo.results,
        correctAnswer: ``,
        answers: `${answers}`,
        level: 0,
        score: 0,
        correct: true,
        answered: isAnswered(answers),
      };

      const updatedExercises = exercises.map((exercise) => {
        if (exercise.slidePosition === exerciseInfo.slidePosition) {
          return { ...exercise, results: results };
        }

        return exercise;
      });

      globalState.setExercises(updatedExercises);
    } catch (e) {
      console.log("DigitalExerciseBlock7", e);
      setShowAlert(true);
    }
  }

  function calculateLevel(correctAnswers, userAnswers) {
    let score = calculateScore(correctAnswers, userAnswers);
    if (score <= 4) return 1;
    else if (score <= 6) return 2;
    else if (score <= 8) return 3;
    else return 4;
  }

  function calculateScore(correctAnswers, userAnswers) {
    let numCorrectAnswers = correctAnswers
      ?.map(function (value, index) {
        return value.toLowerCase() === userAnswers[index].toLowerCase();
      })
      .filter((v) => v).length;
    return (10 * numCorrectAnswers) / userAnswers.length;
  }

  function isAnswered(userAnswers) {
    return userAnswers !== "";
  }

  function refreshAnswers(data) {
    setAnswers(data);
  }

  let showAsAnswered =
    (globalState.role === "student" && globalState.mode === "reviewed") ||
      (globalState.role === "teacher") || globalState.devMode;

  return (
    <section
      className="digitalExerciseBlock digitalExerciseBlock7"
      id={`digitalExercise${exerciseInfo.exerciseBlock.sys.id}`}
    >
      {showAlert ? (
        <div className="alert alert-danger" role="alert">
          Este ejercicio tiene errores en los datos.
          <br />
          ExerciseBlock idName: {exerciseInfo.exerciseBlock.idName}
        </div>
      ) : (
        <></>
      )}
      <table>
        <tbody>
          <tr>
            <td>
              <div className={`${showAsAnswered ? "showAsAnswered" : ""}`}>
                <CKEditor
                  config={{
                    language: "es",
                    simpleUpload: {
                      // The URL that the images are uploaded to.
                      uploadUrl: `${process.env.REACT_APP_SITE_URL}api/v1/media/ck-upload-image`,

                      // Enable the XMLHttpRequest.withCredentials property.
                      withCredentials: false,

                      // Headers sent along with the XMLHttpRequest to the upload server.
                      headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${globalState.authToken}`,
                      },

                      callback: () => {
                        console.log(1);
                      },
                    },
                  }}
                  editor={CustomEditor}
                  data={initialAnswer}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    // console.log( 'Editor is ready to use!', editor );
                    if (showAsAnswered) {
                      editor?.enableReadOnlyMode("feature-id");
                      editor.ui.view.toolbar.element.style.display = "none";
                    }
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    refreshAnswers(data);
                  }}
                />
              </div>
            </td>
          </tr>

          {showAsAnswered && exerciseInfo.exerciseBlock.openAnswer ? (
            <tr>
              <td>
                <div className="openAnswer">
                  <div className="cabecera">
                    <svg
                      width="18"
                      height="20"
                      viewBox="0 0 18 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.012 16H18V2C18 1.46957 17.7893 0.960859 17.4142 0.585786C17.0391 0.210714 16.5304 0 16 0H3C1.794 0 0 0.799 0 3V17C0 19.201 1.794 20 3 20H18V18H3.012C2.55 17.988 2 17.805 2 17C2 16.195 2.55 16.012 3.012 16ZM5 4H14V6H5V4Z"
                        fill="#0D70B6"
                      />
                    </svg>
                    <span>{t("solucionario")}</span>
                  </div>
                  <div className="row">
                    {getApartados(
                      exerciseInfo.exerciseBlock.openAnswer.json
                    ).map((apartado, index) => {
                      return (
                        <div
                          className="col-12"
                          key={`openAnswer${exerciseInfo.slidePosition}-${index}`}
                        >
                          {apartado.map((contentBlock, indexb) => {
                            return (
                              <div
                                className="contentBlock"
                                key={`openAnswer${exerciseInfo.slidePosition}-${index}-${indexb}`}
                              >
                                {getContentBlockToShow(
                                  contentBlock,
                                  false,
                                  `slideNote${exerciseInfo.slidePosition}-${index}-${indexb}`,
                                  exerciseInfo.exerciseBlock.openAnswer.links
                                    ?.assets?.block
                                )}
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </td>
            </tr>
          ) : (
            <></>
          )}
        </tbody>
      </table>
    </section>
  );
});
