import {ApolloClient, createHttpLink, InMemoryCache} from "@apollo/client";
import { createClient } from "contentful";
import {setContext} from "@apollo/client/link/context";

export class GraphQLClient {
  static getOrCreate() {
    if (this.graphQLClient == null) this.graphQLClient = Client_GraphQL();
    return this.graphQLClient;
  }
}

function Client_GraphQL() {
  const space = process.env.REACT_APP_CONTENTFUL_SPACE;
  const env = process.env.REACT_APP_CONTENTFUL_ENVIRONMENT || "dev";

  const httpLink = createHttpLink({
    uri: `https://graphql.contentful.com/content/v1/spaces/${space}/environments/${env}`,
  });

  const authLink = setContext((_, { headers }) => {
    const token =
        process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN ||
        process.env.REACT_APP_TOKEN;
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      }
    }
  });

  const link = authLink.concat(httpLink)

  return new ApolloClient({
    link: link,
    cache: new InMemoryCache()
  });
}

export class DeliveryClient {
  static getOrCreate() {
    if(this.deliveryClient == null) this.deliveryClient = Client_Delivery();
    return this.deliveryClient;
  }
}

function Client_Delivery() {
  return createClient({
    space:
        process.env.REACT_APP_CONTENTFUL_SPACE || process.env.REACT_APP_SPACE,
    environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT || "master",
    accessToken:
        process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN ||
        process.env.REACT_APP_TOKEN,
  });
}