import React, { Component } from "react";
import { withRouter } from "../Utils/withRouter";
import { Client_GraphQL } from "./Client_GraphQL";
import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

class Tests extends Component {
  constructor() {
    super();
    this.state = {
      slug: "",
    };
  }

  componentDidMount() {
    this.setState({
      slug: this.props.match.params.slug ?? "no slug",
    });
  }

  getOrCreateGraphQLClient() {
    if (this.graphQLClient == null) this.graphQLClient = Client_GraphQL();
    return this.graphQLClient;
  }

  render() {
    const { i18n, t } = this.props;

    const GET_TESTS = gql`
          query getLessons{
              testsCollection(locale:"${i18n.language}"){
                items{
                  sys{
                    id
                  }
                  name
                  idName
                  linkedFrom(allowedLocales: ["es-ES", "ca", "en-CA"]){
                    unitsCollection(limit:1){
                      items{
                        sys{
                          id
                        }
                        name
                        linkedFrom(allowedLocales: ["es-ES", "ca", "en-CA"]){
                          gradesCollection(limit:1){
                            items{
                              sys{
                                id
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
        `;

    const TestsList = (props) => {
      const { t } = props;

      const { loading, error, data } = useQuery(GET_TESTS, {
        client: this.getOrCreateGraphQLClient(),
        errorPolicy: "ignore"
      });

      if (loading) return <p>Loading...</p>;
      if (error) return <p>Error :(</p>;

      return (
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>{t("unidad")}</th>
              <th>{t("test")}</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {data.testsCollection.items.map((test, index) => {
              let unit =
                test.linkedFrom?.unitsCollection?.items?.length > 0
                  ? test.linkedFrom.unitsCollection.items[0]
                  : null;
              let grade =
                unit?.linkedFrom?.gradesCollection?.items?.length > 0
                  ? unit.linkedFrom.gradesCollection.items[0]
                  : null;

              let _unitId = unit != null ? unit.sys.id : undefined;
              let _gradeId = grade != null ? grade.sys.id : undefined;

              if (_unitId !== undefined && _gradeId !== undefined) {
                return (
                  <tr key={index}>
                    <td>{unit.name}</td>
                    <td className={`title`}>{test.name}</td>
                    <td className="text-end">
                      <Link
                        className="btn btn-primary btn-sm mx-2"
                        to={`../lessons/${_gradeId}/${_unitId}/${test.sys.id}/test?type=display`}
                      >
                        {t("lessons.ver")}
                      </Link>
                      <Link
                        className="btn btn-primary btn-sm mx-2"
                        to={`../lessons/${_gradeId}/${_unitId}/${test.sys.id}/test?type=opened`}
                      >
                        {t("lessons.resolver")}
                      </Link>
                      <Link
                        className="btn btn-secondary btn-sm mx-2"
                        to={`../lessons/${_gradeId}/${_unitId}/${test.sys.id}/test?type=opened&useTestUser=true&devMode=true`}
                      >
                        {t("lessons.testear")}
                      </Link>
                    </td>
                  </tr>
                );
              } else {
                return (
                  <tr key={index}>
                    <td className={`title`}>{test.name}</td>
                    <td className="text-end" colSpan={`2`}>
                      {t("tests.no_units")}
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      );
    };

    return (
      <div className={`activities`}>
        <div className="container my-5">
          <div className="row">
            <div className="col-12">
              <div className="text-center">
                <h2>{t("navbar.tests")}</h2>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-12">
              <TestsList t={t} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Tests);
