import React, { useState, useLayoutEffect } from "react";
import { renderKatex } from "../../../Utils/renderKatex";
import { useQuery } from "@apollo/client";
import BlackLoader from "../../../images/black-loader.gif";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  getApartados,
  getReviewContent,
  getContentBlockToShow,
} from "../../ExerciseBlocks";
import { DigitalExerciseBlock1 } from "../DigitalExercises/DigitalExerciseBlock1";
import { DigitalExerciseBlock2 } from "../DigitalExercises/DigitalExerciseBlock2";
import { DigitalExerciseBlock4 } from "../DigitalExercises/DigitalExerciseBlock4";
import { DigitalExerciseBlock3 } from "../DigitalExercises/DigitalExerciseBlock3";
import { DigitalExerciseBlock5 } from "../DigitalExercises/DigitalExerciseBlock5";
import { DigitalExerciseBlock6 } from "../DigitalExercises/DigitalExerciseBlock6";
import { LessonActivityGraphql } from "./LessonActivity.graphql";
import { DigitalExerciseBlock7 } from "../DigitalExercises/DigitalExerciseBlock7";
import $ from "jquery";
import { LessonActivityFunctions } from "./LessonActivity.functions";
import { twoDecimals } from "../../../Utils/twoDecimals";
import { t } from "i18next";
import StudentProfileName from "../../../Components/StudentProfileName";
import TeacherCommentsAsStudent from "../../../Components/TeacherCommentsAsStudent";
import TeacherCommentsAsTeacher from "../../../Components/TeacherCommentsAsTeacher";
import {DigitalExerciseBlock8} from "../DigitalExercises/DigitalExerciseBlock8";
import DigitalTemplateNumber from "../../../Components/DigitalTemplateNumber";

const LessonExerciseNote = (props) => {
  let { i18n } = useTranslation();

  const { globalState, exerciseInfo, exercises, corrections } = props;

  const [correction, setCorrection] = useState(
    corrections[exerciseInfo.position - 1]
  );

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useLayoutEffect(() => {
    renderKatex(document.body);
  });

  useLayoutEffect(() => {
    const updatedCorrections = corrections.map((correctionToUpdate) => {
      const parseScore = twoDecimals(correction.score);
      if (correctionToUpdate.exerciseBlock_id === correction.exerciseBlock_id) {
        return {
          ...correctionToUpdate,
          // score: correction.score,
          score: parseScore,
          feedback: correction.feedback,
          level: correction.level,
          correct: correction.correct,
          corrected: correction.corrected,
          answered: correction.answered,
        };
      }

      return correctionToUpdate;
    });

    globalState.setCorrections(updatedCorrections);
  }, [correction]);

  const { loading, error, data } = useQuery(
    LessonActivityGraphql.getExerciseGraphQLCall(
      exerciseInfo.exerciseBlock.sys.id,
      i18n.language
    ),
    {
      client: LessonActivityGraphql.getOrCreateGraphQLClient(),
      errorPolicy: "ignore"
    }
  );

  if (loading)
    return (
      <div align="center" className="pt-5">
        {" "}
        <img src={BlackLoader} alt="Loader" />{" "}
      </div>
    );
  if (error) return <p>Error :(</p>;

  exerciseInfo.exerciseBlock = data.activityBlock;

  let statement = exerciseInfo.exerciseBlock.statement;

  let apartadosStatement = getApartados(statement.json);

  const reviewContent = getReviewContent(
    exerciseInfo.exerciseBlock.review?.json
  );

  let exerciseBlockToShow = getDigitalExerciseBlockToShow(
    exerciseInfo,
    globalState,
    exercises
  );

  function refreshCorrection(e) {
    // let scoreInputValue = e.target.value.replace(/\D/g, "");
    let scoreInputValue = $(
      `.slideNote${exerciseInfo.slidePosition} .slideNoteTeacherComments input[name="score"]`
    ).val();

    let feedbackValue = $(
      `.slideNote${exerciseInfo.slidePosition} .slideNoteTeacherComments textarea[name="feedback"]`
    ).val();

    if (isNaN(scoreInputValue)) scoreInputValue = 0;

    let currentCorrection = {
      ...correction,
      feedback: feedbackValue,
      score: scoreInputValue > 10 ? 10 : scoreInputValue,
      level: LessonActivityFunctions.getLevelFromScore(scoreInputValue),
      correct: LessonActivityFunctions.IsCorrectFromScore(scoreInputValue),
      corrected: true,
    };

    setCorrection(currentCorrection);
  }

  let showTeacherComments = globalState.mode.includes("in_review") || globalState.mode === "reviewed";

  return (
    <div
      className={`slideNote slideNote${exerciseInfo.slidePosition}`}
      data-position={exerciseInfo.slidePosition}
    >
      {globalState.apiInfo.student && globalState.role !== "student" ? (
          <StudentProfileName
              studentProfile={globalState.apiInfo.student}
              mode='fixed'
          />
      ) : null}
      <div
        className={`slideNoteWrapper ${
          showTeacherComments ? "additionalPadding" : ""
        }`}
      >
        <div className="slideNoteContent">
          <div className="container my-0">
            <>
              {exerciseInfo.exerciseBlock.review && (
                <span className="noteContentModalButtonWrapper justify-content-end">
                  <Button
                    variant="none"
                    onClick={handleShow}
                    className="btn-primary"
                  >
                    {t("help")}
                  </Button>
                </span>
              )}
              <div className="row statement">
                <DigitalTemplateNumber globalState={globalState} exerciseInfo={exerciseInfo} />

                {apartadosStatement.map((apartado, index) => {
                  return (
                    <div
                      className="col-12"
                      key={`slideNote${exerciseInfo.slidePosition}-${index}`}
                    >
                      {apartado.map((contentBlock, indexb) => {
                        return (
                          <div
                            className="contentBlock"
                            key={`slideNote${exerciseInfo.slidePosition}-${index}-${indexb}`}
                          >
                            {getContentBlockToShow(
                              contentBlock,
                              false,
                              `slideNote${exerciseInfo.slidePosition}-${index}-${indexb}`,
                              statement.links?.assets?.block
                            )}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
              <form
                action=""
                id={`exercise_${exerciseInfo.exerciseBlock.sys.id}`}
              >
                {exerciseBlockToShow}
              </form>
            </>
          </div>
        </div>
      </div>
      {showTeacherComments ? (
        globalState.role === "teacher" ?
            <TeacherCommentsAsTeacher globalState={globalState} correction={correction} exerciseInfo={exerciseInfo} refreshCorrection={refreshCorrection} />
            : <TeacherCommentsAsStudent correction={correction} showScore={true} />
      ) : null}

      <Modal dialogClassName="note-modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              background: "#white",
              fontFamily: "Roboto",
              color: "#1d1d1b",
            }}
            ref={(el) => {
              if(el){
                el.style.setProperty('font-size', '24px', 'important');
              }
            }}
          >
            {t("help")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            background: "#white",
            fontFamily: "Roboto",
            fontSize: "16px",
            color: "#1d1d1b",
          }}
        >
          {reviewContent.map((apartado, index) => {
            return (
              <div
                className="col-12"
                style={{ padding: "2rem" }}
                key={`slideNote${exerciseInfo.slidePosition}-${index}`}
              >
                {apartado.map((contentBlock, indexb) => {
                  return (
                    <div style={{ paddingBottom: "1.5rem" }} key={indexb}>
                      {getContentBlockToShow(
                        contentBlock,
                        false,
                        `slideNote${exerciseInfo.slidePosition}-${index}-${indexb}`,
                        exerciseInfo.exerciseBlock.review?.links?.assets?.block
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            {t("close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

function getDigitalExerciseBlockToShow(exerciseInfo, globalState, exercises) {
  function parseAnswers(answersText) {
    let allAnswers = [];
    if (answersText) {
      let regexAnswer = /\[\[(.*?)\]\]/gm;
      let matches = [...answersText.matchAll(regexAnswer)];
      if (matches.length > 0) {
        for (const match of matches) {
          let answers = [];
          for (const answer of match[1].split("|")) {
            answers.push(answer);
          }
          allAnswers.push(answers);
        }
      }
    }
    return allAnswers;
  }

  let answers = parseAnswers(exerciseInfo.exerciseBlock.answers);

  exerciseInfo.answers = answers;

  switch (exerciseInfo.exerciseBlock.digitalTemplate) {
    case 1:
      return (
        <DigitalExerciseBlock1
          assets={exerciseInfo.exerciseBlock.content?.links?.assets?.block}
          exerciseInfo={exerciseInfo}
          globalState={globalState}
          exercises={exercises}
        />
      );
    case 2:
      return (
        <DigitalExerciseBlock2
          assets={exerciseInfo.exerciseBlock.content?.links?.assets?.block}
          exerciseInfo={exerciseInfo}
          globalState={globalState}
          exercises={exercises}
        />
      );
    case 3:
      return (
        <DigitalExerciseBlock3
          assets={exerciseInfo.exerciseBlock.content?.links?.assets?.block}
          exerciseInfo={exerciseInfo}
          globalState={globalState}
          exercises={exercises}
        />
      );
    case 4:
      return (
        <DigitalExerciseBlock4
          assets={exerciseInfo.exerciseBlock.content?.links?.assets?.block}
          exerciseInfo={exerciseInfo}
          globalState={globalState}
          exercises={exercises}
        />
      );
    case 5:
      return (
        <DigitalExerciseBlock5
          assets={exerciseInfo.exerciseBlock.content?.links?.assets?.block}
          exerciseInfo={exerciseInfo}
          globalState={globalState}
          exercises={exercises}
        />
      );
    case 6:
      return (
        <DigitalExerciseBlock6
          assets={exerciseInfo.exerciseBlock.content?.links?.assets?.block}
          exerciseInfo={exerciseInfo}
          globalState={globalState}
          exercises={exercises}
        />
      );
    case 7:
      return (
        <DigitalExerciseBlock7
          assets={exerciseInfo.exerciseBlock.content?.links?.assets?.block}
          exerciseInfo={exerciseInfo}
          globalState={globalState}
          exercises={exercises}
        />
      );
    case 8:
      return (
          <DigitalExerciseBlock8
              assets={exerciseInfo.exerciseBlock.content?.links?.assets?.block}
              exerciseInfo={exerciseInfo}
              globalState={globalState}
              exercises={exercises}
          />
      );
    default:
      return <></>;
  }
}

export default LessonExerciseNote;
