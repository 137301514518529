import React, {useState} from "react";
import {Link, useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {gql, useQuery} from "@apollo/client";
import {camelize} from "../Utils/camelize";
import {GraphQLClient} from "./Clients";
import {LessonsListFragment} from "./Lessons/LessonsListFragment";

const Grade = (props) => {
  let match = { params: useParams() };
  let { i18n } = useTranslation();
  const [searchParams] = useSearchParams();

  let locale = i18n.language;

  const [selectedUnit, setSelectedUnit] = useState(searchParams.get("unit"))

  const globalState = {
    id: match.params.id
  };

  const GET_GRADE = gql`
    query getGrade{
      grades(id: "${globalState.id}", locale:"${locale}"){
        idName
        name
        sys{
          id
        }
        unitsCollection(limit:20){
          items{
            idName
            name
            sys{
              id
            }
            lessonsTestSequenceCollection(limit:20){
              ... on UnitsLessonsTestSequenceCollection{
                items{
                  ... on Lessons{
                    __typename
                    name
                    idName
                    slug
                    sys{
                      id
                    }
                    cumulativeActivitiesCollection(limit:1){
                      items{
                        sys{
                          id
                        }
                      }
                    }
                  }
                  ... on Tests{
                    __typename
                    name
                    slug
                    sys{
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const Unit = (props) => {
    const { grade, unit, index } = props;

    const location = useLocation();
    const navigate = useNavigate();
    let { i18n } = useTranslation();

    const toggleUnit = (unitIdName) => {
      navigate({
        pathname: location.pathname,
        search: `?unit=${unitIdName}`
      });
      setSelectedUnit(unitIdName)
    }

    let unitIdName = camelize(unit.idName)

    return (
        <div className="card" key={`unit_${unitIdName}_${index}`}>
          <div className="card-header d-flex justify-content-between align-items-center" id={`heading${unitIdName}`}>
            <h5 className="mb-0">
              <button
                  className="btn btn-link"
                  onClick={() => toggleUnit(unitIdName)}
              >
                {unit.idName}
                {"  "}
                {unit.name}
              </button>
            </h5>

          </div>
          <div
              id={`collapse${unitIdName}`}
              className={`collapse ${
                  (selectedUnit === unitIdName)
                      ? "show"
                      : ""
              }`}
              aria-labelledby={`heading${unitIdName}`}
              data-parent="#accordion"
          >
            <div className="card-body">
              <LessonsListFragment
                  items={unit.lessonsTestSequenceCollection?.items.filter(item => item != null)}
                  t={i18n.t}
                  gradeId={grade.sys.id}
                  unitId={unit.sys.id}
                  i18n={i18n}
              />
            </div>
          </div>
        </div>
    );
  };

  const { loading, error, data } = useQuery(GET_GRADE, {
    client: GraphQLClient.getOrCreate(),
    errorPolicy: "ignore"
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  let grade = data.grades;

  return (
      <div>
        <div className="container my-5">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h2 className="card-title mb-4">{grade.name}</h2>

                  <div id="accordion">
                    {grade.unitsCollection.items.filter(item => item != null).map(
                        (unit, index) => {
                          return <Unit grade={grade} unit={unit} selectedUnit={selectedUnit}
                                       setSelectedUnit={setSelectedUnit} index={index} />;
                        }
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Grade;
