import React, { Component } from "react";
import { client } from "./Client";

class Asset extends Component {
  constructor() {
    super();
    this.state = {
      asset: null,
    };
  }
  componentDidMount() {}

  render() {
    return <img src={this.props.assetUrl} alt="" />;
  }
}

export default Asset;
