import {useEffect, useState} from "react";
import {LessonTestFunctions} from "./LessonTest.functions";

export function addHours(numOfHours, date = new Date()) {
    date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);

    return date;
}

export function addMinutes(numOfMinutes, date = new Date()) {
    date.setTime(date.getTime() + numOfMinutes * 60 * 1000);

    return date;
}

function timeDiffCalc(dateFuture, dateNow) {
    let diffInMilliSeconds = (dateFuture - dateNow) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    // calculate seconds
    const seconds = Math.floor(diffInMilliSeconds);

    return [days, hours, minutes, seconds];
}


export const LessonTestCounter = (props) => {
    const {globalState, mode, currentSlidePosition, endTime} = props;

    const [minutes, setMinutes] = useState(41)
    const [seconds, setSeconds] = useState(22)

    let interval = null;

    useEffect(() => {

        if(minutes <= 0 && seconds <= 0){
            if(interval != null){
                clearInterval(interval)
            }

            // cerramos automaticamente el test
            closeTest();
        }
    }, [seconds]);

    function changeMinutesAndSeconds() {
        let difference = timeDiffCalc(endTime, Date.now());

        setMinutes(difference[2])
        setSeconds(difference[3])
    }

    useEffect(() => {
        if(endTime != null){
            startInterval()
        }
    }, [endTime])

    function startInterval(){
        changeMinutesAndSeconds();

        interval = setInterval(() => {
            changeMinutesAndSeconds();
        }, 1000)
    }

    function closeTest(){
        LessonTestFunctions.closeTest(globalState);
    }

    return (
        <div className={`counter ${currentSlidePosition !== 1 ? 'visible' : 'hidden' }`} id={`counterLayer`}>
            <div className={`numbers`}>
                <span className={`minutes`}>{minutes}</span>
                <span className={`dots`}>:</span>
                <span className={`seconds`}>{seconds.toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                })}</span>
            </div>
        </div>
    );
}