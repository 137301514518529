

export class LessonApi{

    static async redirectToMode(globalState, toMode = 'blocked'){
        let location = `${globalState.props.location.pathname}?type=${toMode}`;

        if (globalState.authToken)
            location += `&auth_token=${globalState.authToken}`;

        if (globalState.role) location += `&role=${globalState.role}`;

        if (globalState.studentId) location += `&uuid=${globalState.studentId}`;

        if (globalState.devMode) location += "&devMode=true";

        if (globalState.teacherMode) location += "&teacherMode=true";

        window.location.replace(location);
    }

}