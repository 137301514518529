import $ from "jquery";
import "slick-carousel/slick/slick";
import { t } from "i18next";

export class ExerciseBlockFunctions {
    static processData(data, globalState) {

        if(data.exerciseBlock){
            globalState.setExerciseBlock(data.exerciseBlock);

            let slides = globalState.slides;

            let slidePosition = 0;

            let exerciseBlock = data.exerciseBlock;

            let exercisePosition = 1;
            if (exerciseBlock.digital &&
                exerciseBlock.digitalTemplate > 0 &&
                exerciseBlock.digitalContent &&
                !slides.find((e) => e.info.slug === `${exerciseBlock.sys.id}`)) {

                let exerciseInfo = {
                    exerciseBlock: exerciseBlock,
                    teachingBlock: null,
                    position: exercisePosition,
                    slidePosition: slidePosition,
                    rubric: null,
                    results: {
                            exerciseBlock_id: exerciseBlock.sys.id,
                            teachingBlock_id: null,
                            digitalTemplate: exerciseBlock.digitalTemplate,
                            correctAnswer: ``,
                            answers: ``,
                            competency: null,
                            level: 1,
                            score: 0,
                            correct: false,
                            answered: false,
                        },
                };

                let slide = {
                    info: {
                        slug: `${exerciseBlock.sys.id}`,
                        title: `${t("ejercicio")} ${slidePosition}`,
                    },
                    exerciseInfo: exerciseInfo,
                    slidePosition: slidePosition,
                    globalState: globalState,
                };
                slides.push(slide);

                slidePosition++;
                exercisePosition++;
            }

            globalState.setSlides(slides);
        }
    }

    static initSlickCarousel(globalState) {
        let elements = document.getElementsByClassName("slideNotes");

        let shouldInitCarousel =
            !globalState.isSlickCreated &&
            elements !== null &&
            elements.length !== 0 &&
            globalState.mode !== "loading" &&
            globalState.mode !== "blocked";

        if (shouldInitCarousel) {
            $(".slideNotes").on("init", function (event, slick) {});

            let slick = $(".slideNotes").slick({
                infinite: false,
                prevArrow: ".leftArrow",
                nextArrow: ".rightArrow",
                touchThreshold: 20,
                draggable: false,
            });

            if (slick) {
                globalState.setIsSlickCreated(true);
            }

            $(".slideNotes").on(
                "beforeChange",
                function (event, slick, currentSlide, nextSlide) {
                    globalState.setXapiStatement({verb: "left", slidePosition: currentSlide +1});
                    globalState.setCurrentSlidePosition(nextSlide + 1);
                }
            );
        }
    }
}
