import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  getApartados,
  getDigitalContentBlockToShow,
} from "../ExerciseBlocks";
import $ from "jquery";
import AutosizeInput from "../../Utils/AutosizeInput";
import { validateAnswersFormat } from "../../Utils/validateAnswersFormat";
import { renderKatex } from "../../Utils/renderKatex";
import {getAnswersFormat} from "../../Utils/getAnswersFormat";

export const TestExerciseBlock1 = React.memo((props) => {
  const { assets, exerciseInfo, globalState } = props;

  let contentJson =
    exerciseInfo.exerciseBlock.digitalContent?.json ??
    exerciseInfo.exerciseBlock.content?.json;

  useEffect(() => {
    refreshAnswers();
  }, [exerciseInfo]);

  useLayoutEffect(() => {
    renderKatex(document.body);
  });

  const [answers, setAnswers] = useState(getAnswers());

  useEffect(() => {
    refreshResults();
  }, [answers]);

  function getAnswers() {
    let answers = [];
    if (exerciseInfo.results !== "") {
      if (exerciseInfo.results.answers !== "") {
        answers = exerciseInfo.results.answers
          .replaceAll("[", "")
          .replaceAll("]", "")
          .replaceAll("empty", "")
          .split("|");
      } else {
        for (let answer in exerciseInfo.answers[0]) {
          answers.push("");
        }
      }
    }
    return answers;
  }

  function refreshResults() {
    try {

    } catch (e) {
      console.log("DigitalExerciseBlock1", e);
    }
  }

  function refreshAnswers() {
    let inputTexts = $(
      `#digitalExercise${exerciseInfo.exerciseBlock.sys.id} input[name="inputText"].userAnswer`
    );

    if (inputTexts.length > 0) {
      let currentAnswers = [];

      inputTexts.each(function () {
        currentAnswers.push(
          $(this).val() !== "" ? $(this).val().replaceAll(" ", "") : "empty"
        );
      });

      setAnswers(currentAnswers);
    }
  }

  return (
    <section className="digitalExerciseBlock digitalExerciseBlock1"
      id={`digitalExercise${exerciseInfo.exerciseBlock.sys.id}`}>
      <table>
        <tbody>
          {getApartados(contentJson).map((apartado, index) => {
            return (
              <tr
                className="apartado"
                key={`${exerciseInfo.exerciseBlock.sys.id}-${index}`}
              >
                <td>
                  {apartado.map((contentBlock, indexb) => {
                    return (
                      <div
                        className="contentBlock"
                        key={`${exerciseInfo.exerciseBlock.sys.id}-${index}-${indexb}`}
                      >
                        {getDigitalContentBlockToShow(
                          contentBlock,
                          `${exerciseInfo.exerciseBlock.sys.id}-${index}-${indexb}`,
                          assets
                        )}
                      </div>
                    );
                  })}
                </td>
                <td>
                  {(() => {
                    const ansFormat = getAnswersFormat(exerciseInfo.exerciseBlock, index);

                    let hasValue = answers[index] && answers[index] !== "empty"
                    let isCorrect = validateAnswersFormat(
                        answers[index],
                        exerciseInfo?.answers[0][index],
                        ansFormat);

                    return (
                        <div>
                          <AutosizeInput
                              className={`${hasValue ? (isCorrect ? 'correct' : 'wrong') : ''} userAnswer ms-4`}
                              onChange={refreshAnswers}
                              type="text"
                              id={`input${index}`}
                              name={`inputText`}
                              length={answers[index]?.length}
                              placeholder={""}
                          />
                          <AutosizeInput
                              className={`ms-4 correctAnswer`}
                              type="text"
                              id={`input${index}`}
                              name={`input2Text`}
                              length={answers[index]?.length}
                              value={
                                exerciseInfo?.answers[0][index]
                                    ? exerciseInfo?.answers[0][index]
                                    : ""
                              }
                              readOnly={true}
                              size={1}
                          />
                        </div>
                    );
                  })()}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </section>
  );
});
