import $ from "jquery";
import "slick-carousel/slick/slick";
import { t } from "i18next";

export class LessonExerciseFunctions {
  static processData(data, globalState) {
    globalState.setLesson(data.lessons);
    globalState.setUnit(data.units);

    let slides = globalState.slides;
    let links = globalState.links;
    let exercises = globalState.exercises;
    let rubrics = globalState.rubrics;

    if (globalState.lesson.teachingBlocksCollection != null) {
      let slidePosition = 0;

      function addInitialSlideNote(slidePosition) {
        let slide = {
          info: {
            slug: "general-information",
            title: t("general.information"),
          },
          slidePosition: slidePosition,
          globalState: globalState,
        };

        let link = {
          info: {
            slug: "general-information",
            title: slide.info.title,
          },
          slidePosition: slidePosition,
          globalState: globalState,
        };

        slides.push(slide);
        links.push(link);
      }

      if (!slides.find((s) => s.info.slug === "general-information")) {
        addInitialSlideNote.call(this, slidePosition);
        slidePosition++;
      }

      let exercisePosition = 1;
      for (const teachingBlock of globalState.lesson.teachingBlocksCollection
        .items) {
        let teachingBlockRubric = null;
        if (teachingBlock.rubricsCollection?.items?.length > 0) {
          for (let rubric of teachingBlock.rubricsCollection.items) {
            if (!rubrics.some((r) => r.sys.id === rubric.sys.id)) {
              rubrics.push(rubric);
            }
            teachingBlockRubric = rubric;
          }
        }

        if (teachingBlock.exerciseBlocksCollection) {
          for (const exerciseBlock of teachingBlock.exerciseBlocksCollection.items.filter(e => e != null)) {
            if (
              exerciseBlock.digital &&
              exerciseBlock.digitalTemplate > 0 &&
              exerciseBlock.digitalContent &&
              !slides.find((e) => e.info.slug === `${exerciseBlock.sys.id}`)
            ) {
              let exerciseInfo = {
                exerciseBlock: exerciseBlock,
                teachingBlock: teachingBlock,
                position: exercisePosition,
                slidePosition: slidePosition,
                rubric: teachingBlockRubric,
                results: globalState.apiInfo?.params?.answers
                  ? globalState.apiInfo.params.answers.results.answers[
                      slidePosition - 1
                    ]
                  : {
                      exerciseBlock_id: exerciseBlock.sys.id,
                      teachingBlock_id: teachingBlock.sys.id,
                      digitalTemplate: exerciseBlock.digitalTemplate,
                      correctAnswer: ``,
                      answers: ``,
                      competency:
                        teachingBlockRubric?.evaluationItem?.competence?.sys.id,
                      level: 1,
                      score: 0,
                      correct: false,
                      answered: false,
                    },
              };

              let slide = {
                info: {
                  slug: `${exerciseBlock.sys.id}`,
                  title: `${t("ejercicio")} ${slidePosition}`,
                },
                exerciseInfo: exerciseInfo,
                slidePosition: slidePosition,
                globalState: globalState,
              };

              let link = {
                info: {
                  slug: `${exerciseBlock.sys.id}`,
                  title: `${t("ejercicio")} ${slidePosition}`,
                },
                slidePosition: slidePosition,
                globalState: globalState,
              };

              exercises.push(exerciseInfo);
              slides.push(slide);
              links.push(link);

              slidePosition++;
              exercisePosition++;
            }
          }
        }
      }

      function addSummarySlideNote(slidePosition) {
        let slide = {
          info: {
            slug: "summary",
            title: t("summary"),
          },
          exercises: globalState.exercises,
          slidePosition: slidePosition,
          globalState: globalState,
        };

        let link = {
          info: {
            slug: "summary",
            title: slide.info.title,
          },
          slidePosition: slidePosition,
          globalState: globalState,
        };

        slides.push(slide);
        links.push(link);
      }

      if (!slides.find((s) => s.info.slug === "summary")) {
        addSummarySlideNote.call(this, slidePosition);
        slidePosition++;
      }

      function addAttemptSlideNote(slidePosition) {
        let slide = {
          info: {
            slug: "attempt",
            title: t("attempt"),
          },
          exercises: globalState.exercises,
          slidePosition: slidePosition,
          globalState: globalState,
        };

        let link = {
          info: {
            slug: "attempt",
            title: slide.info.title,
          },
          slidePosition: slidePosition,
          globalState: globalState,
        };

        slides.push(slide);
        links.push(link);
      }

      if (!slides.find((s) => s.info.slug === "attempt")) {
        addAttemptSlideNote.call(this, slidePosition);
        slidePosition++;
      }
    }

    globalState.setSlides(slides);
    globalState.setLinks(links);
    globalState.setExercises(exercises);
    globalState.setRubrics(rubrics);
  }

  static reprocessExercises(globalState, apiInfo) {
    if (apiInfo) {
      let exercises = globalState.exercises;

      for (const exercise of exercises) {
        exercise.results =
          apiInfo.params.answers.results.answers[exercise.slidePosition - 1];
      }

      globalState.setExercises(exercises);
    }
  }

  static calculateResults(globalState, exercises) {
    let results = [];

    for (let rubric of globalState.rubrics) {
      if (rubric.evaluationItem?.competence) {
        let result = {
          rubric_id: rubric.sys.id,
          rubric_name: rubric.idName,
          competence_id: rubric.evaluationItem.competence.sys.id,
          quantitative: 0,
          qualitative: "N0",
        };

        let rubricExercises = exercises.filter(
          (e) => e.rubric?.sys.id === result.rubric_id
        );
        if(rubricExercises.length > 0){
          for (let exercise of rubricExercises) {
            result.quantitative += exercise.results.answered
                ? exercise.results.score ?? 0
                : 0;
          }

          result.quantitative = (
              result.quantitative / rubricExercises.length
          ).toFixed(2);

          if (result.quantitative <= 4) result.qualitative = "N1";
          else if (result.quantitative <= 6) result.qualitative = "N2";
          else if (result.quantitative <= 8) result.qualitative = "N3";
          else result.qualitative = "N4";
        }

        results.push(result);
      }
    }

    return results;
  }

  static toggleFullscreen() {
    let elem = document.documentElement;

    if (document.fullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    }
  }

  static checkAnswers(globalState) {
    switch (globalState.mode) {
      case "solve_first_time":
        globalState.setPopup("checkAnswers");
        break;
      case "solve_retry":
        globalState.setPopup("checkAnswersRetry");
        break;
    }
  }

  static goToSlide(newSlidePosition) {
    $(".slideNotes").slick("slickGoTo", newSlidePosition, false);
  }

  static initSlickCarousel(globalState) {
    let elements = document.getElementsByClassName("slideNotes");

    let shouldInitCarousel =
      !globalState.isSlickCreated &&
      elements !== null &&
      elements.length !== 0 &&
      globalState.mode !== "loading" &&
      globalState.mode !== "blocked";

    if (shouldInitCarousel) {
      $(".slideNotes").on("init", function (event, slick) {});

      let slick = $(".slideNotes").slick({
        infinite: false,
        prevArrow: ".leftArrow",
        nextArrow: ".rightArrow",
        touchThreshold: 20,
        draggable: false,
      });

      if (slick) {
        globalState.setIsSlickCreated(true);
      }

      $(".slideNotes").on(
        "beforeChange",
        function (event, slick, currentSlide, nextSlide) {
          globalState.setXapiStatement({verb: "left", slidePosition: currentSlide +1});
          globalState.setCurrentSlidePosition(nextSlide + 1);
        }
      );
    }
  }

  static getMode(globalState, apiInfo) {
    // let possibleModes = ['loading', 'blocked', 'solve_first_time', 'solve_retry', 'display_with_data', 'display_without_data', 'correct']

    if (globalState) {
      switch (globalState.role) {
        case "student":
          switch (globalState.type) {
            case "blocked":
              globalState.setStatusBanner('outDeadline');
              return "blocked";
            case "display":
              return apiInfo?.params?.answers == null
                ? "display_without_data"
                : "display_with_data";
            case "solve":
              return apiInfo?.params?.answers == null
                ? "solve_first_time"
                : "solve_retry";
            case "corrected":
              return "display_with_data";
            default:
              return "loading";
          }
        case "teacher":
          switch (globalState.type) {
            case "display":
              return "display_without_data";
            case "corrected":
              return "display_with_data";
            default:
              return "loading";
          }
        case "admin":
          switch (globalState.type) {
            case "display":
              return "display_without_data";
            case "corrected":
              return "corrected";
            default:
              return "loading";
          }
        default:
          return "loading";
      }
    } else {
      return "loading";
    }
  }
}
