import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  fallbackLng: "es-ES",
  lng: "es-ES",
  resources: {
    "ca": {
      translation: require("./locales/ca/translations.json"),
    },
    "es-ES": {
      translation: require("./locales/es/translations.json"),
    },
    "en-CA": {
      translation: require("./locales/en/translations.json"),
    },
    "eu-ES": {
      translation: require("./locales/eu/translations.json"),
    },
  },
  // ns: ['translations'],
  // defaultNS: 'translations'
});

i18n.languages = ["ca", "es-ES", "en-CA", "eu-ES"];

export default i18n;
