import React, {useEffect, useLayoutEffect, useState} from "react";

import { useQuery } from "@apollo/client";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {withRouter} from "../../Utils/withRouter";
import {renderKatex} from "../../Utils/renderKatex";
import {ExerciseBlockFunctions} from "./ExerciseBlock.functions";
import BlackLoader from "../../images/black-loader.gif";
import {ExerciseBlockGraphql} from "./ExerciseBlock.graphql";
import {GraphQLClient} from "../Clients";
import ExerciseBlockSlide from "./ExerciseBlockSlide";

const ExerciseBlock = (props) => {
    let match = { params: useParams() };
    let { i18n } = useTranslation();
    let location = useLocation();
    let navigate = useNavigate();

    const [exerciseBlock, setExerciseBlock] = useState({});
    const [slides, setSlides] = useState([]);
    const [currentSlidePosition, setCurrentSlidePosition] = useState(1);
    const [isSlickCreated, setIsSlickCreated] = useState(false);

    let globalState = {
        id: match.params.id,
        mode: "",
        exerciseBlock: exerciseBlock,
        setExerciseBlock: setExerciseBlock,
        isSlickCreated: isSlickCreated,
        setIsSlickCreated: setIsSlickCreated,
        slides: slides,
        setSlides: setSlides,
        currentSlidePosition: currentSlidePosition,
        setCurrentSlidePosition: setCurrentSlidePosition,
        functions: {
            checkAnswers: (e) => {
                ExerciseBlockFunctions.checkAnswers(globalState);
            },
            initSlickCarousel: () => {
                ExerciseBlockFunctions.initSlickCarousel(globalState);
            }
        },
    };

    useEffect(() => {
        if (
            location &&
            location.pathname &&
            !location.pathname.includes("public/")
        ) {
            let navigateToUrl = location.pathname.replace("staging", "public") + location.search;
            navigate(navigateToUrl, { replace: true });
            return "";
        }
    });

    useLayoutEffect(() => {
        renderKatex(document.body);

        globalState.functions.initSlickCarousel();
    });

    const { loading, error, data } = useQuery(
        ExerciseBlockGraphql.getExerciseGraphQLCall(
            globalState.id,
            i18n.language
        ),
        {
            client: GraphQLClient.getOrCreate(
                process.env.REACT_APP_CONTENTFUL_ENVIRONMENT || "master"
            ),
            errorPolicy: "ignore"
        }
    );

    if (loading)
        return (
            <div align="center" className="pt-5">
                {" "}
                <img src={BlackLoader} alt="Loader" />{" "}
            </div>
        );
    if (error) return <p>Error :(</p>;

    if (globalState.slides.length === 0)
        ExerciseBlockFunctions.processData(data, globalState);

    return (
        <div className="lesson exercises exercise">
            <div className="slideNotesContainer">
                <div className="slideNotes">
                    {globalState.slides.map((slide, index) => {
                        return (
                            <div className={`slideNoteContainer`} key={index}>
                                {(() => {
                                    return (
                                        <ExerciseBlockSlide
                                            key={`slideNote_${slide.slidePosition}`}
                                            slide={slide}
                                            exerciseInfo={slide.exerciseInfo}
                                            globalState={slide.globalState}
                                            slidePosition={slide.slidePosition}
                                        />
                                    );
                                })()}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default withRouter(ExerciseBlock);
