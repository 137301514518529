import React from "react";
import {t} from "i18next";
import {ReactComponent as IconInfo} from '../images/svg/Icon-Info.svg';

const RememberLineResults = ({ exercises }) => {
    return (
        <div className="rememberLine">
            <IconInfo fill='#0D70B6' />

            <div className="rememberInfo">
                <div>
                    {t("answered")}
                    {" "}
                    <span className="answeredExercisesCount">{ exercises.filter((e) => e.results.answered).length }{" "}{t("of")}{" "}{exercises.length}</span>
                    {" "}
                    {t("tareas")}
                </div>
            </div>
        </div>
    );
};

export default RememberLineResults;
