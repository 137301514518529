import React from "react";

export const ContentBlockEmbeddedAsset = (props) =>{
    const { contentBlock, assets, isDigital, clau } = props;

    let imageUrl = contentBlock.data?.target?.fields?.file?.url;

    if(!imageUrl && assets != null){
        let asset = assets.filter(a => a.sys.id == contentBlock.data.target.sys.id)[0];

        if(asset)
            imageUrl = asset.url;
    }

    return (
        <img crossOrigin="*" src={imageUrl} className="imatge" alt=""/>
    )
}