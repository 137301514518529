import {getContentBlockToShow} from "../ExerciseBlocks";
import React from "react";

export const ContentBlockTable = (props) => {
    const { contentBlock, clau } = props;

    let tableHeaderRows = []
    let tableRows = []

    for(let row of contentBlock.content.filter(c => c.nodeType == 'table-row')){
        if(row.content.some(c => c.nodeType == 'table-header-cell')){
            tableHeaderRows.push(row)
        }else{
            tableRows.push(row)
        }
    }

    return (
        <div className="mt-3 mb-0" key={`${clau}_lines`}>
            <table className={`contentBlock-table table table-bordered`}>
                <thead>
                {tableHeaderRows.map((row, index) => {
                    return (
                        <tr key={`thead-${index}`}>
                            {row.content.map((cell, indexr) => {
                                return (
                                    <th key={`thead-${index}-${indexr}`}>
                                        {cell.content.map((contentBlock, indexc) => {
                                            return (
                                                <div key={`thead-${index}-${indexr}-${indexc}`}>
                                                    {getContentBlockToShow(
                                                        contentBlock,
                                                        false,
                                                        `thead-${index}-${indexr}`,
                                                        null
                                                    )}
                                                </div>
                                            )
                                        })}
                                    </th>
                                )
                            })}
                        </tr>
                    )
                })}
                </thead>
                <tbody>
                {tableRows.map((row, index) => {
                    return (
                        <tr key={`tbody-${index}`}>
                            {row.content.map((cell, indexr) => {
                                return (
                                    <td key={`tbody-${index}-${indexr}`}>
                                        {cell.content.map((contentBlock, indexc) => {
                                            return (
                                                <div key={`tbody-${index}-${indexr}-${indexc}`}>
                                                    {getContentBlockToShow(
                                                        contentBlock,
                                                        false,
                                                        `tbody-${index}-${indexr}`,
                                                        null
                                                    )}
                                                </div>
                                            )
                                        })}
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )
}