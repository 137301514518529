import { t } from "i18next";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import {LessonExerciseApi} from "./LessonExercise.api";

export const LessonExerciseNavigationBar = (props) => {
  const { globalState, mode, currentSlidePosition } = props;

  useEffect(() => {
    refreshNavigation();
  });

  function refreshNavigation() {
    refreshProgressBar();

    // $(".sidebarLink").removeClass("selected");
    // $(`#sidebarLink${currentSlidePosition - 1}`).addClass("selected");
  }

  function refreshProgressBar() {
    $(".numSlides .position").text(`${currentSlidePosition - 1}`);
    $(".numSlides .total").text(`${globalState.exercises.length}`);

    let width = 0;

    if (currentSlidePosition > 1) {
      if (currentSlidePosition - 1 < globalState.exercises.length)
        width = Math.floor(
          ($(".line-bg").width() * (currentSlidePosition - 1)) /
            globalState.exercises.length
        );
      else width = $(".line-bg").width();
    }

    $(".line-progress").animate(
      {
        width: width,
      },
      200,
      function () {
        // Animation complete.
      }
    );
  }

  return (
    <div className="slideNotesBottomMenu">
      <div className="navigation">
        <div className="lines">
          <div className="line-bg"></div>
          <div className="line-progress"></div>
        </div>
      </div>

      <NavBarLeft
        globalState={globalState}
        mode={mode}
        currentSlidePosition={currentSlidePosition}
      />

      <NavBarCenter
        globalState={globalState}
        mode={mode}
        currentSlidePosition={currentSlidePosition}
      />

      <NavBarRight
        globalState={globalState}
        mode={mode}
        currentSlidePosition={currentSlidePosition}
      />
    </div>
  );
};

const NavBarCenter = (props) => {
  const { globalState, mode, currentSlidePosition } = props;

  function startExercise() {
    $(".slideNotes").slick("slickNext");
  }

  function reviewExercise() {
    LessonExerciseApi.goToPage(globalState, 'display', globalState.devMode, globalState.teacherMode);
  }

  function retryExercise() {
    globalState.setPopup("retryExercises");
  }

  function leftArrowButton() {
    return (
      <>
        <button
          className={`button white leftArrow ${
              (currentSlidePosition >= 2 && globalState.slides[currentSlidePosition - 1].info.slug !== "attempt") ? "visible" : "hidden"
          }`}
        >
          <svg
            width="10"
            height="16"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.49998 1.33334L1.83331 8.00001L8.49998 14.6667"
              stroke="#0D70B6"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <span>
            {currentSlidePosition === 2 ? t("previous") : t("previous")}
          </span>
        </button>
      </>
    );
  }

  function numSlidesButton() {
    return (
      <>
        <div
          className={`numSlides ${
            currentSlidePosition >= 2 &&
            currentSlidePosition < globalState.functions.getSlides().length
              ? "visible"
              : "hidden"
          }`}
        >
          <span className={`position`}></span>
          <span className={`slash`}>/</span>
          <span className={`total`}></span>
        </div>
      </>
    );
  }

  function rightArrowButton() {
    let isSummary = currentSlidePosition === globalState.functions.getSlides().length - 1;
    let isVisible =
      currentSlidePosition >= 2 &&
      currentSlidePosition < globalState.functions.getSlides().length;
    if (
      (mode === "display_with_data" ||
        mode === "display_without_data" ||
        mode === "reviewed") &&
      isSummary
    ) {
      isVisible = false;
    }
    return (
      <>
        <button
          className={`button rightArrow ${isVisible ? "visible" : "hidden"}`}
        >
          <span>{isSummary ? t("summary") : t("next")}</span>
          <svg
            width="10"
            height="16"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.66669 1.33334L8.33335 8.00001L1.66669 14.6667"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </>
    );
  }

  function navigationButtons() {
    return (
      <>
        {leftArrowButton()}
        {numSlidesButton()}
        {rightArrowButton()}
      </>
    );
  }

  return (
    <div className={`arrows`}>
      {(() => {
        switch (mode) {
          case "loading":
            return <div></div>;
          case "blocked":
            return (
              <div>
                <button className={`button disabled`} onClick={startExercise}>
                  <span>{t("start.exercise")}</span>
                </button>
              </div>
            );
          case "solve_first_time":
            return (
                <div>
                  <button
                      className={`button ${
                          currentSlidePosition === 1 ? "visible" : "hidden"
                      }`}
                      onClick={startExercise}>
                    <span>{t("start.exercise")}</span>
                  </button>

                  {navigationButtons()}
                </div>
            );
          case "solve_retry":
            return (
              <div>
                <button
                  className={`button ${
                    currentSlidePosition === 1 ? "visible" : "hidden"
                  }`}
                  onClick={startExercise}>
                  <span>{t("start.exercise")}</span>
                </button>
                <button
                    className={`button white me-2 retryExerciseButton ${
                        globalState.slides[currentSlidePosition - 1].info.slug === "attempt" ? "visible" : "hidden"
                    }`}
                    onClick={reviewExercise}>
                  <span>{t("review.exercise.first")}</span>
                </button>
                <button
                    className={`button white retryExerciseButton ${
                        globalState.slides[currentSlidePosition - 1].info.slug === "attempt" ? "visible" : "hidden"
                    }`}
                    onClick={retryExercise}>
                  <span>{t("retry.exercise")}</span>
                </button>

                {navigationButtons()}
              </div>
            );
          case "display_with_data":
          case "display_without_data":
            return (
              <div>
                <button
                  className={`button ${
                    currentSlidePosition === 1 ? "visible" : "hidden"
                  }`}
                  onClick={startExercise}
                >
                  <span>{t("review.exercise")}</span>
                </button>

                {navigationButtons()}
              </div>
            );
          case "correct":
            return <div></div>;
          default:
            return <div></div>;
        }
      })()}
    </div>
  );
};

const NavBarRight = (props) => {
  const { globalState, mode, currentSlidePosition } = props;

  return (
    <div className={`rightButtons`}>
      {(() => {
        switch (mode) {
          case "loading":
            return <div></div>;
          case "blocked":
            return <div></div>;
          case "solve_first_time":
            if (globalState.authToken === "") return <div></div>;
            else {
              return (
                  <div>
                    <button
                        className={`button checkAnswersButton ${
                            currentSlidePosition === globalState.functions.getSlides().length ||
                            globalState.devMode
                                ? "visible"
                                : "hidden"
                        }`}
                        onClick={globalState.functions.checkAnswers}
                    >
                      <span>{t("check.answers")}</span>
                    </button>
                  </div>
              );
            }
          case "solve_retry":
            if (globalState.authToken === "") return <div></div>;
            else {
              return (
                <div>
                  <button
                    className={`button checkAnswersButton ${
                      currentSlidePosition === globalState.functions.getSlides().length ||
                      globalState.devMode ? "visible" : "hidden"
                    }`}
                    onClick={globalState.functions.checkAnswers}>
                    <span>{t("check.answers")}</span>
                  </button>
                </div>
              );
            }
          case "display_with_data":
            if (globalState.role === "student") {
              return (
                <div>
                  {/* <button
                    className={`button white retryExerciseButton`}
                    onClick={retryExercise}
                  >
                    <span>{t("retry.exercise")}</span>
                  </button> */}
                </div>
              );
            } else {
              return <div></div>;
            }

          case "display_without_data":
            return <div></div>;
          case "correct":
            return <div></div>;
          default:
            return <div></div>;
        }
      })()}
    </div>
  );
};

const NavBarLeft = (props) => {
  const { globalState, mode, currentSlidePosition } = props;

  return (
    <div className={`leftButtons`}>
      {(() => {
        switch (mode) {
          case "solve_first_time":
          case "solve_retry":
            return (
              <div>
                <button
                  className={`resetExerciseButton ${
                    globalState.devMode ? "visible" : "hidden"
                  }`}
                  onClick={globalState.functions.resetExercise}
                >
                  <span>Reset exercise</span>
                </button>
              </div>
            );
          default:
            return <div></div>;
        }
      })()}
    </div>
  );
};
