import { t } from "i18next";
import { useEffect } from "react";
import { LessonExerciseApi } from "./LessonExercise.api";
import {LessonExerciseFunctions} from "./LessonExercise.functions";

export const LessonExerciseOverlay = (props) => {
  const { globalState, mode, popup } = props;

  function cancel() {
    globalState.setPopup("");
  }

  function checkAnswers() {
    LessonExerciseApi.sendExercise(globalState);
  }

  function nextSlide(){
    LessonExerciseFunctions.goToSlide(globalState.currentSlidePosition + 1, globalState);
    globalState.setPopup("");
  }

  function retryExercise() {
    let location = `${globalState.props.location.pathname}?type=solve`;

    if (globalState.authToken)
      location += `&auth_token=${globalState.authToken}`;

    if (globalState.role) location += `&role=${globalState.role}`;

    if (globalState.studentId) location += `&uuid=${globalState.studentId}`;

    if (globalState.devMode) location += "&devMode=true";

    if (globalState.teacherMode) location += "&teacherMode=true";

    window.location.replace(location);
  }

  return (
    <div
      className={`overlay ${popup !== "" ? "visible" : "hidden"}`}
      id={`overlayLayer`}
    >
      <div
        className={`popup ${popup === "checkAnswers" ? "visible" : "hidden"}`}
        id={`checkAnswersPopup`}
      >
        <h4>{t("exercise.results")}</h4>

        <p>
          {t("going.see.answers")}
          {/*<br />*/}
          {/*{t("remember.practice")}*/}
        </p>

        <div className="buttons">
          <button className="button white cancel" onClick={cancel}>
            <span>{t("cancel")}</span>
          </button>

          <button className={`button blue checkAnswers`} onClick={checkAnswers}>
            <span>{t("check.answers")}</span>
          </button>
        </div>
      </div>

      <div
        className={`popup ${
          popup === "checkAnswersRetry" ? "visible" : "hidden"
        }`}
        id={`checkAnswersRetryPopup`}
      >
        <h4>{t("exercise.results")}</h4>

        <p>{t("answers.practice.exercise")}</p>

        <div className="buttons">
          <button className="button white cancel" onClick={cancel}>
            <span>{t("cancel")}</span>
          </button>

          <button className={`button blue checkAnswers`} onClick={nextSlide}>
            <span>{t("check.answers")}</span>
          </button>
        </div>
      </div>

      <div className={`popup ${popup === "retryExercises" ? "visible" : "hidden"}`} id={`retryExercisePopup`}>
        <h4>{t("practice.exercise")}</h4>

        <p>{t("practice.as.many")}</p>

        <div className="buttons">
          <button className="button white cancel" onClick={cancel}>
            <span>{t("cancel")}</span>
          </button>

          <button
            className={`button blue retryExercise`}
            onClick={retryExercise}>
            <span>{t("retry.exercise")}</span>
          </button>
        </div>
      </div>

      <div
        className={`popup ${
          popup === "authenticationError" ? "visible" : "hidden"
        }`}
        id={`authenticationErrorPopup`}
      >
        <h4>{t("auth.error")}</h4>

        <p>Auth token: {globalState.authToken}</p>

        <div className="buttons">
          <button className="button white cancel" onClick={cancel}>
            <span>Ok</span>
          </button>
        </div>
      </div>

      <div
        className={`popup ${popup === "notFoundError" ? "visible" : "hidden"}`}
        id={`notFoundErrorPopup`}
      >
        <h4>{t("not.found.error")}</h4>

        <div className="buttons">
          <button className="button white cancel" onClick={cancel}>
            <span>Ok</span>
          </button>
        </div>
      </div>
    </div>
  );
};
