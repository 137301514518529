import React from "react";
import {t} from "i18next";
import {twoDecimals} from "../Utils/twoDecimals";
import RememberLineResults from "./RememberLineResults";
import {ReactComponent as IconCheckBadge} from '../images/svg/Icon-Check-Badge.svg';

const ExerciseAttemptInfo = ({ exercises, results, globalState, title, showBadge = false }) => {
    if(results.score != null){
        return (
            <div className="attemptInfo">
                <div className="attemptTopBar">
                    <div className="attemptTitle">
                        {showBadge ? <IconCheckBadge /> : null}
                        <span>{title}</span>
                    </div>
                    <div className={`attemptScore ${results.score >= 5 ? "green" : ""}`}>
                        {(results.score != null && twoDecimals(results.score)) ?? "-"}
                    </div>
                </div>

                <div className="attemptExercises">
                    <div className="exercisesLine">
                        {exercises.map((exercise, exercisePosition) => {
                            let answer = results.answers[exercise.position - 1];
                            return (
                                <button
                                    className={`exerciseItem 
                                    ${ answer.answered ? "answered" : "unanswered" }
                                    ${answer.score >= 5 ? "correct" : "wrong"}`}
                                    onClick={globalState.functions.goToSlide}
                                    data-slide={exercise.slidePosition}
                                    id={`exerciseItem${exercisePosition}`}
                                    key={`exerciseItem${exercisePosition}${exercise.position}`}
                                    disabled={globalState.mode === "solve_retry"}>
                                    <div className="title">
                                        {t("task")} {exercise.position}
                                    </div>
                                    <div className="score">
                                        {twoDecimals(answer.score)}
                                    </div>
                                </button>
                            );
                        })}
                    </div>

                    <RememberLineResults exercises={exercises} />

                </div>
            </div>
        );
    }else{
        return null;
    }
};

export default ExerciseAttemptInfo;
