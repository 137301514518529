import React from "react";
import { t } from "i18next";
import RememberLineSummary from "../../../Components/RememberLineSummary";

export const LessonStudentSummaryNote = (props) => {
  const { slidePosition, slide, globalState, exercises } = props;

  return (
    <div
      className={`slideNote student slideNote${slidePosition} summaryNote`}
      id="summaryNote"
      data-position={slidePosition}
    >
      <div className="slideNoteWrapper">
        <div className="slideNoteContent">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="h1 slideTitle">{slide.info.title}</h2>
              </div>

              <div className="col-12 d-flex justify-content-start mt-3">
                <div className="resultsInfo">
                  <div className="resultsTitle">{t(globalState.lessonType)}</div>

                  <div className="resultsExercises">
                    <div className="exercisesLine">
                      {exercises?.map((exercise, exercisePosition) => {
                        let exerciseInfo = exercises[exercise.position - 1];
                        return (
                          <button
                            className={`exerciseItem ${
                              exerciseInfo.results?.answered ? "answered" : ""
                            }`}
                            onClick={globalState.functions.goToSlide}
                            data-slide={exercise.slidePosition}
                            id={`exerciseItem${exercisePosition}`}
                            key={`exerciseItem${exercisePosition}${exercise.position}`}
                          >
                            <div className="title">
                              {t("task")} {exercise.position}
                            </div>
                            <div className="check">
                              <svg
                                width="17"
                                height="14"
                                viewBox="0 0 17 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.54836 11.1075L15.6437 1.47556C15.6754 1.44415 15.7129 1.4191 15.7541 1.40179C15.797 1.38379 15.843 1.37451 15.8895 1.37451C15.936 1.37451 15.982 1.38379 16.0248 1.40179L16.2637 0.833149L16.0248 1.40179C16.0677 1.4198 16.1065 1.44618 16.1391 1.47937L16.1391 1.47938L16.1416 1.48197C16.285 1.62684 16.2872 1.8645 16.1466 2.01217L16.1314 2.02817L16.1171 2.04503L7.25893 12.5167C7.22827 12.5485 7.19183 12.5742 7.15153 12.5925C7.10823 12.6121 7.06137 12.6227 7.01384 12.6236C6.96631 12.6244 6.9191 12.6156 6.87509 12.5977L6.5914 13.2919L6.87508 12.5976C6.83107 12.5797 6.7912 12.5529 6.75789 12.519L6.75655 12.5176L1.36122 7.05031C1.36101 7.0501 1.3608 7.04988 1.36059 7.04967C1.29032 6.97767 1.25098 6.88104 1.25098 6.78041C1.25098 6.67948 1.29056 6.58258 1.36122 6.51051L1.36136 6.51038C1.3939 6.47718 1.43273 6.4508 1.47559 6.43279C1.51845 6.41479 1.56448 6.40551 1.61096 6.40551C1.65745 6.40551 1.70347 6.41479 1.74634 6.43279C1.7892 6.4508 1.82803 6.47718 1.86057 6.51038L1.86057 6.51038L1.86236 6.51219L6.44036 11.1517L7.01864 11.7377L7.54836 11.1075Z"
                                  fill="#0D70B6"
                                  stroke="#0D70B6"
                                  strokeWidth="1.5"
                                />
                              </svg>
                            </div>
                          </button>
                        );
                      })}
                    </div>

                    <RememberLineSummary exercises={exercises} lessonType={globalState.lessonType} />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
