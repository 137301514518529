import React, {useEffect, useState} from "react";
import {getApartados, getDigitalContentBlockToShow} from "../../ExerciseBlocks";
import {ReactComponent as IconCheck} from '../../../images/svg/Icon-Check.svg';
import {ReactComponent as IconCorrect} from '../../../images/svg/Icon-Correct.svg';
import {ReactComponent as IconWrong} from '../../../images/svg/Icon-Wrong.svg';
import $ from "jquery";

export const DigitalExerciseBlock4 = (props) => {
    const {assets, exerciseInfo, globalState, exercises} = props;

    const [showAlert, setShowAlert] = useState(false)

    let contentJson =
        exerciseInfo.exerciseBlock.digitalContent?.json ??
        exerciseInfo.exerciseBlock.content?.json

    useEffect(() => {
        refreshAnswers()
    }, [exerciseInfo])

    const [answers, setAnswers] = useState(getAnswers())

    useEffect(() => {
        refreshResults()
    }, [answers])

    let correctAnswers = getCorrectAnswers();

    function getAnswers() {
        let answers = [];
        if (exerciseInfo.results != "") {
            answers = exerciseInfo.results.answers
            .replaceAll("[", "")
            .replaceAll("]", "")
            .split("|");
        }
        return answers;
    }

    function getCorrectAnswers() {
        let answers = [];
        if (exerciseInfo.exerciseBlock != "") {
            answers = exerciseInfo.exerciseBlock.answers
            .replaceAll("[", "")
            .replaceAll("]", "")
            .split("|");
        }
        return answers;
    }

    function refreshResults(){
        try{

            let results = {...exerciseInfo.results,
                correctAnswer: `[[${exerciseInfo.answers.join("|")}]]`,
                answers: `[[${answers.join("|")}]]`,
                level: calculateLevel(exerciseInfo.answers[0], answers),
                score: calculateScore(exerciseInfo.answers[0], answers),
                correct: isCorrect(exerciseInfo.answers[0], answers),
                answered: isAnswered(exerciseInfo.answers[0], answers)
            }

            const updatedExercises = exercises.map(exercise => {
                if(exercise.slidePosition === exerciseInfo.slidePosition){
                    return {...exercise, results: results};
                }

                return exercise;
            })

            globalState.setExercises(updatedExercises)

        }catch (e) {
            setShowAlert(true)
        }
    }

    function calculateLevel(correctAnswers, userAnswers){
        let score = calculateScore(correctAnswers, userAnswers)
        if(score <= 4)
            return 1
        else if (score <= 6)
            return 2
        else if (score <= 8)
            return 3
        else
            return 4
    }

    function calculateScore(correctAnswers, userAnswers) {
        return isCorrect(correctAnswers, userAnswers) ? 10 : 0;
    }

    function isCorrect(correctAnswers, userAnswers){
        return correctAnswers.length === userAnswers.length &&
            correctAnswers.every(function(value, index) { return value == userAnswers[index]});
    }

    function isAnswered(correctAnswers, userAnswers){
        return userAnswers.length > 0;
    }

    function refreshAnswers(){

        let currentAnswers = []

        $(`#digitalExercise${exerciseInfo.exerciseBlock.sys.id} input[type="radio"]`).each(function(index){
            if($(this)[0].checked){
                currentAnswers.push(index + 1)
            }
        })

        setAnswers(currentAnswers)
    }

    return (
        <section className="digitalExerciseBlock digitalExerciseBlock4" id={`digitalExercise${exerciseInfo.exerciseBlock.sys.id}`}>
            {showAlert ?
                <div className="alert alert-danger" role="alert">
                    Este ejercicio tiene errores en los datos.<br />
                    ExerciseBlock idName: {exerciseInfo.exerciseBlock.idName}
                </div>
                : <></>
            }
            {getApartados(contentJson).map((apartado, index) => {
                let isCorrect = "";
                if (exerciseInfo.results.correct) {
                    isCorrect = index + 1 == answers[0] ? "correct" : "";
                } else {
                    if (index + 1 == correctAnswers[0]) {
                        isCorrect = "correct";
                    } else {
                        isCorrect = index + 1 == answers[0] ? "wrong" : "";
                    }
                }
                //console.log(index, answers[0], index + 1 == answers[0])
                return (
                    <div className="apartado" key={`${exerciseInfo.exerciseBlock.sys.id}-${index}`}>
                        {apartado.map((contentBlock, indexb) =>{
                            return (
                                <div className="contentBlock" key={`${exerciseInfo.exerciseBlock.sys.id}-${index}-${indexb}`}>
                                    { getDigitalContentBlockToShow(contentBlock, `${exerciseInfo.exerciseBlock.sys.id}-${index}-${indexb}`, assets) }
                                </div>
                            )
                        })}

                        {(() => {
                            switch (globalState.mode) {
                                case "solve_first_time":
                                case "solve_retry":
                                    return (
                                        <input
                                            type="radio"
                                            id={`radioButton${index}`}
                                            name={`radioGroup${exerciseInfo.exerciseBlock.sys.id}`}
                                            onChange={refreshAnswers}
                                            className={`mt-2`}
                                        />
                                    );
                                case "solve_continue":
                                    return (
                                        <input
                                            type="radio"
                                            id={`radioButton${index}`}
                                            name={`radioGroup${exerciseInfo.exerciseBlock.sys.id}`}
                                            onChange={refreshAnswers}
                                            className={`mt-2`}
                                            checked={index + 1 == answers[0]}
                                        />
                                    );
                                case "display_with_data":
                                case "in_review_first_time":
                                case "in_review_continue":
                                case "reviewed":
                                    return (
                                        <input
                                            type="radio"
                                            id={`radioButton${index}`}
                                            name={`radioGroup${exerciseInfo.exerciseBlock.sys.id}`}
                                            className={`mt-2 ${isCorrect}`}
                                            disabled={true}
                                            checked={index + 1 == answers[0]}
                                        />
                                    );
                                case "display_without_data":
                                    return (
                                        <input
                                            type="radio"
                                            id={`radioButton${index}`}
                                            name={`radioGroup${exerciseInfo.exerciseBlock.sys.id}`}
                                            className={`mt-2`}
                                            disabled={true}
                                        />
                                    );
                                default:
                                    return <></>;
                            }
                        })()}

                        {(() => {
                            switch (globalState.mode) {
                                case "in_review_first_time":
                                case "in_review_continue":
                                case "display_with_data":
                                case "reviewed":
                                    if ((answers.includes((index+1).toString()) ||
                                            answers.includes(index+1)) &&
                                        exerciseInfo.results.correctAnswer
                                        .replaceAll("[", "")
                                        .replaceAll("]", "")
                                        .replaceAll("empty", "")
                                        .includes(index+1)
                                    ) {
                                        return (
                                            <div className="ms-1"><IconCorrect /></div>
                                        );
                                    }
                                    if ((answers.includes((index+1).toString()) || answers.includes(index+1)) &&
                                        !exerciseInfo.results.correctAnswer
                                        .replaceAll("[", "")
                                        .replaceAll("]", "")
                                        .replaceAll("empty", "")
                                        .includes(index+1)
                                    ) {
                                        return (
                                            <div className="ms-1 mt-1"><IconWrong /></div>
                                        );
                                    }
                                    if ((!answers.includes((index+1).toString()) || !answers.includes(index+1)) &&
                                        exerciseInfo.results.correctAnswer
                                        .replaceAll("[", "")
                                        .replaceAll("]", "")
                                        .replaceAll("empty", "")
                                        .includes(index+1)
                                    ) {
                                        return (
                                            <div className="ms-1"><IconCheck /></div>
                                        );
                                    }
                                    break;
                                default:
                                    if ((globalState.devMode || globalState.teacherMode) &&
                                        (exerciseInfo.answers[0].includes((index + 1).toString()) || exerciseInfo.answers[0].includes(index+1)))
                                    {
                                        return (
                                            <div className="ms-1"><IconCheck /></div>
                                        );
                                    }else{
                                        return (
                                            <></>
                                        );
                                    }
                            }
                        })()}
                    </div>
                )
            })}
        </section>);
}