import React from "react";

import {ReactComponent as IconInfo} from '../images/svg/Icon-Info.svg';
import {t} from "i18next";

function getStatusBannerTitle(statusBannerType) {
    switch (statusBannerType){
        case 'outDeadline':
            return t("statusBanner.outDeadline.title");
        case 'waitingTeacherCorrection':
            return t("statusBanner.waitingTeacherCorrection.title");
        case 'inactiveTest':
            return t("statusBanner.inactiveTest.title");
    }
    return "";
}

function getStatusBannerSubtitle(statusBannerType) {
    switch (statusBannerType){
        case 'outDeadline':
            return t("statusBanner.outDeadline.subtitle");
        case 'waitingTeacherCorrection':
            return t("statusBanner.waitingTeacherCorrection.subtitle");
        case 'inactiveTest':
            return t("statusBanner.inactiveTest.subtitle");
    }
    return "";
}

const StatusBanner = ({ lessonType, statusBannerType }) => {
    return (
        <div className={`statusBanner ${lessonType}`}>
            <div className="icon">
                <IconInfo />
            </div>
            <div className="info">
                <h3>{getStatusBannerTitle(statusBannerType)}</h3>
                <h6>{getStatusBannerSubtitle(statusBannerType)}</h6>
            </div>
        </div>
    );
};

export default StatusBanner;
