import React, { Component } from "react";
import { client } from "../Pages/Client";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { withRouter } from "../Utils/withRouter";
// import { Link } from 'react-router-dom'

class Countries extends Component {
  constructor() {
    super();
    this.state = {
      countries: [],
      regions: [],
    };
  }

  componentDidMount() {
    this.props.i18n.changeLanguage(this.props.match.params.locale);
    this.getData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.t !== prevProps.t) {
      this.props.router.navigate(`../countries`);
      this.getData();
    }
  }

  getData() {
    this.getCountries();
  }

  getCountries() {
    client()
      .getEntries({
        content_type: "countries",
        locale: this.props.i18n.language,
      })
      .then((entries) => {
        this.setState({ countries: entries.items });

        this.getRegions();
      });
  }

  getRegions() {
    client()
      .getEntries({
        content_type: "regions",
        locale: this.props.i18n.language,
      })
      .then((entries) => {
        this.setState({ regions: entries.items });
      });
  }

  render() {
    const { i18n, t } = this.props;

    const Country = (props) => {
      const { country } = props;
      return (
        <div className="card">
          <div className="card-body">
            <h2 className="card-title">{country.fields.name}</h2>
            <div id="accordion">
              {this.state.regions
                .filter(
                  (region) => region.fields?.country?.sys?.id == country.sys?.id
                )
                .map((region) => {
                  return <Region region={region} key={region.fields.idName} />;
                })}
            </div>
          </div>
        </div>
      );
    };

    const Region = (props) => {
      const { region } = props;
      return (
        <div className="card">
          <div className="card-header" id={`heading${region.fields.idName}`}>
            <h5 className="mb-0">
              <button
                className="btn btn-link"
                data-toggle="collapse"
                data-target={`#collapse${region.fields.idName}`}
                aria-expanded="false"
                aria-controls={`collapse${region.fields.idName}`}
              >
                {region.fields.name}
              </button>
            </h5>
          </div>
          <div
            id={`collapse${region.fields.idName}`}
            className="collapse"
            aria-labelledby={`heading${region.fields.idName}`}
            data-parent="#accordion"
          >
            <div className="card-body">
              <Grades region={region} />
            </div>
          </div>
        </div>
      );
    };

    const Grades = (props) => {
      if (props.region.fields.grades) {
        return (
          <div className="list-group">
            {props.region.fields.grades.map((grade, index) => {
              return (
                <Link
                  className="list-group-item list-group-item-action"
                  key={grade.fields.idName}
                  to={`../grade/${grade.sys.id}`}
                >
                  <span className="me-3 text-right badge badge-primary">
                    grade #{index + 1}
                  </span>
                  {grade.fields.name}
                </Link>
              );
            })}
          </div>
        );
      } else return "";
    };

    return (
      <div>
        <div className="container my-5">
          <div className="row">
            <div className="col-12">
              <div className="text-center">
                <h2>{t("navbar.countries")}</h2>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-12">
              {this.state.countries.map((country) => {
                return (
                  <Country country={country} key={country.fields.idName} />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Countries);
