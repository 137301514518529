import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  getApartados,
  getDigitalContentBlockToShow,
} from "../../ExerciseBlocks";
import $ from "jquery";
import AutosizeInput from "../../../Utils/AutosizeInput";
import { validateAnswersFormat } from "../../../Utils/validateAnswersFormat";
import { renderKatex } from "../../../Utils/renderKatex";
import {getAnswersFormat} from "../../../Utils/getAnswersFormat";

export const DigitalExerciseBlock1 = React.memo((props) => {
  const { assets, exerciseInfo, globalState, exercises } = props;

  const [showAlert, setShowAlert] = useState(false);

  let contentJson =
    exerciseInfo.exerciseBlock.digitalContent?.json ??
    exerciseInfo.exerciseBlock.content?.json;

  useEffect(() => {
    if (globalState.mode.includes("in_review") || globalState.mode === "reviewed")
      return;
    refreshAnswers();
  }, [exerciseInfo]);

  useLayoutEffect(() => {
    renderKatex(document.body);
  });

  const [answers, setAnswers] = useState(getAnswers());

  useEffect(() => {
    if (globalState.mode.includes("in_review") || globalState.mode === "reviewed")
      return;
    refreshResults();
  }, [answers]);

  function getAnswers() {
    let answers = [];
    if (exerciseInfo.results !== "") {
      if (exerciseInfo.results.answers !== "") {
        answers = exerciseInfo.results.answers
          .replaceAll("[", "")
          .replaceAll("]", "")
          .replaceAll("empty", "")
          .split("|");
      } else {
        for (let answer in exerciseInfo.answers[0]) {
          answers.push("");
        }
      }
    }
    return answers;
  }

  function refreshResults() {
    try {
      let results = {
        ...exerciseInfo.results,
        correctAnswer: `[[${exerciseInfo.answers[0]?.join("|")}]]`,
        answers: `[[${answers.join("|")}]]`,
        level: calculateLevel(exerciseInfo.answers[0], answers),
        score: calculateScore(exerciseInfo.answers[0], answers),
        correct: isCorrect(exerciseInfo.answers[0], answers),
        answered: isAnswered(exerciseInfo.answers[0], answers),
      };

      const updatedExercises = exercises.map((exercise) => {
        if (exercise.slidePosition === exerciseInfo.slidePosition) {
          return { ...exercise, results: results };
        }

        return exercise;
      });

      globalState.setExercises(updatedExercises);
    } catch (e) {
      console.log("DigitalExerciseBlock1", e);
      setShowAlert(true);
    }
  }

  function calculateLevel(correctAnswers, userAnswers) {
    let score = calculateScore(correctAnswers, userAnswers);
    if (score <= 4) return 1;
    else if (score <= 6) return 2;
    else if (score <= 8) return 3;
    else return 4;
  }

  function calculateScore(correctAnswers, userAnswers) {
    let numCorrectAnswers = correctAnswers?.map(function (value, index) {
        const ansFormat = getAnswersFormat(exerciseInfo.exerciseBlock, index);
        return isValueCorrect(value, userAnswers[index], ansFormat);
      })?.filter((v) => v).length;
    return (10 * numCorrectAnswers) / userAnswers.length;
  }

  function isCorrect(correctAnswers, userAnswers) {
    return (
      correctAnswers.length === userAnswers.length &&
      correctAnswers.every(function (value, index) {
        const ansFormat = getAnswersFormat(exerciseInfo.exerciseBlock, index);
        return isValueCorrect(value, userAnswers[index], ansFormat);
      })
    );
  }
  function isValueCorrect(correctAnswer, userAnswer, ansFormat){
    return (
        correctAnswer === userAnswer ||
        validateAnswersFormat(userAnswer, correctAnswer, ansFormat)
    )
  }

  function isAnswered(correctAnswers, userAnswers) {
    return userAnswers.some((a) => a != "empty");
  }

  function refreshAnswers() {
    let inputTexts = $(
      `#digitalExercise${exerciseInfo.exerciseBlock.sys.id} input[name="inputText"].userAnswer`
    );

    if (inputTexts.length > 0) {
      let currentAnswers = [];

      inputTexts.each(function () {
        currentAnswers.push(
          $(this).val() !== "" ? $(this).val().replaceAll(" ", "") : "empty"
        );
      });

      setAnswers(currentAnswers);
    }
  }

  return (
    <section
      className="digitalExerciseBlock digitalExerciseBlock1"
      id={`digitalExercise${exerciseInfo.exerciseBlock.sys.id}`}
    >
      {showAlert ? (
        <div className="alert alert-danger" role="alert">
          Este ejercicio tiene errores en los datos.
          <br />
          ExerciseBlock idName: {exerciseInfo.exerciseBlock.idName}
        </div>
      ) : (
        <></>
      )}
      <table>
        <tbody>
          {getApartados(contentJson).map((apartado, index) => {
            return (
              <tr
                className="apartado"
                key={`${exerciseInfo.exerciseBlock.sys.id}-${index}`}
              >
                <td>
                  {apartado.map((contentBlock, indexb) => {
                    return (
                      <div
                        className="contentBlock"
                        key={`${exerciseInfo.exerciseBlock.sys.id}-${index}-${indexb}`}
                      >
                        {getDigitalContentBlockToShow(
                          contentBlock,
                          `${exerciseInfo.exerciseBlock.sys.id}-${index}-${indexb}`,
                          assets
                        )}
                      </div>
                    );
                  })}
                </td>
                <td>
                  {(() => {
                    switch (globalState.mode) {
                      case "solve_first_time":
                      case "solve_retry":
                        return (
                          <AutosizeInput
                            className="ms-4 userAnswer"
                            onChange={refreshAnswers}
                            type="text"
                            id={`input${index}`}
                            name={`inputText`}
                            length={answers[index]?.length}
                            placeholder={
                              (globalState.devMode || globalState.teacherMode) && exerciseInfo.answers[0]
                                ? exerciseInfo.answers[0][index]
                                : ""
                            }
                          />
                        );
                      case "solve_continue":
                        return (
                          <AutosizeInput
                            className="ms-4 userAnswer"
                            onChange={refreshAnswers}
                            type="text"
                            id={`input${index}`}
                            name={`inputText`}
                            length={answers[index]?.length}
                            placeholder={
                              (globalState.devMode || globalState.teacherMode) && exerciseInfo.answers[0]
                                ? exerciseInfo.answers[0][index]
                                : ""
                            }
                            value={
                              answers[index] && answers[index] !== "empty"
                                ? answers[index]
                                : ""
                            }
                          />
                        );
                      case "display_with_data":
                      case "in_review_first_time":
                      case "in_review_continue":
                      case "reviewed":
                        const ansFormat = getAnswersFormat(exerciseInfo.exerciseBlock, index);

                        if (
                            exerciseInfo?.results?.answers !== '' &&
                            (exerciseInfo?.answers[0][index] ===
                            exerciseInfo?.results?.answers
                              .replaceAll("[", "")
                              .replaceAll("]", "")
                              .replaceAll("empty", "")
                              .split("|")
                              [index].replaceAll(" ", "") ||
                          validateAnswersFormat(
                            exerciseInfo?.results?.answers
                              .replaceAll("[", "")
                              .replaceAll("]", "")
                              .replaceAll("empty", "")
                              .split("|")
                              [index].replaceAll(" ", ""),
                            exerciseInfo?.answers[0][index],
                            ansFormat
                          ))
                        ) {
                          return (
                            <AutosizeInput
                              className={`ms-4 correct`}
                              type="text"
                              id={`input${index}`}
                              name={`inputText`}
                              length={exerciseInfo?.answers[0][index]?.length}
                              placeholder={
                                (globalState.devMode || globalState.teacherMode) && exerciseInfo.answers[0]
                                  ? exerciseInfo.answers[0][index]
                                  : ""
                              }
                              value={exerciseInfo?.answers[0][index]}
                              readOnly={true}
                              size={1}
                            />
                          );
                        } else {
                          return (
                            <div>
                              <AutosizeInput
                                className={`ms-4 userAnswer wrong`}
                                type="text"
                                id={`input${index}`}
                                name={`inputText`}
                                length={exerciseInfo.answers[0][index]?.length}
                                placeholder={
                                  (globalState.devMode || globalState.teacherMode) && exerciseInfo.answers[0]
                                    ? exerciseInfo.answers[0][index]
                                    : ""
                                }
                                value={
                                  exerciseInfo?.results?.answers
                                    ? exerciseInfo.results.answers
                                        .replaceAll("[", "")
                                        .replaceAll("]", "")
                                        .replaceAll("empty", "")
                                        .split("|")
                                        [index].replaceAll(" ", "")
                                    : ""
                                }
                                readOnly={true}
                              />
                              <AutosizeInput
                                className={`ms-4 correctAnswer`}
                                type="text"
                                id={`input${index}`}
                                name={`inputText`}
                                length={answers[index]?.length}
                                value={
                                  exerciseInfo?.answers[0][index]
                                    ? exerciseInfo?.answers[0][index]
                                    : ""
                                }
                                readOnly={true}
                                size={1}
                              />
                            </div>
                          );
                        }
                      case "display_without_data":
                        return (
                          <AutosizeInput
                            className="ms-4"
                            type="text"
                            id={`input${index}`}
                            name={`inputText`}
                            length={answers[index]?.length}
                            placeholder={
                              (globalState.devMode || globalState.teacherMode) && exerciseInfo.answers[0]
                                ? exerciseInfo.answers[0][index]
                                : ""
                            }
                            readOnly={true}
                            size={1}
                          />
                        );
                      default:
                        return <></>;
                    }
                  })()}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </section>
  );
});
