import { t } from "i18next";
import StatusBanner from "../../../Components/StatusBanner";
import LessonHeader from "../../../Components/LessonHeader";
import ActivityTestAttemptInfo from "../../../Components/ActivityTestAttemptInfo";
import ActivityTestRubrics from "../../../Components/ActivityTestRubrics";
import TeacherCommentsAsStudent from "../../../Components/TeacherCommentsAsStudent";

export const LessonStudentInitialNote = (props) => {
  const {
    slidePosition,
    globalState,
    rubrics,
    deadLine,
    mode,
    statusBanner,
    apiInfo,
    exercises,
    corrections,
    finalCorrection,
  } = props;

  let showTeacherComments = globalState.mode.includes("in_review") || globalState.mode === "reviewed";

  return (
    <div
      className={`slideNote slideNote${slidePosition} initialNote`}
      id="initialNote"
      key={`initialNote`}
      data-position={slidePosition}
      data-navigation="false">

      {statusBanner !== "" ? (
          <StatusBanner
              lessonType={globalState.lessonType}
              statusBannerType={statusBanner} />
      ) : null}

      <div className={`slideNoteWrapper ${ showTeacherComments ? "additionalPadding" : "" }`}>
        <div className="slideNoteContent">
          <div className="container">

            <LessonHeader globalState={globalState} deadLine={deadLine} mode={mode} apiInfo={apiInfo}
                          rubrics={rubrics} lessonName={globalState.functions.getName()} />

            <div className="row">

              {mode === "display_with_data" || mode === "reviewed" ? (
                  <div className="col-12">
                    <ActivityTestAttemptInfo exercises={exercises} globalState={globalState} results={finalCorrection}
                                             corrections={corrections} showBadge={true} title={t("average")} />
                  </div>
              ) : null}

            </div>

            <ActivityTestRubrics rubrics={rubrics} mode={mode} results={finalCorrection} />

            {globalState.role === "student" && showTeacherComments ? (
                <TeacherCommentsAsStudent correction={finalCorrection} />
            ) : null}

          </div>
        </div>
      </div>
    </div>
  );
};
