import React, {useState} from "react";

import { gql, useQuery } from "@apollo/client";
import {useTranslation} from "react-i18next";
import {Link, useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {withRouter} from "../../Utils/withRouter";
import {GraphQLClient} from "../Clients";

const ExerciseBlocks = (props) => {
    let match = { params: useParams() };
    let { i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    const [textSearched, setTextSearched] = useState(searchParams.get("search"))

    const doSearch = (element) => {
        let searchValue = element.target.value
        navigate({
            pathname: location.pathname,
            search: searchValue ? `?search=${searchValue}` : ""
        });

        setTextSearched(searchValue)
    }

    const ExerciseBlocksList = (props) => {
        const { t, i18n } = props;

        const GET_EXERCISEBLOCKS = gql`
            query getExerciseBlocks{
                exerciseBlockCollection(locale:"${i18n.language}", where: {digitalTemplate_in: [1,5]}, limit:1000){
                    items{
                        sys{
                            id
                        }
                        idName
                        digitalTemplate
                        linkedFrom{
                            lessonsCollection(limit:3){
                                items{
                                    sys{
                                        id
                                    }
                                    idName
                                }
                            }
                        }
                    }
                }
            }
        `;

        const { loading, error, data } = useQuery(GET_EXERCISEBLOCKS, {
            client: GraphQLClient.getOrCreate(),
            errorPolicy: "ignore"
        });

        if (loading) return <p>Loading...</p>;
        if (error) return <p>Error :(</p>;

        let itemsToShow = (textSearched != "" && textSearched != null) ? data.exerciseBlockCollection.items
            .filter(i => i.idName.toLowerCase().includes(textSearched.toLowerCase())) : data.exerciseBlockCollection.items;

        return !loading && data ? (
            <table className="table table-bordered lessons">
                <thead>
                <tr>
                    <th>idName</th>
                    <th>Digital template</th>
                    <th></th>
                    <th>Related lessons</th>
                </tr>
                </thead>
                <tbody>
                {itemsToShow?.map((exerciseBlock, index) => {

                    return (
                        <tr key={index}>
                            <td>{ exerciseBlock.idName }</td>
                            <td>{ exerciseBlock.digitalTemplate }</td>
                            <td>
                                <Link className="btn btn-primary btn-sm ps-4 pe-4"
                                      to={`../exerciseBlock/${exerciseBlock.sys.id}`}>Ver exercise block</Link>
                                {/*<Link className="btn btn-primary btn-sm ps-4 pe-4 ms-3" target={"_blank"}*/}
                                {/*      to={`https://app.contentful.com/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE || "2wexatmcd4kx"}/entries/${exerciseBlock.sys.id}`}>Ver en contentful</Link>*/}
                            </td>
                            <td>
                                {exerciseBlock.linkedFrom?.lessonsCollection?.items?.map((lesson, index) => {
                                    return(
                                        <Link className="btn btn-primary btn-sm ps-4 pe-4 me-2"
                                              to={`../lessons?search=${lesson.idName}`}>{lesson.idName}</Link>
                                    );
                                })}
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        ) : null;
    };

    return (
        <div className={`lessons`}>
            <div className="container mt-3  mb-5">
                <div className="row">
                    <div className="col-3">

                    </div>
                    <div className="col-6">
                        <div className="text-center">
                            <h2>Exercise blocks</h2>
                        </div>
                    </div>
                    <div className="col-3">
                        <input type="text" className={'form-control'} placeholder={'Buscar'} defaultValue={textSearched} onChange={doSearch}/>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-12">
                        <ExerciseBlocksList t={i18n.t} i18n={i18n} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(ExerciseBlocks);
