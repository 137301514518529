import React, {Component} from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

class Page extends Component{
    render() {
        const {element} = this.props;

        return (
            <div>
                <Navbar />
                {element}
                <Footer />
            </div>
        );
    }
}

export default Page;