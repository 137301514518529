import { t } from "i18next";
export const LessonTestWaitingNote = (props) => {
  const { slidePosition } = props;

  return (
    <div
      className={`slideNote slideNote${slidePosition}`}
      id="summaryNote"
      data-position={slidePosition}
    >
      <div className="slideNoteWrapper">
        <div className="slideNoteContent d-flex align-items-center">
          <div className="container">
            <div className="d-flex justify-content-center mb-1">
              <h1 className="text-primary fw-bold">{t("waiting.room")}</h1>
            </div>
            <div className="d-flex justify-content-center mb-4">
              <p className="h3">{t("waiting.message")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
