import { t } from "i18next";
import Button from "react-bootstrap/Button";

export const LessonTestDeliveredNote = (props) => {
  const { slidePosition } = props;

  return (
    <div
      className={`slideNote slideNote${slidePosition} summaryNote`}
      id="summaryNote"
      data-position={slidePosition}
    >
      <div className="slideNoteWrapper">
        <div className="slideNoteContent d-flex align-items-center">
          <div className="container">
            <div className="d-flex justify-content-center mb-1">
              <h1 className="text-primary fw-bold">{t("congratulations")}</h1>
            </div>
            <div className="d-flex justify-content-center mb-4">
              <h3>{t("succ.sent.test.teacher")}</h3>
            </div>
            <div className="d-flex justify-content-center mb-4">
              <p className="text-muted">{t("remember.corrected.test")}</p>
            </div>
            <div className="d-flex justify-content-center">
              {/* <a href="javascript:self.history.back()">
                <Button className="btn-primary py-2 px-3">
                  {t("back.to.tasks")}
                </Button>
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
