import React, { Component } from "react";
import { withRouter } from "../Utils/withRouter";
import { Navigate } from "react-router-dom";

class RedirectManager extends Component {
  constructor() {
    super();

    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState, snapshot) {}

  render() {
    const { i18n } = this.props;

    let language = i18n.language;

    return <Navigate to={`/${language}/staging`} />;
  }
}

export default withRouter(RedirectManager);
