import React, { useLayoutEffect } from "react";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import BlackLoader from "../../images/black-loader.gif";
import {
  getApartados,
  getContentBlockToShow,
} from "../ExerciseBlocks";
import {renderKatex} from "../../Utils/renderKatex";
import {ExerciseBlockGraphql} from "./ExerciseBlock.graphql";
import {GraphQLClient} from "../Clients";
import {TestExerciseBlock5} from "./TestExerciseBlock5";
import {TestExerciseBlock1} from "./TestExerciseBlock1";
import {t} from "i18next";
import {Link} from "react-router-dom";

const ExerciseBlockSlide = (props) => {
  let { i18n } = useTranslation();

  const { globalState, exerciseInfo } = props;

  useLayoutEffect(() => {
    renderKatex(document.body);
  });

  const { loading, error, data } = useQuery(
      ExerciseBlockGraphql.getExerciseGraphQLCall(
      exerciseInfo.exerciseBlock.sys.id,
      i18n.language
    ),
    {
      client: GraphQLClient.getOrCreate(),
        errorPolicy: "ignore"
    }
  );

  if (loading)
    return (
      <div align="center" className="pt-5">
        {" "}
        <img src={BlackLoader} alt="Loader" />{" "}
      </div>
    );
  if (error) return <p>Error :(</p>;

  exerciseInfo.exerciseBlock = data.exerciseBlock;

  let statement = exerciseInfo.exerciseBlock.digital
    ? exerciseInfo.exerciseBlock.digitalStatement
    : exerciseInfo.exerciseBlock.statement;

  let apartadosStatement = getApartados(statement.json);

  let exerciseBlockToShow = getDigitalExerciseBlockToShow(exerciseInfo, globalState);

  return (
    <div className={`slideNote slideNote${exerciseInfo.slidePosition}`}
      data-position={exerciseInfo.slidePosition}>
      <div className="slideNoteWrapper">
        <div className="slideNoteContent">
          <div className="container my-0">
            <div>
              <div className="row statement">
                <div className="mb-3">
                  <h5>{exerciseInfo.exerciseBlock.idName} / {t("template")}:{" "}{exerciseInfo.exerciseBlock.digitalTemplate}</h5>
                </div>

                {apartadosStatement.map((apartado, index) => {
                  return (
                    <div
                      className="col-12"
                      key={`slideNote${exerciseInfo.slidePosition}-${index}`}>
                      {apartado.map((contentBlock, indexb) => {
                        return (
                          <div
                            className="contentBlock"
                            key={`slideNote${exerciseInfo.slidePosition}-${index}-${indexb}`}
                          >
                            {getContentBlockToShow(
                              contentBlock,
                              false,
                              `slideNote${exerciseInfo.slidePosition}-${index}-${indexb}`,
                              statement.links?.assets?.block
                            )}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>

              <form action=""
                id={`exercise_${exerciseInfo.exerciseBlock.sys.id}`}>
                {exerciseBlockToShow}
              </form>

              <div className="row statement">
                <div className="mt-5">
                  <h6>Answers format:{" "}{exerciseInfo.exerciseBlock.answersFormat}</h6>

                  {exerciseInfo.exerciseBlock.answersFormat == 'Multiple' ? (
                      <h6>Multiple text format:{" "}{exerciseInfo.exerciseBlock.multipleTextFormat.replaceAll("[", "")
                      .replaceAll("]", "")
                      .replaceAll("|", ", ")}</h6>
                  ) : null}

                  <Link className="btn btn-outline-primary btn-sm ps-4 pe-4 mt-2" target={"_blank"}
                        to={`https://app.contentful.com/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE || "2wexatmcd4kx"}/entries/${exerciseInfo.exerciseBlock.sys.id}`}>Ver en Contentful</Link>

                  {exerciseInfo.exerciseBlock.linkedFrom?.lessonsCollection?.items?.length > 0 ? (
                      <div className={'mt-5'}>
                        <h6>Related lessons:</h6>
                        {exerciseInfo.exerciseBlock.linkedFrom?.lessonsCollection?.items?.map((lesson, index) => {
                          return(
                              <Link className="btn btn-primary btn-sm ps-4 pe-4 me-2"
                                    to={`../../staging/lessons?search=${lesson.idName}`}>{lesson.idName}</Link>
                          );
                        })}
                      </div>

                  ) : null}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function getDigitalExerciseBlockToShow(exerciseInfo, globalState) {
  function parseAnswers(answersText) {
    let allAnswers = [];
    if (answersText) {
      let regexAnswer = /\[\[(.*?)\]\]/gm;
      let matches = [...answersText.matchAll(regexAnswer)];
      if (matches.length > 0) {
        for (const match of matches) {
          let answers = [];
          for (const answer of match[1].split("|")) {
            answers.push(answer);
          }
          allAnswers.push(answers);
        }
      }
    }
    return allAnswers;
  }

  let answers = exerciseInfo.exerciseBlock.digital
    ? parseAnswers(exerciseInfo.exerciseBlock.answers)
    : null;

  exerciseInfo.answers = answers;

  switch (exerciseInfo.exerciseBlock.digitalTemplate) {
    case 1:
      return (
        <TestExerciseBlock1
          assets={
            exerciseInfo.exerciseBlock.digitalContent.links?.assets?.block
          }
          exerciseInfo={exerciseInfo}
          globalState={globalState}
        />
      );

    case 5:
      return (
        <TestExerciseBlock5
          assets={
            exerciseInfo.exerciseBlock.digitalContent.links?.assets?.block
          }
          exerciseInfo={exerciseInfo}
          globalState={globalState}
        />
      );
    default:
      return <></>;
  }
}

export default ExerciseBlockSlide;
