export const getAnswersFormat = (exerciseBlock, answerPos) => {
    let ansFormat = []

    if(exerciseBlock.answersFormat){
        ansFormat = exerciseBlock.answersFormat

        if(exerciseBlock.answersFormat == 'Multiple'){
            if (exerciseBlock.multipleTextFormat !== "") {
                let multipleTextFormat = exerciseBlock.multipleTextFormat
                .replaceAll("[", "")
                .replaceAll("]", "")
                .replaceAll("empty", "")
                .split("|");

                if(multipleTextFormat[answerPos]){
                    ansFormat = [multipleTextFormat[answerPos]]
                }
            }
        }
    }

    return ansFormat;
}