import renderMathInElement from "katex/contrib/auto-render";

export function renderKatex(body) {
    return renderMathInElement(body, {
        delimiters: [
            {left: "$$", right: "$$", display: false},
            {left: "\\(", right: "\\)", display: false}
        ],
        strict: false
    });
}